import React, { useState } from 'react'
import {
  Typography,
  Grid,
  Button,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import { Remove, Add } from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { formatMoney } from '../../helpers/money'
import { getTotalPrice } from '../../helpers/cart'
import useMediaQuery from '@material-ui/core/useMediaQuery';

const percentages = [0, 0.05, 0.1, 0.15]

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 1rem',
  },
  label: {
    width: '100%',
    padding: '0.5em 1em',
    backgroundColor: '#EEE',
    color: 'black',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]:{
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
    }
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  tipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 1rem',
  },
  tipContainer: {
    width: '46%',
    margin: '2%',
    [theme.breakpoints.down('sm')]: {
      color: 'white',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
      }
    }
  },
  tipLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  writeTip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    marginTop: '1rem',
  },
  tipInput: {
    [theme.breakpoints.down('sm')]: {
      color: 'white',
      borderColor: 'rgba(214, 6, 42, 0.5)', // Same as outlined buttons
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 4,
    },
    borderColor: 'inherit',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    padding: '0 1rem',
    '&&&:before': {
      border: 'none',
    },
    '&&:after': {
      border: 'none',
    },
  },
  inputAdornment: {
    [theme.breakpoints.down('sm')]: {
      color: 'white'
    }
  }
}))

const SelectTip = ({ cart, tip, setTip }) => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))
  const [totalPrice, setTotalPrice] = useState(getTotalPrice(cart))

  const handleWriteTip = (e) => {
    setTip(parseFloat(e.target.value))
  }

  const handleSelectTip = (percentage) => {
    setTip(percentage * totalPrice)
  }

  return(
    <div>
      <div className={classes.label}>
        <Typography><b>¿Cuánta propina dejarás?</b></Typography>
      </div>
      <div className={classes.tipsContainer}>
      {percentages.map(percentage =>
        <Button
          key={percentage}
          color={matches ? "primary" : "inherit"}
          onClick={() => handleSelectTip(percentage)}
          classes={{
            root: classes.tipContainer,
            label: classes.tipLabel,
          }}
          variant="outlined">
          <Typography><b>{percentage*100}%</b></Typography>
          <Typography variant="caption">${formatMoney(totalPrice*percentage)}</Typography>
        </Button>
      )}
        <div className={classes.writeTip}>
          <Typography><b>Otro monto:</b></Typography>
          <TextField
            style={{width: '35%'}}
            value={tip}
            inputProps={{style: {padding: '7px 14px'}}}
            InputProps={{
              className: classes.tipInput,
              startAdornment:
              <InputAdornment disableTypography position="start">
                <Typography className={classes.inputAdornment}>$</Typography>
              </InputAdornment>,
            }}
            onChange={handleWriteTip}
            type="number"/>
        </div>
      </div>
    </div>
  )
}

export default SelectTip

import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Typography,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormField } from '../userActions'
import { MainContext } from '../../context/MainContext'

const useStyles = makeStyles(theme => ({
  label: {
    width: '100%',
    padding: '0.5em 1em',
    backgroundColor: '#EEE',
    color: 'black',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
    }
  },
  formContainer: {
    padding: '0 1em',
  },
  marginTop: {
    marginTop: '1rem',
  },
  loginLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '0.5rem',
  },
  loginLink: {
    marginLeft: '1rem',
    fontSize: 14,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'inherit',
    }
  }
}))

const UserData = ({ email, telephone, password, firstName, lastName, onChange, matches }) => {
  const classes = useStyles()
  const { setDialogOpen } = useContext(MainContext)
  const history = useHistory()

  const navigateToLogin = () => {
    if (!matches) {
      setDialogOpen(true, 'login')
    }
    else history.push('login')
  }

  return (
    <>
      <div className={classes.label}>
        <Typography><b>Ingresa tus datos de contacto</b></Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.marginTop}>
          <FormField
            name="firstName"
            value={firstName.value}
            error={firstName.error}
            onChange={onChange}
          />
        </div>
        <div className={classes.marginTop}>
          <FormField
            name="lastName"
            value={lastName.value}
            error={lastName.error}
            onChange={onChange}
          />
        </div>
        <div className={classes.marginTop}>
          <FormField
            name="email"
            type="email"
            value={email.value}
            error={email.error}
            onChange={onChange}
          />
        </div>
        <div className={classes.marginTop}>
          <FormField
            name="telephone"
            type="number"
            value={telephone.value}
            error={telephone.error}
            onChange={onChange}
          />
        </div>
        <div className={classes.marginTop}>
          <FormField
            name="password"
            type="password"
            value={password.value}
            error={password.error}
            onChange={onChange}
          />
        </div>
        <div className={classes.loginLinkContainer}>
          <Typography style={{ fontSize: 14 }}>¿Ya tienes cuenta?</Typography>
          <Button onClick={navigateToLogin} className={classes.loginLink}>
            Inicia sesión
          </Button>
        </div>
      </div>
    </>
  )
}

export default UserData

import moment from 'moment';

export const dayToSpanish = {
  monday: 'lunes',
  tuesday: 'martes',
  wednesday: 'miércoles',
  thursday: 'jueves',
  friday: 'viernes',
  saturday: 'sábado',
  sunday: 'domingo',
};

export const getDeliveryTime = (zone) => {
  const now = moment();
  const today = now.locale('en').format('dddd').toLowerCase();
  const hour = now.format('HH:MM:ss.SSS')
  let time = 60;
  const deliveryTime = zone.delivery_times.find((dt) => dt.day === today);
  if (deliveryTime) {
    const hours = deliveryTime.hours_and_time;
    const amountOfHours = hours.length;
    const deliveryHour = hours.find((_hour) => {
      return _hour.start <= hour && hour <= _hour.end
    });
    if (deliveryHour) {
      time = deliveryHour.time;
    }
    else if (amountOfHours > 0) {
      const sum = hours.reduce((acc, hour) => acc + hour.time, 0);
      time = parseInt((sum / amountOfHours), 10);
    }
  }
  return time;
};

const getTodayHours = (restaurant) => {
  const now = moment();
  const today = now.locale('en').format('dddd').toLowerCase();
  return restaurant.open_hours.find((openHour) => openHour.day === today);
}

const getCurrentHour = (restaurant) => {
  const openHour = getTodayHours(restaurant);
  if (!openHour) return null;
  const now = moment();
  let searchedHour = null;
  openHour.hours
    .forEach((hour) => {
      const start = moment(hour.start, "HH:mm:ss.SSS");
      const end = moment(hour.end, "HH:mm:ss.SSS");
      if (now.isAfter(start) && now.isBefore(end)) {
        searchedHour = hour;
      }
    });
  return searchedHour;
}

const getNextHour = (restaurant) => {
  const openHour = getTodayHours(restaurant);
  if (!openHour) return null;
  const now = moment();
  let searchedHour = null;
  openHour.hours
    .forEach((hour) => {
      const start = moment(hour.start, "HH:mm:ss.SSS");
      // there is max one open hour per day
      if (now.isBefore(start)) {
        searchedHour = hour;
      }
    });
  return searchedHour;
}

export const getRestaurantOpen = (restaurant) => {
  const openHour = getCurrentHour(restaurant);
  if (!openHour) return false;
  return true;
};

const roundDate = (date, duration, method) => {
  return moment(Math[method]((+date) / (+duration)) * (+duration));
};

export const nextAvailableQuarterHour = () => {
  return roundDate(moment().add(60, 'minutes'), moment.duration(30, 'minutes'), 'ceil');
};

export const getScheduleMinHour = (restaurant) => {
  let hour = getCurrentHour(restaurant);
  if (!hour) {
    hour = getNextHour(restaurant);
    if (!hour) return null;
  }
  const now = moment();
  const start = moment(hour.start, 'HH:mm:ss.SSS').add(60, 'minutes')
  // give 60 minutes of room to the scheduling time (add mutates now object)
  if (now.add(60, 'minutes').isAfter(start)) return now;
  else return start;
};

export const getScheduleMaxHour = (restaurant) => {
  let hour = getCurrentHour(restaurant);
  if (!hour) {
    hour = getNextHour(restaurant);
    if (!hour) return null;
  }
  return moment(hour.end, 'HH:mm:ss.SSS').subtract(30, 'minutes');
};

export const getFullWrittenDate = (date) => {
  return moment(date).locale('es').format('HH:mm [del] dddd DD [de] MMMM [de] YYYY');
};

import React, { useContext } from 'react';
import {
  Typography,
} from '@material-ui/core';
import { MainContext } from '../../context/MainContext';

const ErrorPage = ({ full, title, message }) => {
  const { restaurant } = useContext(MainContext);
  return (
    <div style={{
      height: full ? '100vh' : 'inherit',
      width: full ? '100%' : 'inherit',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Typography variant="h6" align="center">{title}</Typography>
      <Typography align="center">{message}{restaurant.contactInformation.phoneNumber.replace(/ /g, '').replace('+', '')}</Typography>
    </div>
  );
}

ErrorPage.defaultProps = {
  full: false,
  title: 'Algo no salió como esperábamos',
  message: 'Por favor contáctate con nosotros por whatsapp al '
}

export default ErrorPage;
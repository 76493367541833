import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Hidden, Typography } from "@material-ui/core";
import { WhatsApp } from "@material-ui/icons";
import TagManager from "react-gtm-module";
import { makeStyles } from '@material-ui/core/styles'
import Header from "./header";
import Banner from "./banner";
import Footer from "./Footer";
import "./layout.css";
import { MainContext } from "../../context/MainContext";

const useStyles = makeStyles(theme => ({
  whatsappButton: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    padding: '10px',
  },
}))


const Layout = (props) => {
  const { children, showBanner, hideFooter } = props;
  const { restaurant } = useContext(MainContext)


  const handleContactUs = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Contact Us Click',
      }
    })
  }

  const classes = useStyles();

  return (
    <>
      <Header />
      {showBanner && <Banner />}
      <div style={{ margin: `0 auto` }}>
        <main>{children}</main>
      </div>
      {!hideFooter && <Footer />}
      <Hidden mdUp>
        <a
          onClick={handleContactUs}
          className={classes.whatsappButton}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://wa.me/${restaurant.contactInformation?.phoneNumber?.replace(/ /g, '').replace('+', '')}`}
        >
          <WhatsApp style={{ color: '#128C7E', fontSize: '40px' }} />
        </a>
      </Hidden>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React from 'react'
import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
} from '@material-ui/core'
import { Item } from '../cart'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  label: {
    width: '100%',
    padding: '0.5em 1em',
    backgroundColor: '#EEE',
    color: 'black',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
    }
  },
  summaryContainer: {
    padding: '0 1rem',
  },
  divider: {
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.grey[500],
    }
  }
}))

const Details = ({
  cart,
  deliveryOptions,
  tip,
  additionals,
  promotionalCodes,
  total,
  subTotal,
  dailyPromo,
  discount,
  dailyDiscount,
}) => {
  const classes = useStyles()
  return (
    <div>
      <div className={classes.label}>
        <Typography><b>Resumen de tu pedido</b></Typography>
      </div>
      <div className={classes.summaryContainer}>
        {cart.map(item =>
          <Item key={item.temporaryId} item={item} />
        )}
        {/* {additionals.map(additional =>
          <Item key={additional.id} item={additional}/>
        )} */}
        <Divider className={classes.divider} />
        <Item item={{ name: 'Subtotal', totalPrice: subTotal }} />
        {promotionalCodes.length > 0 && promotionalCodes.map((code) =>
          <Item key={code.name} item={{ name: code.name, totalPrice: code.moneyDiscount }} />
        )}
        {dailyPromo.length > 0 && dailyPromo.map((code) =>
          <Item key={code.code} item={{ name: code.name, totalPrice: code.moneyDiscount }} />
        )}
        <Divider className={classes.divider} />
        {(discount > 0 || dailyDiscount > 0) && (
          <>
            <Item item={{ name: 'Subtotal con descuentos', totalPrice: subTotal - discount - dailyDiscount }} />
            <Divider className={classes.divider} />
          </>
        )}
        {deliveryOptions && deliveryOptions.deliveryQuote && (
          <Item item={{ name: `Costo de envío`, totalPrice: deliveryOptions.deliveryQuote.fee }} />
        )}
        <Item item={{ name: 'Propina', totalPrice: tip }} />
        <Divider className={classes.divider} />
        <Item item={{ name: 'Total', totalPrice: total }} />
      </div>
    </div>
  )
}

Details.propTypes = {
  promotionalCodes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    moneyDiscount: PropTypes.number,
  })),
  dailyPromo: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    moneyDiscount: PropTypes.number,
  })),
}

Details.defaultProps = {
  promotionalCodes: [],
  dailyPromo: [],
};

export default Details

import React, { useEffect, useState } from 'react'
import moment from 'moment';
import {
  MenuItem,
  TextField,
  Paper,
  // Typography,
  InputAdornment,
} from '@material-ui/core'
import { LocationOn } from '@material-ui/icons'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
// import { pointInZone } from '../../helpers/delivery';
// import { getDeliveryTime } from '../../helpers/date';

const CreateAddress = ({ handleAddress, address, /* deliveryZones */ }) => {
  // const [error, setError] = useState('')
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  useEffect(() => {
    if (address)
      setValue(address.long, false)
  }, [address, setValue])

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => async () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    try {
      const geoCode = await getGeocode({ address: description })
      const { lat, lng } = await getLatLng(geoCode[0])

      handleAddress({
        latitude: lat,
        longitude: lng,
        long: description,
        short: description.split(',')[0]
      })

      // let foundZone = false
      // for (var i = 0; i < deliveryZones.length; i++) {
      //   const deliveryZone = deliveryZones[i];
      //   if (pointInZone({ lat, lng }, deliveryZone)) {
      //     const { id, delivery_cost, minimum_order, isActive } = deliveryZone;
      //     if (!isActive) {
      //       setError('En estos momentos no podemos repartir a esa dirección');
      //       handleAddress(null);
      //       return;
      //     }
      //     const time = getDeliveryTime(deliveryZone);
      //     handleAddress({
      //       address: {
      //         latitude: lat,
      //         longitude: lng,
      //         long: description,
      //         short: description.split(',')[0]
      //       },
      //       zone: {
      //         id,
      //         delivery_cost,
      //         minimum_order,
      //         time,
      //         isActive,
      //       },
      //     })
      //     setError('');
      //     foundZone = true;
      //     return
      //   }
      // }
      // if(!foundZone) {
      //   handleAddress(null);
      //   setError('Lo sentimos, no hacemos reparto a tu dirección');
      // }
    } catch (e) {
      console.log("😱 Error: ", e);
    }
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <MenuItem key={place_id} onClick={handleSelect(suggestion)}>
          <strong style={{ marginRight: '0.5rem' }}>{main_text}</strong>
          <small>{secondary_text}</small>
        </MenuItem>
      );
    });

  return (
    <div>
      <TextField
        InputProps={{
          style: { backgroundColor: 'white' },
          startAdornment: (
            <InputAdornment position="start">
              <LocationOn />
            </InputAdornment>
          )
        }}
        placeholder="Ingresa tu dirección"
        fullWidth
        variant="outlined"
        value={value}
        onChange={handleInput}
        disabled={!ready}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" &&
        <Paper style={{ maxWidth: 363 }}>
          {renderSuggestions()}
        </Paper>}
    </div>
  );
}

export default CreateAddress

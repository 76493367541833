import React, { useState, useRef, useEffect, createRef, useContext } from 'react'
import { Link, useHistory } from "react-router-dom"
import {
  CategoriesHeader,
  MenuAndCategoriesHeader,
} from './'
import {
  Card,
  CardMobile,
} from '../items'
import {
  ConfigureDialog
} from '../dialogs'
import {
  Typography,
  Hidden,
  Button,
  LinearProgress,
  Grid,
} from '@material-ui/core'
import TagManager from 'react-gtm-module';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ShoppingCart } from '@material-ui/icons'
import { MainContext } from '../../context/MainContext'
import { formatMoney } from '../../helpers/money'
import { getTotalItems, getTotalPrice } from '../../helpers/cart'
import { ScrollToTopOnMount } from '../utils'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

const useStyles = makeStyles(theme => ({
  categoriesContainer: {
    paddingLeft: '0.5rem',
    paddingTop: 5,
    boxShadow: '0px 6px 9px -11px rgba(0,0,0,0.75)',
    position: 'sticky',
    top: 55,
    zIndex: 2,
    backgroundColor: theme.custom.categories.backgroundColor,
    color: theme.custom.categories.color,
    [theme.breakpoints.up("md")]: {
      paddingTop: '1rem',
    }
  },
  menuContainer: {
    padding: '0 3em',
    [theme.breakpoints.only("xs")]: {
      padding: '0 1em 4em',
    },
    backgroundImage: `url(${theme.backgroundImageUrl})`,
    backgroundSize: 'contain',
  },
  categoryHeader: {
    paddingTop: '1rem',
    color: theme.custom.categories.headerColor,
  },
  categoryNote: {
    fontStyle: 'italic',
    lineHeight: '1.2',
  },
  itemsContainer: {
    margin: '1em 0 2em'
  },
  sticky: {
    position: 'sticky',
    top: 100, // header + menu categories
  },
  itemListTile: {
    cursor: 'pointer',
  },
  configureLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  bottomButton: {
    width: '100%',
    height: '4em',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    bottom: 0,
  },
}))

const FoodMenu = (props) => {
  const menus = props.menus
  const { cart, selectedMenu, selectedCategory } = useContext(MainContext)
  const [menu, setMenu] = useState(selectedMenu || 0)
  const [category, setCategory] = useState(selectedCategory || 0)
  const [loading, setLoading] = useState(true)
  const [categoriesRefs, setCategoriesRefs] = useState(null)
  const [itemDialogData, setItemDialogData] = useState({
    open: false,
    item: null,
  })
  const classes = useStyles()
  const menuSelect = useRef()

  const history = useHistory()

  useScrollPosition(() => {
    const menuSelectBottom = menuSelect.current.getBoundingClientRect().bottom
    let nextCategoryBottom, actualCategoryBottom
    if (category < categoriesRefs[menu].length - 1)
      nextCategoryBottom = categoriesRefs[menu][category + 1].current.getBoundingClientRect().bottom
    if (category > 0)
      actualCategoryBottom = categoriesRefs[menu][category].current.getBoundingClientRect().bottom

    if (nextCategoryBottom - 100 < menuSelectBottom) {
      setCategory(category => category + 1)
      return
    }
    if (actualCategoryBottom - 100 > menuSelectBottom) {
      setCategory(category => category - 1)
      return
    }
  }, null, null, null, 400)

  useEffect(() => {
    let _categoriesRefs = [];
    _categoriesRefs = menus.map((_menu, i) => _menu.categories.map(() => createRef()))
    setCategoriesRefs(_categoriesRefs)
    setLoading(false)
  }, [])

  const handleSetCategory = (index) => {
    const { name, id } = menus[menu].categories[index]
    TagManager.dataLayer({
      dataLayer: {
        index,
        name,
        id,
        event: 'Category Click'
      },
    })
    categoriesRefs[menu][index].current.scrollIntoView()
    setCategory(index)
  }

  const handleOpenItemDialog = (item) =>
    setItemDialogData({
      open: true,
      selectedItem: item,
    })

  if (loading)
    return (
      <LinearProgress />
    )
  return (
    <div>
      <ScrollToTopOnMount />
      <div className={classes.categoriesContainer}>
        {menus.length > 1 ? // For restaurants that don't want to divide de menu
          <MenuAndCategoriesHeader
            ref={menuSelect}
            menus={menus}
            menu={menu}
            category={category}
            sticky
            setMenu={setMenu}
            setCategory={handleSetCategory}
          />
          :
          <CategoriesHeader
            ref={menuSelect}
            category={category}
            setCategory={handleSetCategory}
            categories={menus[menu].categories}
          />
        }
      </div>
      <div className={classes.menuContainer}>
        {menus[menu].categories.map((_category, i) =>
          <div key={_category.name}>
            <div
              id={`category_${_category.id}`}
              ref={categoriesRefs[menu][i]}
              style={{
                position: 'relative',
                top: -150,
              }} />
            <Typography
              className={classes.categoryHeader}
              variant="h6">
              <b>{_category.name}</b>
            </Typography>
            <Typography variant="subtitle1" className={classes.categoryNote}>
              {_category.note}
            </Typography>
            {_category.items &&
              <>
                <Hidden mdUp>
                  {_category.items.filter(item => item.is_active).map((item) =>
                    <Link
                      to={{
                        pathname: "/item-configure",
                        state: { item }
                      }}
                      className={classes.configureLink}
                      key={item.id}>
                      <CardMobile item={item} />
                      {/* <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }} /> */}
                    </Link>
                  )}
                </Hidden>
                <Hidden smDown >
                  <div className={classes.itemsContainer}>
                    <Grid container spacing={4}>
                      {_category.items.filter(item => item.is_active).map((item) =>
                        <Grid item md={6} lg={4} xl={3}
                          className={classes.itemListTile}
                          onClick={() => handleOpenItemDialog(item)}
                          key={item.id}>
                          <Card onClick={() => handleOpenItemDialog(item)} type="vertical" item={item} />
                        </Grid>
                      )}
                    </Grid>
                  </div>
                </Hidden>
              </>
            }
          </div>
        )}
        {itemDialogData.selectedItem !== null &&
          <ConfigureDialog
            open={itemDialogData.open}
            item={itemDialogData.selectedItem}
            onClose={() => setItemDialogData({ open: false, selectedItem: null })} />
        }
      </div>
      {cart.length > 0 &&
        <Hidden mdUp>
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push("/cart")}
            className={classes.bottomButton}>
            <div style={{ display: 'flex' }}>
              <ShoppingCart style={{ marginRight: '0.5em' }} />
              <Typography variant="body1"><b>Ver carro ({getTotalItems(cart)})</b></Typography>
            </div>
            <Typography variant="body1"><b>${formatMoney(getTotalPrice(cart))}</b></Typography>
          </Button>
        </Hidden>
      }
    </div>
  )
}

export default FoodMenu

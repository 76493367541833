import React, { useContext, useEffect } from 'react';
import {
  Switch,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import './App.css';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Layout } from './components/layout';
import {
  Menu,
  Cart,
  ItemConfigure,
  OrderConfigure,
  Login,
  Register,
  Payment,
  PaymentSuccess,
  PaymentFailure,
  ForgotPassword,
  EmailConfirmation,
  Orders,
  Order,
  CompleteLoginProvider,
  UserProfile,
  Addresses
} from './views';
import {
  Delivery as DeliveryDialog,
  Login as LoginDialog,
  ForgotPassword as ForgotPasswordDialog,
  Register as RegisterDialog,
  ConfirmEmail as ConfirmEmailDialog,
  EmailConfirmed as EmailConfirmedDialog,
} from './components/dialogs';
import PrivateRoute from './auth/PrivateRoute';
import { MainContext } from './context/MainContext';
import InStoreMenus from './views/InStoreMenus';

function App() {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("sm"));
  const { dialogsOpen, setDialogOpen, user } = useContext(MainContext);

  useEffect(() => {
    if (user) {
      if (!user.confirmed) setDialogOpen(true, 'confirmEmailDialog');
      else setDialogOpen(false, 'confirmEmailDialog');
    }
  }, [user]);

  return (
    <Router>
      {match ?
        <Switch>
          <Route path="/login">
            <Layout>
              <Login />
            </Layout>
          </Route>
          <Route path="/register">
            <Layout>
              <Register />
            </Layout>
          </Route>
          <Route path="/forgot-password">
            <Layout>
              <ForgotPassword />
            </Layout>
          </Route>
          <Route path="/cart">
            <Layout hideFooter>
              <Cart />
            </Layout>
          </Route>
          <Route path="/item-configure">
            <Layout hideFooter>
              <ItemConfigure />
            </Layout>
          </Route>
          <PrivateRoute exact path="/payment">
            <Layout>
              <Payment />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path='/payment/success'>
            <Layout>
              <PaymentSuccess />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path='/payment/failure'>
            <Layout>
              <PaymentFailure />
            </Layout>
          </PrivateRoute>
          <Route path="/order-configure">
            <Layout hideFooter>
              <OrderConfigure />
            </Layout>
          </Route>
          <Route exact path="/">
            <Layout showBanner>
              <Menu />
            </Layout>
          </Route>
        </Switch>
        :
        <Switch>
          <PrivateRoute exact path="/payment">
            <Layout>
              <Payment />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path='/payment/success'>
            <Layout>
              <PaymentSuccess />
            </Layout>
          </PrivateRoute>
          <PrivateRoute exact path='/payment/failure'>
            <Layout>
              <PaymentFailure />
            </Layout>
          </PrivateRoute>
          <Route path="/order-configure">
            <Layout>
              <OrderConfigure />
            </Layout>
          </Route>
          <Route exact path="/">
            <Layout showBanner>
              <Menu />
            </Layout>
          </Route>
        </Switch>
      }
      <Route exact path="/profile">
        <Layout>
          <UserProfile />
        </Layout>
      </Route>
      <Route exact path="/in-store-menus">
        <Layout showBanner>
          <InStoreMenus />
        </Layout>
      </Route>
      <Route
        path="/login_providers/:provider/:id_token?"
        render={
          (props) => (
            <Layout>
              <CompleteLoginProvider {...props} />
            </Layout>
          )
        }
      />
      <Route exact path="/addresses">
        <Layout>
          <Addresses />
        </Layout>
      </Route>
      <Route exact path="/orders">
        <Layout>
          <Orders />
        </Layout>
      </Route>
      <Route exact path="/orders/:id">
        <Layout>
          <Order />
        </Layout>
      </Route>
      <Route exact path="/email-confirmation">
        <EmailConfirmation />
      </Route>
      <DeliveryDialog open={dialogsOpen.address} onClose={() => setDialogOpen(false, 'address')} />
      <LoginDialog
        open={dialogsOpen.login}
        onClose={() => setDialogOpen(false, 'login')} />
      <RegisterDialog
        open={dialogsOpen.register}
        onClose={() => setDialogOpen(false, 'register')} />
      <ForgotPasswordDialog
        open={dialogsOpen.forgotPassword}
        onClose={() => setDialogOpen(false, 'forgotPassword')} />
      <ConfirmEmailDialog
        open={dialogsOpen.confirmEmailDialog}
        onClose={() => setDialogOpen(false, 'confirmEmailDialog')}
        userEmail={user ? user.email : null} />
      <EmailConfirmedDialog
        open={dialogsOpen.emailConfirmed}
        onClose={() => setDialogOpen(false, 'emailConfirmed')} />
    </Router>
  );
}

export default App;

import React, { forwardRef, useContext } from 'react'
import {
  Grid,
  Tabs,
  Tab,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MenuSelect from './MenuSelect'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { MainContext } from '../../context/MainContext'

const useStyles = makeStyles(theme => ({
  menuSelectContainer: {
    padding: '0 1rem',
    [theme.breakpoints.down("sm")]: {
      padding: '1rem 0',
      display: 'flex',
      alignItems: 'center',
    }
  }
}))

const MenuAndCategoriesHeader = forwardRef((props, ref) => {
  const { menus, menu, category, setMenu, setCategory } = props
  const mainContext = useContext(MainContext)
  // const [selectedCategory, setCategory] = useState(0)
  // const [selectedSubcategory, setSubcategory] = useState(0)
  const classes = useStyles()
  const smUp = useMediaQuery('(min-width:960px)');

  const handleTabChange = (event, newTab) => {
    // setSubcategory(newTab);
    setCategory(newTab)
    mainContext.setCategory(newTab)
  };

  const handleMenuSelect = async (menu) => {
    // setCategory(category)
    await setCategory(0)
    await mainContext.setCategory(0)
    setMenu(menu)
    mainContext.setMenu(menu)
  }

  return(
    <Grid container ref={ref}>
      <Grid item xs={4} md={3} className={classes.menuSelectContainer}>
        <MenuSelect menus={menus} setMenu={handleMenuSelect} menu={menu}/>
      </Grid>
      <Grid item xs={8} md={9} style={{ paddingTop: 5 }}>
        <Tabs
          value={category}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons={smUp ? 'off' : 'on'}>
          {menus[menu].categories.map((category) =>
            <Tab key={category.id} label={category.name}/>
          )}
        </Tabs>
      </Grid>
    </Grid>
  )
})

export default MenuAndCategoriesHeader

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LogoSrc from '../../assets/soma/logo.png';

const useStyles = makeStyles(() => ({
  somaLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  somaLogo: {
    width: '30%',
  },
}))

const SomaLogo = () => {
  const classes = useStyles();
  return (
    <div className={classes.somaLogoContainer}>
      <img src={LogoSrc} alt="soma logo" className={classes.somaLogo} />
    </div>
  );
}

export default SomaLogo;
export const klapAvailablePayments = [
  // 'Sodexo',
  // 'Edenred',
  // 'Transfer',
  'CreditOrDebitCard',
];

export const paymentTypeToKlapOptions = {
  Sodexo: ['sodexo'],
  Edenred: ['edenred'],
  Transfer: ['transferencia'],
  CreditOrDebitCard: ['tarjetas'],
}
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getFullWrittenDate } from '../../helpers/date';
import { useQuery } from '@apollo/client';
import { GET_ORDER } from '../../gql/orders';
import { Reorder } from './Reorder.js';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '1rem',
    [theme.breakpoints.only('xs')]: {
      margin: '1rem',
    },
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    }
  },
  buttonContainer: {
    [theme.breakpoints.only('xs')]: {
      marginTop: '1rem',
      marginLeft: "auto",
    },
  },

}));

const Card = ({ order }) => {
  const classes = useStyles();
  const history = useHistory();
  const [reorderActive, setReorderActive] = useState(false);

  const goToOrder = () => {
    history.push(`orders/${order.id}`);
  };

  const { data, error, loading } = useQuery(GET_ORDER, {
    variables: {
      id: order.id,
    }
  })

  return (
    <Paper className={classes.paper}>
      <div className={classes.contentContainer}>
        <div>
          <Typography variant="h6">{getFullWrittenDate(order.created_at)}</Typography>
          <Typography>{order.address ? order.address.short : 'Retiro en local'}</Typography>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            onClick={() => { setReorderActive(true) }}
            color="primary"
            disabled={loading}
          >
            Volver a pedir
          </Button>
          <Button
            style={{
              marginLeft: "10px",
            }}
            variant="contained"
            onClick={goToOrder}
            color="primary"
          >
            Ver pedido
          </Button>
        </div>
      </div>
      {
        reorderActive && (
          <Reorder orderData={data.order}/>
        )
      }
    </Paper>
  )
}

Card.propTypes = {
  order: PropTypes.shape({
    address: PropTypes.shape({
      short: PropTypes.string
    }),
    created_at: PropTypes.string.isRequired,
  }).isRequired,
};

export default Card;
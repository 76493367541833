import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { LinearProgress, makeStyles } from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Hidden,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import host from '../../config/host';

const GET_BANNER = gql`
  query GetBanner($restaurantId: ID!) {
    banners(where: { restaurant: { id: $restaurantId } }) {
      desktop {
        id
        url
      }
      mobile {
        id
        url
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 150,
    },
  },
  mobileDims: {
    [theme.breakpoints.down('sm')]: {
      height: 150,
    },
  }
}));

const Banner = () => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_BANNER, {
    variables: {
      restaurantId: process.env.REACT_APP_RESTAURANT_ID,
    },
  });

  if (loading) return <LinearProgress />;
  if (error) return `Error! ${error.message}`;

  const banner = data.banners[0];

  if (banner.desktop.length <= 0 || banner.desktop.length <= 0) return null;

  return (
    <div className={classes.container}>
      <Hidden xsDown>
        <Carousel
          stopOnHover={true}
          swipeable={true}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          showIndicators={true}
          infiniteLoop={true}
          interval={4000}
          autoPlay={true}>
          {banner.desktop.map((image) =>
            <img
              key={image.id}
              src={image.url}
              alt="desktop banner"
            />
          )}
        </Carousel>
      </Hidden>
      <Hidden smUp>
        <Carousel
          stopOnHover={true}
          swipeable={true}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          showIndicators={true}
          infiniteLoop={true}
          interval={4000}
          autoPlay={true}>
          {banner.mobile.map((image) =>
            <div key={image.id} className={classes.mobileDims}>
              <img
                src={image.url}
                alt="mobile banner"
                className={classes.mobileDims}
              />
            </div>
          )}
        </Carousel>
      </Hidden>
    </div>
  )
}

export default Banner

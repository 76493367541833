import React, { useContext, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { Button, LinearProgress, Paper, Typography } from "@material-ui/core";
import axios from "axios";
import host from "../config/host";
import { useHistory } from "react-router-dom";
import { MainContext } from "../context/MainContext";
import Profile from "../components/userActions/Profile";


const CompleteLoginProvider = ({ match }) => {
  const { search } = useLocation();
  const history = useHistory();
  const { setUser, restaurant } = useContext(MainContext);
  const [error, setError] = useState('');
  const [incompleteUserData, setIncompleteUserData] = useState(false);
  const [componentUser, setComponentUser] = useState(null);

  const provider = match.params.provider;

  useEffect(() => {
    const query = new URLSearchParams(search);
    const idToken = query.get("id_token");
    const accessToken = query.get("access_token");
    const params = `?id_token=${idToken}&access_token=${accessToken}&restaurant_id=${restaurant.id}`;

    axios
      .get(`${host}/auth/${provider}/callback${params}`)
      .then((response) => {
        const { user, jwt } = response.data;
        setComponentUser({ ...user, jwt });
        if (checkUserIncompleteData(user)) return;
        setUser({ ...user, jwt });
        history.replace("/");
      })
      .catch((error) => {
        console.log(error);
        const { response } = error;
        if (response.data && response.data.message && response.data.message.length > 0 &&
          response.data.message[0].messages.length > 0 &&
          response.data.message[0].messages[0].id === 'Auth.form.error.email.taken') {
          setError('Ese email ya tiene una cuenta con otro método de ingreso')
        } else {
          setError(`Hubo un error al iniciar con ${provider}`);
        }
      });
  }, [])


  const checkUserIncompleteData = (user) => {
    const { first_name, last_name, phone_number } = user;
    if (!first_name || !last_name || !phone_number) {
      setIncompleteUserData(true);
      return true;
    }
    return false
  }

  if (incompleteUserData) {
    return <Profile user={componentUser} />
  }

  if (Boolean(error)) {
    return (
      <Paper
        elevation={10}
        style={{
          margin: "2rem",
          padding: "2rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{error}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.replace("/");
          }}
        >
          Volver a inicio
        </Button>
      </Paper>
    );
  }

  return (
    <>
      <LinearProgress />
      <Paper
        elevation={10}
        style={{
          margin: "2rem",
          padding: "2rem",
        }}
      >
        <Typography
          variant="h6"
          style={{ width: { sm: "100%", md: "auto" } }}
        >{`Iniciando sesión con ${provider}...`}</Typography>
      </Paper>
    </>
  );
};

export default CompleteLoginProvider;

import React, { useContext, useState, useEffect } from 'react'
import {
  Typography,
  Hidden,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandMore, Schedule, Update } from '@material-ui/icons'
import { MainContext } from '../../context/MainContext'
import { getDeliveryTime } from '../../helpers/date'
import moment from 'moment-timezone'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    cursor: 'pointer',
    [theme.breakpoints.down("sm")]: {
      color: 'white',
      height: '100%',
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: 'column',
      borderRadius: 5,
      paddingLeft: '1rem',
      paddingTop: '1rem',
    }
  },
  addressContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up("md")]: {
      border: '1px solid #43596e',
      borderRadius: 5,
      paddingLeft: 10,
      padding: 5,
      width: 'fit-content',
    }
  },
  addressLabel: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.up("md")]: {
      marginBottom: 3,
    }
  },
  address: {
    lineHeight: 1,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    maxHeight: '16px',
  },
  addressDisabled: {
    color: 'inherit !important',
    '&&&:before': {
      borderBottomStyle: 'hidden',
    }
  },
  timeContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.5rem',
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      flexDirection: 'column',
      marginLeft: 10,
      alignItems: 'flex-start',
    }
  },
}))

const AddressContainer = ({ address, type }) => {
  const classes = useStyles();
  return (
    <div className={classes.addressContainer}>
      <Typography align="left" variant="body2" className={classes.address}>
        {type === 'delivery' && address ? address.long : 'Retiro en el local'}
      </Typography>
      <ExpandMore />
    </div>
  );
}

const AddressHandler = () => {
  const classes = useStyles();
  const { deliveryOptions, scheduleOptions, setDialogOpen, restaurant } = useContext(MainContext)
  const { type: scheduleType, time: scheduledTime } = scheduleOptions;

  const getDeliveryTime = (type) => {
    if (scheduleType === 'inmediate') {
      return type === 'delivery' && deliveryOptions?.deliveryQuote?.dropoff_eta
      ? moment(deliveryOptions.deliveryQuote.dropoff_eta).format('HH:mm')
      : moment().add(restaurant.pickup_time, 'minutes').format('HH:mm');
    } else {
      return scheduledTime;
    }
  }


  if (deliveryOptions) {
    const { address, type } = deliveryOptions;
    return (
      <>
        <Hidden mdUp>
          <div className={classes.container}>
            <div onClick={() => setDialogOpen(true, 'address')}>
              <div style={{ display: 'flex' }}>
                <Typography className={classes.addressLabel} align="left" variant="body2">
                  Entrega a las {getDeliveryTime(type)}
                </Typography>
              </div>
              <AddressContainer type={type} address={address} />
            </div>
          </div>
        </Hidden>
        <Hidden smDown>
          <div className={classes.container}>
            <div onClick={() => setDialogOpen(true, 'address')}>
              <Typography className={classes.addressLabel} align="left" variant="body2">
                Entregar en
              </Typography>
              <AddressContainer type={type} address={address} />
            </div>
            <div className={classes.timeContainer}>
              <Update color="primary" style={{ marginRight: '0.5rem' }} />
              <Typography className={classes.addressLabel} align="left" variant="body2">
                Entrega a las {getDeliveryTime(type)}
              </Typography>
            </div>
          </div>
        </Hidden>
      </>
    )
  }
  return (
    <>
      <Hidden mdUp>
        <div className={classes.container} onClick={() => setDialogOpen(true, 'address')}>
          <div>
            <Typography className={classes.addressLabel} align="left" variant="body2">
              ¿Dónde pedirás hoy?
            </Typography>
            <div className={classes.addressContainer}>
              <Typography align="left" variant="subtitle1" style={{ lineHeight: 1 }}>
                Escoge la dirección
              </Typography>
              <ExpandMore />
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden smDown>
        <div className={classes.container}>
          <div onClick={() => setDialogOpen(true, 'address')}>
            <Typography className={classes.addressLabel} align="left" variant="body2">
              ¿Dónde pedirás hoy?
            </Typography>
            <div className={classes.addressContainer}>
              <Typography align="left" variant="subtitle1" style={{ lineHeight: 1 }}>
                Escoge la dirección
              </Typography>
              <ExpandMore />
            </div>
          </div>
        </div>
      </Hidden>
    </>
  )
}

export default AddressHandler

const distanceBetweenPoints = (lat1, lon1, lat2, lon2) => {  // generally used geo measurement function
    var R = 6378.137; // Radius of earth in KM
    var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
    var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d * 1000; // meters
}

/**
 *
 * @param {*} latLng Object { lat: float, lng: float }
 * @param {*} zone Object { ..., radius: int, center: array[2] }
 */
export const pointInZone = (latLng, zone) => {
    const { lat, lng } = latLng;
    const _distanceBetweenPoints = distanceBetweenPoints(
        zone.center[0],
        zone.center[1],
        lat,
        lng,
    )
    if (_distanceBetweenPoints <= zone.radius) {
        return true;
    }
    return false;
};

export const deliveryCalculator = (fee, store) => {
    return store.deliveryCopay ? parseInt(fee * (1 - store.deliveryCopay) / 100, 10) : parseInt(fee / 100, 10);
}

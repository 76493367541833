import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, ApolloProvider, InMemoryCache, useQuery, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createMuiTheme, LinearProgress, responsiveFontSizes, ThemeProvider } from '@material-ui/core'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { initialize as initializeGTM } from './helpers/gtm';
import { GET_RESTAURANT } from './gql/restaurant';
import './index.css';
import './styles/fonts.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MainContext, MainContextProvider } from './context/MainContext'
import host from './config/host';
import customFetch from './helpers/customFetch';
import 'moment/locale/es'
import { addGoogleTagManager, addFacebookDomainVerificationTag } from './helpers/tagsConfig';

initializeGTM();
Sentry.init({
  dsn: `https://${process.env.REACT_APP_SENTRY_KEY}.ingest.sentry.io/5779248`,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.3,
});


const ThemeManager = () => {
  const [loading, setLoading] = useState(true);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('none');
  const [muiTheme, setMuiTheme] = useState(null);
  const { setRestaurant } = useContext(MainContext);
  const { data, error } = useQuery(GET_RESTAURANT, {
    variables: {
      restaurantId: process.env.REACT_APP_RESTAURANT_ID,
    },
  })

  const getFavicon = () => document.getElementById("favicon");

  const handleGoogleTagManager = (googleTagManagerId) => {
    if (!googleTagManagerId) return;

    if (googleTagManagerId) {
      addGoogleTagManager(googleTagManagerId);
    }
  }

  const handleFacebookDomainVerificationTag = (facebookDomainVerification) => {
    if (!facebookDomainVerification) return;

    if (facebookDomainVerification) {
      addFacebookDomainVerificationTag(facebookDomainVerification);
    }
  }

  useEffect(() => {
    if (data) {
      const { optimizedRestaurant: restaurant } = data;
      setRestaurant(restaurant)
      const favicon = getFavicon(); // Access favicon element
      if (restaurant.favicon) {
        favicon.href = restaurant.favicon.url;
      }
      document.title = restaurant.name;
      if (restaurant.backgroundImage) {
        setBackgroundImageUrl(restaurant.backgroundImage.url);
      }
      if (restaurant.muiTheme) {
        setMuiTheme(restaurant.muiTheme);
      }
      handleGoogleTagManager(restaurant.googleTagManagerId);
      handleFacebookDomainVerificationTag(restaurant.facebookDomainVerification)
      setLoading(false);
    }
  }, [data])

  if (loading) return <LinearProgress />
  if (error) {
    console.log(error);
    return <p>Error :(</p>
  }

  let theme = createMuiTheme({
    backgroundImageUrl,
  });
  if (muiTheme) {
    theme = createMuiTheme({
      ...muiTheme,
      backgroundImageUrl,
    });
    theme = responsiveFontSizes(theme);
  }

  return (
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  )
}

const ApolloRouting = ({ children }) => {
  const { login, logout, user, apolloClient, setApolloClient } = useContext(MainContext);
  const cache = new InMemoryCache();

  useEffect(() => {
    let newApolloClient = null;
    const httpLink = new HttpLink({
      uri: `${host}/graphql`,
      fetch: (uri, options) => customFetch(uri, options, login, logout, user),
    });

    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: user ? `Bearer ${user.jwt}` : '',
      },
    }));

    newApolloClient = new ApolloClient({
      cache,
      link: authLink.concat(httpLink),
    });
    setApolloClient(newApolloClient);
  }, [user])

  if (!apolloClient) return null;

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <MainContextProvider>
      <ApolloRouting>
        <ThemeManager />
      </ApolloRouting>
    </MainContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useContext, useEffect } from 'react'
import _ from 'lodash';
import {
  Hidden,
  Grid,
  LinearProgress,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { FoodMenu } from '../components/foodMenu'
import { Cart } from '../components/cart'
import { AddressHandler } from '../components/delivery'
import { MainContext } from '../context/MainContext'
import { Storefront } from '@material-ui/icons';
import { Link } from 'react-router-dom';


const GET_DATA = gql`
  query GetData($restaurantId: ID!)
    {
      getProducts (restaurantId: $restaurantId)
      optimizedZones (restaurantId: $restaurantId)
      getTodayPromo (restaurantId: $restaurantId) {
        id
        rule
        type
        day
        name
        categories {
          id
        }
      }
    }
  `;

const useStyles = makeStyles((theme) => ({
  cartContainer: {
    backgroundColor: theme.palette.background.default,
  },
  cartSticky: {
    position: 'sticky',
    top: 110,
    height: 'calc(100vh - 50px)',
    boxShadow: '-6px 12px 9px -11px rgba(0,0,0,0.75)',
    zIndex: 3,
  },
  inStoreButtonContainer: {
    margin: '0.5rem',
  },
  itemButton: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
}))

const Menu = () => {
  const classes = useStyles();
  const {
    menus,
    setMenus,
    dailyPromo,
    setDailyPromo,
    restaurant,
  } = useContext(MainContext)
  const { loading, error, data } = useQuery(GET_DATA, {
    variables: {
      restaurantId: process.env.REACT_APP_RESTAURANT_ID,
    },
  });

  useEffect(() => {
    if (data) {
      setMenus([{ categories: _.cloneDeep(data.getProducts) }]);
      if (!dailyPromo) {
        setDailyPromo(data.getTodayPromo)
      }
    }
  }, [data])



  if (loading) return (
    <div style={{ height: '90vh' }}>
      <LinearProgress />
    </div>
  )
  if (error) {
    console.log(error);
    return <p>Error :(</p>
  }

  return (
    <Grid container justify="space-between">
      {restaurant.in_store_menus?.length > 0 && (
        <Grid item xs={12} className={classes.inStoreButtonContainer}>
          <Link
            to={{
              pathname: '/in-store-menus',
            }}
            className={classes.itemButton}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              startIcon={<Storefront />}
            >
              Revisa la carta para pedir en el local
            </Button>
          </Link>
        </Grid>
      )}

      <Grid item xs={12} md={9}>
        <FoodMenu menus={menus || [{ categories: _.cloneDeep(data.getProducts) }]} />
      </Grid>
      <Hidden smDown>
        <Grid item md={3} className={classes.cartContainer}>
          <div className={classes.cartSticky}>
            <div style={{ margin: '3rem 2rem 0 0', }}>
              <AddressHandler />
            </div>
            <Cart />
          </div>
        </Grid>
      </Hidden>
    </Grid>
  )
}

export default Menu

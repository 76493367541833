import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser (
    $firstName: String!
    $lastName: String!
    $username: String!
    $email: String!
    $password: String!
    $phoneNumber: String!
  ) {
    createUser (
      input: {
        data : {
          first_name: $firstName,
          last_name: $lastName,
          username: $username,
          email: $email,
          password: $password,
          phone_number: $phoneNumber,
        }
      }
    ) {
      user {
        id
      }
    }
  }
`;

export const GET_USER_CARDS = gql`
query getMyCards ($restaurantId: ID!) {
  getMyCards(restaurantId: $restaurantId)
}
`;

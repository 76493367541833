import React from 'react'
import {
  Dialog,
  Container,
  IconButton
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Configure } from '../configure'
import { useStyles } from './style';

const ConfigureDialog = ({ open, item, onClose }) => {
  const classes = useStyles();
  return(
    <Dialog
      open={open}
      onClose={onClose}
      classes={{paperScrollPaper: classes.scroller}}>
      <Container maxWidth="xs" className={classes.container}>
        <IconButton
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}
          onClick={onClose}>
          <Close/>
        </IconButton>
        <Configure
          item={item}
          onDialog
          handleClose={onClose}/>
      </Container>
    </Dialog>
  )
}

export default ConfigureDialog

export const urlParser = (url) => {
  const search = url.replace('?', '');
  if (!search) return null;
  const jsonResponse = JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);
  return jsonResponse;
};

export const capitalize = (string) => 
  string[0].toUpperCase() + string.substring(1, string.length);

export const restaurantHasFeature = (restaurant, feature) => {
  if (!restaurant.features) return false;
  const features = restaurant.features.map((feat) => feat.feature);
  return features.includes(feature);
}
/* eslint-disable eqeqeq */

export const getTotalPrice = (cart) =>
  cart.reduce((acc, item) => acc + item.totalPrice, 0);

export const getTotalItems = (cart) => 
  cart.reduce((acc, item) => acc + item.quantity, 0);

export const getTotalByItem = (cart, itemId) =>
  cart
    .filter((item) => item.id === itemId)
    .reduce((acc, item) => acc + item.quantity, 0);

export const getTotalPriceForCategory = (cart, categoryId) => {
  return cart.filter(item => item.category.id == categoryId)
    .reduce((acc, item) => acc + item.totalPrice, 0);
}

export const getTotalItemsForCategory = (cart, categoryId) => {
  return cart.filter(item => item.category.id == categoryId)
    .reduce((acc, item) => acc + item.quantity, 0);
}

export const getTotalPriceForItem = (cart, itemId) => {
  return cart.filter(item => item.id == itemId)
    .reduce((acc, item) => acc + item.totalPrice, 0);
}

export const getPromosDiscounts = (cart, promos) => {
  let currentDiscount = 0;
  let promosDiscounts = [];
  promos.forEach((promo) => {
    let promoDiscount = 0;
    switch (promo.type) {
      case 'checkDiscount': {
        promoDiscount = (getTotalPrice(cart) - currentDiscount) * parseInt(promo.rule, 10) / 100;
        currentDiscount += promoDiscount;
        break;
      }
      case 'categoryDiscount': {
        promoDiscount = (promo.categories.reduce((acc, cat) => {
          return acc + getTotalPriceForCategory(cart, cat.id)
        }, 0) - currentDiscount) * parseInt(promo.rule, 10) / 100;
        currentDiscount += promoDiscount;
        break;
      }
      case 'itemDiscount': {
        promoDiscount = (promo.items.reduce((acc, item) => {
          return acc + getTotalPriceForItem(cart, item.id)
        }, 0) - currentDiscount) * parseInt(promo.rule, 10) / 100;
        currentDiscount += promoDiscount;
        break;
      }
      case 'checkYxZ': {
        const splitRule = promo.rule.split("x"); // Format is always YxZ
        const y = parseInt(splitRule[0], 10)
        const z = parseInt(splitRule[1], 10)
        const toRemove = y - z // Y - Z. Ex: 4x3 = 1 free item, 5x3 = 2 free items
        let newCart = []
        cart.forEach((item) => {
          for (var j = 0; j < item.quantity; j++) {
            newCart.push(item);
          }
        });
        newCart.sort((a, b) => a.totalPrice - b.totalPrice)
        const totalItems = newCart.length;
        let totalDiscount = 0;
        for (var i = 0; i < parseInt(totalItems / y, 10) * toRemove; i++) {
          totalDiscount += newCart[i].unitPrice;
        }
        promoDiscount = totalDiscount
        currentDiscount += promoDiscount;
        break;
      }
      case 'categoryYxZ': {
        const splitRule = promo.rule.split("x"); // Format is always YxZ
        const y = parseInt(splitRule[0], 10)
        const z = parseInt(splitRule[1], 10)
        const categoriesIds = promo.categories.map((cat) => cat.id);
        const toRemove = y - z // Y - Z. Ex: 4x3 = 1 free item, 5x3 = 2 free items
        let newCart = []
        cart.forEach((item) => {
          if (categoriesIds.includes(item.category.id)) {
            for (var j = 0; j < item.quantity; j++) {
              newCart.push(item);
            }
          }
        });
        newCart.sort((a, b) => a.totalPrice - b.totalPrice)
        const totalCategoryItems = categoriesIds.reduce((acc, id) => {
          return acc + getTotalItemsForCategory(cart, id)
        }, 0)
        let totalDiscount = 0;
        for (var i = 0; i < parseInt(totalCategoryItems / y, 10) * toRemove; i++) {
          totalDiscount += newCart[i].unitPrice;
        }
        promoDiscount = totalDiscount
        currentDiscount += promoDiscount;
        break;
      }
      case 'itemYxZ': {
        const splitRule = promo.rule.split("x"); // Format is always YxZ
        const y = parseInt(splitRule[0], 10)
        const z = parseInt(splitRule[1], 10)
        const toRemove = y - z // Y - Z. Ex: 4x3 = 1 free item, 5x3 = 2 free items
        const itemsIds = promo.items.map((item) => item.id);
        let newCart = []
        cart.forEach((item) => {
          if (itemsIds.includes(item.id)) {
            for (var j = 0; j < item.quantity; j++) {
              newCart.push(item);
            }
          }
        });
        newCart.sort((a, b) => a.totalPrice - b.totalPrice)
        const totalItems = itemsIds.reduce((acc, id) => {
          return acc + getTotalByItem(cart, id)
        }, 0)
        let totalDiscount = 0;
        for (var k = 0; k < parseInt(totalItems / y, 10) * toRemove; k++) {
          totalDiscount += newCart[k].unitPrice;
        }
        promoDiscount = totalDiscount
        currentDiscount += promoDiscount;
        break;
      }
      default: {
        break;
      }
    }
    promosDiscounts.push({ ...promo, moneyDiscount: promoDiscount });
  })
  return promosDiscounts;
}

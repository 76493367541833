import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${theme.backgroundImageUrl})`,
    backgroundSize: 500,
    [theme.breakpoints.up('md')]: {
      padding: '2rem 0',
    }
  },
}));
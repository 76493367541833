import React, { useState, useContext, useRef, useEffect } from "react"
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Button,
  CircularProgress,
  TextField,
} from '@material-ui/core'
import { FormField } from './'
import { MainContext } from '../../context/MainContext'
import { forgotPassword, validateCode, resetPassword } from '../../apiCalls/strapi'
import { ScrollToTopOnMount } from '../utils'

const useStyles = makeStyles(theme => ({
  container: {
    padding: '3em 2em',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.custom.header.backgroundColor,
      color: 'white',
    },
  },
  title: {
    marginBottom: '1rem',
  },
  fieldContainer: {
    marginBottom: '1rem',
  },
  buttonContainer: {
    marginTop: '2rem',
    width: '100%',
  },
  button: {
    height: 45,
    fontSize: '1.1rem',
  },
  registerLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  registerLink: {
    marginLeft: '1rem',
    fontSize: 14,
    padding: 0,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'inherit',
    }
  },
  forgetLink: {
    width: '100%',
    paddingBottom: 0,
    fontSize: 14,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'inherit',
    }
  },
  tokenTextFieldsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0.5rem',
  },
  tokenTextField: {
    width: '18%',
  },
  loadingSendCodeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tokenInput: {
    padding: '0 14px',
    minHeight: '4rem',
    fontSize: 30,
    textAlign: 'center',
  },
  tokenInputField: {
    backgroundColor: 'white',
  },
}))

const SuccessStep = () => {
  const classes = useStyles();
  const { setDialogOpen } = useContext(MainContext);

  const handleCloseDialog = () => {
    setDialogOpen(false, 'forgotPassword');
  }

  return (
    <div className={classes.container}>
      <Typography variant="h4" align="center" className={classes.title}>
        Cambio exitoso
      </Typography>
      <Typography variant="h6" align="center" className={classes.title}>
        Desde ahora puedes usar tu nueva contraseña
      </Typography>
      <Button
        fullWidth
        variant="contained"
        onClick={handleCloseDialog}
        color="primary"
      >
        Entendido
      </Button>
    </div>
  )
}

const NewPasswordStep = ({ next, userToken }) => {
  const fields = ["password"];
  const classes = useStyles();
  const { setUser } = useContext(MainContext)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState({
    password: {
      value: '',
      error: '',
    },
  });

  const handleChange = (name, value, error) => {
    setData(data => ({
      ...data,
      [name]: {
        ...data[name], value, error
      },
    }))
  }

  const handleResetPassword = () => {
    setLoading(true);
    const { password } = data;
    resetPassword(userToken, password.value, password.value)
      .then((response) => {
        const { user, jwt } = response.data
        setUser({ ...user, jwt })
        setLoading(true);
        next();
      })
      .catch(() => {
        setLoading(false);
        setError('No pudimos cambiar tu contraseña, intenta nuevamente')
      })
  }

  return (
    <div className={classes.container}>
      <Typography variant="h4" align="center" className={classes.title}>
        Escribe tu nueva contraseña
      </Typography>
      {fields.map((field) =>
        <div key={field} className={classes.fieldContainer}>
          <FormField
            type={field}
            name={field}
            error={data[field].error}
            value={data[field].value}
            onChange={handleChange} />
        </div>
      )}
      <div className={classes.buttonContainer}>
        <Typography color="error" variant="body2" align="center" gutterBottom>{error}</Typography>
        <Button
          onClick={handleResetPassword}
          className={classes.button}
          fullWidth
          disabled={loading}
          endIcon={loading ? <CircularProgress size={20} /> : null}
          variant="contained"
          color="primary"
        >
          Cambiar contraseña
        </Button>
      </div>

    </div>
  )
}


const TokenStep = ({ next, userEmail, updateUserToken }) => {
  const classes = useStyles();
  const [token, setToken] = useState(['', '', '', '']);
  const [timer, setTimer] = useState(30);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [resendData, setResendData] = useState({
    color: 'initial',
    message: '',
  });
  const textFieldsRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleChange = (e, i) => {
    let { value } = e.target;
    value = value.substring(0, 1).toUpperCase();
    const newToken = [...token];
    newToken[i] = value;
    if (i < 3 && value.length === 1) {
      textFieldsRefs[i + 1].current.select();
      newToken[i + 1] = '';
    }
    setToken(newToken);
    const charsWritten = newToken.reduce((acc, value) => acc + value, '')
    // user wrote the last character of the code and all of the other are filled
    if (i === 3 && charsWritten.length === 4) {
      setLoading(true);
      validateCode(charsWritten)
        .then(() => {
          setLoading(false);
          updateUserToken(charsWritten);
          next();
        })
        .catch(() => {
          setLoading(false);
          setError('Código inválido, intenta nuevamente')
        })
    }
  }

  const handleResendToken = () => {
    setTimer(60);
    forgotPassword(userEmail)
      .then((response) => {
        setResendData({
          color: 'success',
          message: 'Código reenviado con éxito',
        })
      })
      .catch((error) => {
        setTimer(0);
        setResendData({
          color: 'error',
          message: 'No pudimos reenviar el código',
        })
      });
  }

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer((oldTimer) => oldTimer - 1);
      }, 1000)
    }
  }, [timer])

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <Typography variant="h4" align="center">
          Ingresa el código enviado a tu email
      </Typography>
        <Typography align="center">
          Recuerda revisar la carpeta "Spam" o "No deseados"
      </Typography>
      </div>
      <div style={{ marginBottom: '2rem' }}>
        <div className={classes.tokenTextFieldsContainer}>
          {token.map((value, i) => (
            <TextField
              disabled={loading}
              className={classes.tokenTextField}
              value={value}
              onChange={(e) => handleChange(e, i)}
              variant="outlined"
              inputProps={{
                ref: textFieldsRefs[i],
                className: classes.tokenInput,
              }}
              InputProps={{
                className: classes.tokenInputField,
              }}
            />
          ))}
        </div>
        {loading && (
          <div className={classes.loadingSendCodeContainer}>
            <CircularProgress size={16} style={{ marginRight: '0.5rem' }} />
            <Typography>Validando el código</Typography>
          </div>
        )}
        <Typography variant="caption" color="error" align="center" component="div">
          {error}
        </Typography>
      </div>
      <Typography gutterBottom variant="caption" color={resendData.color} align="center" component="div">
        {resendData.message}
      </Typography>
      <Button
        fullWidth
        onClick={handleResendToken}
        disabled={timer > 0}
        endIcon={timer > 0 ? <Typography variant="h6">{timer}</Typography> : null}
        color="primary"
        variant="contained"
      >
        Reenviar código
      </Button>
    </div>
  )
};

const InputEmailStep = ({ onDialog, next, updateUserEmail }) => {
  const fields = ["email"]
  const classes = useStyles();
  const history = useHistory();
  const { setDialogOpen } = useContext(MainContext);
  const [data, setData] = useState({
    email: {
      value: '',
      error: '',
    },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (name, value, error) => {
    setData(data => ({
      ...data,
      [name]: {
        ...data[name], value, error
      },
    }))
  }

  const handleForgotPassword = () => {
    // Other errors are handled in FormField and written in .error
    if (data.email.value === '' || data.email.error !== '') {
      return;
    }

    setLoading(true);
    setError('');
    forgotPassword(data.email.value)
      .then(response => {
        // Handle success.
        if (response.data.ok) {
          updateUserEmail(data.email.value);
          next();
        }
        setLoading(false);
      })
      .catch(error => {
        // Handle error.
        setLoading(false);
        setError('No pudimos recuperar tu contraseña');
      });
  }

  const navigateToLogin = () => {
    if (onDialog) {
      setDialogOpen(false, 'forgotPassword')
      setDialogOpen(true, 'login')
    }
    else history.replace('login')
  }

  return (
    <div className={classes.container}>
      <Typography variant="h4" align="center" className={classes.title}>
        Recupera tu contraseña
      </Typography>
      {fields.map((field) =>
        <div key={field} className={classes.fieldContainer}>
          <FormField
            type={field}
            name={field}
            error={data[field].error}
            value={data[field].value}
            onChange={handleChange} />

        </div>
      )}
      <div className={classes.buttonContainer}>
        <Typography color="error" variant="body2" align="center" gutterBottom>{error}</Typography>
        <Button
          onClick={handleForgotPassword}
          className={classes.button}
          fullWidth
          disabled={loading}
          endIcon={loading ? <CircularProgress size={20} /> : null}
          variant="contained"
          color="primary"
        >
          Recuperar contraseña
        </Button>
        <Button
          color="primary"
          onClick={navigateToLogin}
          className={classes.forgetLink}>
          Volver
        </Button>
      </div>
    </div>
  )
}

const ForgotPassword = ({ onDialog }) => {
  const [step, setStep] = useState(0);
  const [userEmail, setUserEmail] = useState('');
  const [userToken, setUserToken] = useState('');

  return (
    <>
      <ScrollToTopOnMount />
      {step === 0 && (
        <InputEmailStep next={() => setStep(1)} onDialog={onDialog} updateUserEmail={setUserEmail} />
      )}
      {step === 1 && (
        <TokenStep next={() => setStep(2)} userEmail={userEmail} updateUserToken={setUserToken} />
      )}
      {step === 2 && (
        <NewPasswordStep next={() => setStep(3)} userToken={userToken} />
      )}
      {step === 3 && (
        <SuccessStep />
      )}
    </>
  )

}

export default ForgotPassword

import axios from 'axios'
import host from '../config/host';

export const register = (email, password, telephone, firstName, lastName, restaurantId) => {
  return axios.post(`${host}/auth/local/register`, {
    username: email,
    email: email,
    password: password,
    phone_number: telephone,
    first_name: firstName,
    last_name: lastName,
    frontHost: window.location.origin,
    restaurantId: restaurantId,
  })
};

export const login = (email, password) => {
  return axios.post(`${host}/auth/local`, {
    identifier: email,
    password: password,
  })
};

export const updateUser = (data) => {
  const { user, firstName, lastName, phoneNumber } = data;
  return axios.patch(`${host}/users/me/${user.id}`, {
    firstName, lastName, phoneNumber
  },
  {
    headers: {
      Authorization: 'Bearer ' + user.jwt,
    }
  })
}

export const createTransbankTransaction = (authToken, order) => {
  return axios.post(`${host}/payments/webpay/create-transaction`, {
    amount: order.total_price,
    restaurant: order.restaurant.id,
    orderId: order.id,
    frontHost: window.location.origin,
  },
  {
    headers: {
      Authorization: 'Bearer ' + authToken,
    }
  })
};


export const createOneClickTransaction = (authToken, order) => {
  return axios.post(`${host}/payments/one-click/create-transaction`, {
    amount: order.total_price,
    restaurant: order.restaurant.id,
    orderId: order.id,
    frontHost: window.location.origin,
  },
  {
    headers: {
      Authorization: 'Bearer ' + authToken,
    }
  })
};

export const oneClickStartInscription = (authToken, restaurant) => {
  return axios.post(`${host}/payments/one-click/start-inscription`, {
    frontHost: window.location.origin,
    restaurant: restaurant.id,
    paymentOnInscription: false,
  },
  {
    headers: {
      Authorization: 'Bearer ' + authToken,
    }
  })
};

export const klapStartPayment = (authToken, order, methods) => {
  return axios.post(`${host}/payments/klap/start-payment`, {
    order,
    methods
  },
  {
    headers: {
      Authorization: 'Bearer ' + authToken,
    }
  })
}

export const sendEmailConfirmation = (email) => {
  return axios
  .post(`${host}/auth/send-email-confirmation`, {
    email,
    frontHost: window.location.origin,
  });
};

export const confirmEmail = (confirmation) => axios
  .get(`${host}/auth/email-confirmation`, {
    params: {
      confirmation: confirmation,
    },
  })

export const forgotPassword = (email) => {
  return axios
  .post(`${host}/auth/forgot-password`, {
    email,
  });
};

export const validateCode = (code) => {
  return axios
  .post(`${host}/auth/validate-code`, {
    code,
  });
};

export const resetPassword = (code, password, passwordConfirmation) => {
  return axios
  .post(`${host}/auth/reset-password`, {
    code,
    password,
    passwordConfirmation,
  })
}

export const refreshToken = (jwt) => {
  return axios
    .post(`${host}/auth/refresh-token`, {
      jwt
    });
};

import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Grid, Step, StepContent, StepIcon, StepLabel, Stepper, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Lottie from 'react-lottie';
import * as cookingAnimation from '../../assets/animations/cooking.json';
import * as waitingAnimation from '../../assets/animations/waiting.json';
import * as deliveryAnimation from '../../assets/animations/delivery.json';
import * as creatingAnimation from '../../assets/animations/creating.json';
import * as packingAnimation from '../../assets/animations/packing.json';
import * as readyForPickUpAnimation from '../../assets/animations/readyForPickUp.json';
import { GET_ORDER_STATUS } from '../../gql/orders';
import { Cancel, CheckCircle, Warning } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0.5em 1em',
    backgroundColor: '#EEE',
    color: 'black',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
    }
  },
  stepContentTransition: {
    width: 'fit-content',
  },
  animation: {
    width: '300px',
    height: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'inherit',
    },
  },
  successColor: {
    color: theme.palette.success,
  },
  warningColor: {
    color: theme.palette.warning,
  },
  errorColor: {
    color: theme.palette.error,
  },
}));

const statusToStep = {
  // 'CREATED',
  // 'ACCEPTED',
  PREPARING: 0,
  pending: 0,
  PREPARED: 1,
  pickup: 1,
  IN_TRANSIT: 2,
  pickup_complete: 2,
  dropoff: 3,
}

const deliveryPossibleStatus = [
  'pending',
  'pickup',
  'pickup_complete',
  'dropoff',
];

const takeAwayPossibleStatus = [
  'PREPARING',
  'PREPARED',
  'IN_TRANSIT',
];

const getStatusText = (status) => {
  switch (status) {
    // case 'CREATED':
    //   return 'Recibiendo pedido';
    // case 'ACCEPTED':
    //   return 'Esperando para enviar a cocina';
    case 'PREPARING':
    case 'pending':
      return 'Cocinando pedido';
    case 'PREPARED':
      return 'Empacando pedido';
    case 'pickup':
      return 'Repartidor en camino a buscar tu pedido'
    case 'IN_TRANSIT':
      return 'Listo para retiro';
    case 'pickup_complete':
      return 'Pedido retirado';
    case 'dropoff':
      return 'Pedido en camino';
    default:
      return '';
  }
}

const getStatusAnimation = (status) => {
  switch (status) {
    // case 'CREATED':
    //   return creatingAnimation;
    // case 'ACCEPTED':
    //   return waitingAnimation;
    case 'PREPARING':
    case 'pending':
      return cookingAnimation;
    case 'PREPARED':
      return packingAnimation;
    case 'IN_TRANSIT':
    case 'pickup_complete':
        return readyForPickUpAnimation;
    case 'pickup':
      case 'dropoff':
        return deliveryAnimation;
        default:
      return '';
  }
}

const Status = ({ status, deliveryType, orderId }) => {
  const classes = useStyles();
  // When getStatus is called, apollo updates de order at the parent level
  const [getStatus] = useLazyQuery(GET_ORDER_STATUS, {
    variables: {
      id: orderId,
    },
    fetchPolicy: 'network-only',
  });
  const possibleStatus = deliveryType === 'takeAway' ? takeAwayPossibleStatus : deliveryPossibleStatus;
  const [activeStep, setActiveStep] = useState(statusToStep[status]);
  const theme = useTheme();

  useEffect(() => {
    let interval;
    if (status !== 'DELIVERED') {
      interval = setInterval(() => {
        getStatus();
      }, 120000);
    }
    return () => interval && clearInterval(interval);
  }, []);

  useEffect(() => setActiveStep(statusToStep[status]), [status]);

  const MyStepContent = (
    <StepContent
      classes={{
        transition: classes.stepContentTransition,
      }}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: getStatusAnimation(status).default,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        className={classes.animation}
      />
    </StepContent>
  )

  const OrderStatusStepper = () => {
    if (possibleStatus.includes(status) || status === 'PENDING') {
      return (
        <Stepper activeStep={activeStep} orientation="vertical">
          {possibleStatus.map((possibleStatus) => (
            <Step key={possibleStatus}>
              <StepLabel>{getStatusText(possibleStatus)}</StepLabel>
              {MyStepContent}
            </Step>
          ))}
        </Stepper>
      )
    }
    let text = '';
    let icon = null;
    if (status === 'DELIVERED' || status === 'delivered') {
      text = 'Pedido entregado';
      icon = (
        <CheckCircle style={{ color: theme.palette.success.main }} />
      )
    }
    if (status === 'canceled') {
      text = 'Entrega cancelada, comunícate con nosotros';
      icon = (
        <Cancel style={{ color: theme.palette.error.main }} />
      )
    }
    if (status === 'returned') {
      text = 'Productos devueltos al local, comunícate con nosotros';
      icon = (
        <Warning style={{ color: theme.palette.warning.main }} />
      )
    }
    return (
      <Grid container direction="row">
        <div style={{ marginRight: '0.5rem' }}>
          {icon}
        </div>
        <Typography>{text}</Typography>
      </Grid>
    )
  }

  return (
    <>
      <div className={classes.label}>
        <Typography><b>Estado de tu pedido</b></Typography>
        <Typography variant="caption">Actualización cada 2 minutos o al recargar la página</Typography>
      </div>
      <OrderStatusStepper />
    </>
  )
};

export default Status;

import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MainContext } from '../context/MainContext';
import { sendTransactionData } from '../helpers/gtm';
import { klapAvailablePayments } from '../helpers/payments';

const PaymentSuccess = () => {
  const history = useHistory();
  const {
    order,
    setOrder,
    cleanCart,
    currentTransactionToken,
    setCurrentTransactionToken,
    cleanPromotionalCodes } = useContext(MainContext);
  const receivedToken = history.location.search ?
    history.location.search.split('=')[1] :
    null;
  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const klapOrderId = query.get('orderId');

  useEffect(() => {
    if (
      !order
      || (order && order.payment_method === 'Webpay' && (receivedToken !== currentTransactionToken))
      || (order && klapAvailablePayments.includes(order.payment_method) && (klapOrderId !== currentTransactionToken))
      ) {
      history.replace('/');
      return;
    }
    if (
      (order.payment_method !== 'Webpay' || receivedToken === currentTransactionToken)
      || (klapAvailablePayments.includes(order.payment_method) && klapOrderId === currentTransactionToken)
      ) {
      sendTransactionData({
        event: 'purchase',
        transactionId: order.id,
        transactionTotal: order.total_price,
        transactionTax: order.total_price - order.total_price / 1.19,
        transactionShipping: order.delivery_price,
        transactionProducts: order.items.map((item) => ({
          sku: item.id,
          name: item.name,
          price: item.price,
          category: item.category.name,
          quantity: item.quantity,
        })),
       });
      setCurrentTransactionToken(null);
      const orderId = order.id;
      setOrder(null);
      cleanCart();
      cleanPromotionalCodes();
      history.replace(`/orders/${orderId}`);
    }
  }, [])

  return <></>;
}

export default PaymentSuccess;
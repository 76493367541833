import React, { useState, useContext } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { MainContext } from '../../context/MainContext'

const MenuSelect = ({ menus, setMenu, menu }) => {
  const mainContext = useContext(MainContext)

  const handleChange = (event) => {
    setMenu(event.target.value)
    mainContext.setMenu(event.target.value)
  }
  return(
    <FormControl style={{width: '100%'}} variant="outlined">
      <InputLabel htmlFor="outlined-menu-native-simple"><b>Elige la carta</b></InputLabel>
      <Select
        size="small"
        value={menu}
        onChange={handleChange}
        label="Elige la carta"
        inputProps={{
          name: 'menu',
          id: 'outlined-menu-native-simple',
          style: { paddingBottom: '0.5rem', paddingTop: '0.5rem', fontSize: 14 },
        }}
      >
      {menus.map((menu, i) =>
        <MenuItem key={menu.name} value={i}>{menu.name}</MenuItem>
      )}
      </Select>
    </FormControl>
  )
}

export default MenuSelect

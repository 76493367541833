import React, { useState } from 'react'
import {
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandMore } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  summary: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  detailRow: {
    padding: '0.5rem 1rem',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    }
  }
}))

const SelectAddress = ({ user, handleAddress }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const handleSelect = (address) => {
    handleAddress(address)
    setExpanded(false)
  }

  const onChange = (e, expanded) => {
    setExpanded(expanded)
  }

  return (
    <Paper>
      <Accordion expanded={expanded} onChange={onChange}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Usar una dirección guardada</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.detailsContainer}>
          {user.addresses
            .filter((address) => !address.deletedAt)
            .sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10))
            .map(address =>
              <div key={address.id} className={classes.detailRow} onClick={() => handleSelect(address)}>
                <Typography key={address.id}>{address.long}</Typography>
              </div>
            )}
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}

export default SelectAddress

import React, { useContext } from 'react'
import {
  Typography,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  PhoneAndroid,
  MailOutline,
  Instagram,
  Facebook,
  Phone,
} from '@material-ui/icons'
import { MainContext } from '../../context/MainContext';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#0A0A0A',
    padding: '2rem 10rem',
    color: 'white',
    [theme.breakpoints.down("sm")]: {
      padding: '1rem 2rem 3rem',
    }
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    padding: 2,
  },
  logo: {
    width: 90,
  },
  infoWithIcon: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.5rem 0'
  },
  icon: {
    marginRight: '1rem',
    fontSize: 20,
  },
  item: {
    padding: 2,
    marginBottom: 5,
  },
  socialMediaIcon: {
    fontSize: 40,
    color: 'white',
    marginTop: 8,
  },
}))

const Footer = () => {
  const classes = useStyles()
  const { restaurant } = useContext(MainContext);
  if (restaurant.contactInformation)
    return (
      <Grid container className={classes.container}>
        <Grid item md={3} xs={5} className={classes.logoContainer}>
          <img
            className={classes.logo}
            alt="Store logo"
            src={restaurant.logo.url}
          />
        </Grid>
        <Grid item md={3} xs={7} className={classes.item}>
          <Typography variant="h6"><b>Servicio al cliente</b></Typography>
          {restaurant.contactInformation.phoneNumber && (
            <div className={classes.infoWithIcon}>
              <PhoneAndroid className={classes.icon} />
              <Typography variant="body2">{restaurant.contactInformation.phoneNumber}</Typography>
            </div>
          )}
          {restaurant.contactInformation.landLine && (
            <div className={classes.infoWithIcon}>
              <Phone className={classes.icon} />
              <Typography variant="body2">{restaurant.contactInformation.landLine}</Typography>
            </div>
          )}
          {restaurant.contactInformation.email && (
            <div className={classes.infoWithIcon}>
              <MailOutline className={classes.icon} />
              <Typography variant="body2">{restaurant.contactInformation.email}</Typography>
            </div>
          )}
        </Grid>
        <Grid item md={3} xs={5} className={classes.item}>
          {(restaurant.contactInformation.facebook || restaurant.contactInformation.instagram) && (
            <>
              <Typography variant="h6"><b>Redes</b></Typography>
              {restaurant.contactInformation.facebook && (
                <a href={`https://www.facebook.com/${restaurant.contactInformation.facebook}`} target="_blank" rel="noopener noreferrer">
                  <Facebook className={classes.socialMediaIcon} />
                </a>
              )}
              {restaurant.contactInformation.instagram && (
                <a href={`https://www.instagram.com/${restaurant.contactInformation.instagram}`} target="_blank" rel="noopener noreferrer">
                  <Instagram className={classes.socialMediaIcon} />
                </a>
              )}
            </>
          )}
        </Grid>
        <Grid item md={3} xs={7} className={classes.item}>
          <Typography variant="h6"><b>Paga con</b></Typography>
          <img src="/images/webpay-logo.png" alt="Webpay logo" />
        </Grid>
      </Grid>
    )
  return null;
}

export default Footer

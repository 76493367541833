import { gql } from '@apollo/client';

export const CREATE_ADDRESS = gql`
    mutation CreateAddress(
        $short: String,
        $long: String,
        $latitude: Float,
        $longitude: Float,
        $default: Boolean,
        $details: String,
        $note: String,
        $user: ID,
        $zone: ID,
      ) {
        createAddress(
          input: {
            data: {
              short: $short,
              long: $long,
              latitude: $latitude,
              longitude: $longitude,
              default: $default,
              details: $details,
              note: $note,
              user: $user,
              zone: $zone,
            }
          }
        ) {
          address {
            id
            short
            long
            latitude
            longitude
            default
            details
            note
            deletedAt
          }
        }
      }
  `;

export const UPDATE_ADDRESS = gql`
    mutation UpdateAddress(
        $id: ID!,
        $short: String,
        $long: String,
        $latitude: Float,
        $longitude: Float,
        $details: String,
        $note: String,
        $default: Boolean,
        $user: ID,
        $zone: ID,
      ) {
        updateAddress(
          input: {
            where: { id: $id }
            data: {
              short: $short,
              long: $long,
              latitude: $latitude,
              longitude: $longitude,
              details: $details,
              note: $note,
              default: $default,
              user: $user,
              zone: $zone,
            }
          }
        ) {
          address {
            id
            short
            long
            latitude
            longitude
            details
            note
            default
            deletedAt
          }
        }
      }
  `;

export const DELETE_ADDRESS = gql`
    mutation UpdateAddress(
        $id: ID!,
        $deletedAt: DateTime!,
      ) {
        updateAddress(
          input: {
            where: { id: $id }
            data: {
              deletedAt: $deletedAt,
            }
          }
        ) {
          address {
            id
            short
            long
            latitude
            longitude
            details
            note
            default
            deletedAt
          }
        }
      }
  `;

export const UPDATE_ADDRESS_USER = gql`
    mutation UpdateUserAddress(
      $id: ID!,
      $userId: ID
    ) {
      updateAddress(
        input: {
          where: { id: $id }
          data: {
            user: $userId
          }
        }
      ) {
        address {
          id
        }
      }
    }
  `;


import React, { useContext, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Container,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ShoppingCart } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { register } from '../apiCalls/strapi';
import {
  SelectTip,
  PromoCode,
  Details,
  UserData,
  PaymentSelect,
} from '../components/orderConfigure';
import { AddressHandler } from '../components/delivery';
import { Alert } from '../components/dialogs';
import { MainContext } from '../context/MainContext';
import { ScrollToTopOnMount } from '../components/utils';
import { getTotalItems, getTotalPrice } from '../helpers/cart';
import { formatMoney } from '../helpers/money';
import { translatePaymentMethod } from '../helpers/i18n';
import { CREATE_ORDER, UPDATE_ORDER } from '../gql/orders';
import { UPDATE_ADDRESS_USER } from '../gql/address';

const _additionals = [
  {
    id: 1,
    name: 'Palitos',
    type: 'quantity',
    freeAmount: 1000,
    freePerCartTotal: 1,
    pricePerExtra: 0,
  },
  {
    id: 2,
    name: 'Wassabi',
    type: 'quantity',
    freeAmount: 2,
    freePerCartTotal: 10000,
    pricePerExtra: 500,
  },
  {
    id: 3,
    name: 'Jengibre',
    type: 'quantity',
    freeAmount: 2,
    freePerCartTotal: 10000,
    pricePerExtra: 500,
  },
]

const useStyles = makeStyles(theme => ({
  background: {
    backgroundImage: `url(${theme.backgroundImageUrl})`,
    backgroundSize: 500,
    [theme.breakpoints.up('md')]: {
      padding: '2rem 0',
    }
  },
  container: {
    padding: '2rem 0 0',
    backgroundColor: 'white',
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.custom.header.backgroundColor,
      color: 'white',
    }
  },
  padder: {
    padding: '0 1rem',
  },
  addressContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
    },
    marginTop: '1rem',
  },
  addressError: {
    paddingLeft: '1rem',
    marginTop: 4,
  },
  marginTop: {
    marginTop: '2rem',
  },
  bottomButton: {
    width: '100%',
    height: '4rem',
    display: 'flex',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    justifyContent: 'space-between',
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    }
  },
}))

const OrderConfigure = () => {
  const classes = useStyles()
  const {
    cart,
    deliveryOptions,
    scheduleOptions,
    promotionalCodes,
    user,
    dailyPromo,
    setOrder,
    order,
    restaurant,
    setUser,
  } = useContext(MainContext)
  const userDataRef = useRef();
  const [tip, setTip] = useState(0)
  const [openAlert, setOpenAlert] = useState(false);
  const [openMinimumAmountAlert, setOpenMinimumAmountAlert] = useState(false);
  const [deliveryError, setDeliveryError] = useState(false);
  const [userData, setUserData] = useState({
    firstName: {
      value: '',
      error: '',
    },
    lastName: {
      value: '',
      error: '',
    },
    email: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
    telephone: {
      value: '',
      error: '',
    }
  });
  const [paymentSelected, setPaymentSelected] = useState(order ? order.payment_method : '');
  const [cartPrice] = useState(getTotalPrice(cart));
  const [additionals, setAdditionals] = useState(
    _additionals.map(additional => ({ ...additional, quantity: 0, totalPrice: 0 }))
  );
  const [loading, setLoading] = useState(false);
  const [orderError, setOrderError] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [createOrder] = useMutation(CREATE_ORDER);
  const [updateOrder] = useMutation(UPDATE_ORDER);
  const [updateAddressUser] = useMutation(UPDATE_ADDRESS_USER);

  const goToPayment = async () => {
    setLoading(true);
    setOrderError(false);
    let newUser;
    if (!user) {
      const { email, password, telephone, firstName, lastName } = userData;
      try {
        const response = await register(email.value, password.value, telephone.value, firstName.value, lastName.value);
        const { data } = response;
        newUser = { ...data.user, jwt: data.jwt };
        setUser(newUser);
      }
      catch (error) {
        const { response } = error;
        if (response.data && response.data.message && response.data.message.length > 0 && response.data.message[0].messages[0].id === 'Auth.form.error.email.taken') {
          handleUserDataChange('email', email.value, 'El email ya existe, si tienes cuenta, inicia sesión')
          userDataRef.current.scrollIntoView(true);
          setLoading(false);
          return;
        }
        handleUserDataChange('email', email.value, 'Hubo un error al procesar tus datos')
        userDataRef.current.scrollIntoView(true);
        setLoading(false);
        setOpenAlert(false);
        return;
      }
    }
    const userId = user ? user.id : newUser.id;
    // Update the address for the user. If he registered or logged in after putting the address, it will not be associated with the user.
    if (deliveryOptions.address) {
      updateAddressUser({ variables: { id: deliveryOptions.address.id, userId } });
    }
    const deliveryPrice = deliveryOptions?.deliveryQuote?.fee || 0;
    const additionalsPrice = additionals.reduce((p, c) => p + c.totalPrice, 0);
    const subTotal = Math.max(0, cartPrice + additionalsPrice);
    const discount = promotionalCodes.reduce((acc, code) => acc + code.moneyDiscount, 0);
    const dailyDiscount = dailyPromo.reduce((acc, code) => acc + code.moneyDiscount, 0);
    const total = Math.max(0,
      subTotal +
      tip -
      discount -
      dailyDiscount
    ) + deliveryPrice;

    const { type: scheduleType, time: scheduledTime } = scheduleOptions;
    const variables = {
      tip,
      additionals,
      deliveryPrice,
      discount,
      additionalsPrice,
      dailyDiscount,
      restaurant: restaurant.id,
      user: userId,
      items: cart,
      deliveryEstimate: deliveryOptions.type === 'delivery' ? deliveryOptions.zone?.time : restaurant.pickup_time,
      address: deliveryOptions.address?.id,
      zone: deliveryOptions.zone?.id,
      promotionalCodes: promotionalCodes.map(code => code.id),
      deliveryType: deliveryOptions.type,
      itemsTotal: cartPrice,
      totalPrice: total,
      status: 'PREPARING',
      paymentMethod: paymentSelected,
      scheduledTime: scheduleType === 'scheduled' ? moment(scheduledTime, 'HH:mm') : null,
    };
    // If order is not null, it means that the user already sent the order to de DB,
    // but the payment failed or was canceled
    if (order && order.status === 'PREPARING') {
      variables.id = order.id;
      updateOrder({ variables })
        .then(async (response) => {
          await setOrder({ ...response.data.updateOrder.order });
          setLoading(false);
          history.push('payment');
        })
        .catch((error) => {
          setOrderError(true);
          setLoading(false);
        })
    } else {
      createOrder({ variables })
        .then(async (response) => {
          await setOrder({ ...response.data.createOrder.order });
          setLoading(false);
          history.push('payment');
        })
        .catch((error) => {
          setOrderError(true);
          setLoading(false);
        })
    }
  }

  const handleUserDataChange = (name, value, error) => {
    setUserData(userData => ({
      ...userData,
      [name]: { ...userData[name], value, error }
    }))
  }

  const handleConfirm = () => {
    setOrderError(false);
    if (!user) {
      let emptyField = false
      if (userData.firstName.value === '') {
        handleUserDataChange('firstName', '', 'El nombre no puede estar vacío')
      }
      if (userData.lastName.value === '') {
        handleUserDataChange('lastName', '', 'El apellido no puede estar vacío')
      }
      if (userData.email.value === '') {
        handleUserDataChange('email', '', 'El email no puede estar vacío')
        emptyField = true
      }
      if (userData.password.value === '') {
        handleUserDataChange('password', '', 'La contraseña no puede estar vacía')
        emptyField = true
      }
      if (userData.telephone.value === '') {
        handleUserDataChange('telephone', '', 'El teléfono no puede estar vacío')
        emptyField = true
      }
      if (emptyField) {
        userDataRef.current.scrollIntoView(true);
        return;
      }
    }
    if (!deliveryOptions || !deliveryOptions.type || (deliveryOptions.type === 'delivery' && !deliveryOptions.address)) {
      window.scrollTo({ top: 0 });
      setDeliveryError(true);
      return;
    }
    setLoading(true);
    setOpenAlert(true)
  }

  const handleConfirmAlertClose = () => {
    setLoading(false);
    setOpenAlert(false);
  }

  const totalItems = getTotalItems(cart);
  // const deliveryPrice = deliveryOptions && deliveryOptions.zone ? deliveryOptions.zone.delivery_cost : 0;
  const deliveryPrice = deliveryOptions?.deliveryQuote?.fee || 0;
  const minimum_order = deliveryOptions && deliveryOptions.zone && deliveryOptions.type === 'delivery' ? deliveryOptions.zone.minimum_order : 0;
  const subTotal = Math.max(0, cartPrice + additionals.reduce((p, c) => p + c.totalPrice, 0));
  const discount = promotionalCodes.reduce((acc, code) => acc + code.moneyDiscount, 0);
  const dailyDiscount = dailyPromo.reduce((acc, code) => acc + code.moneyDiscount, 0);
  const total = Math.max(0,
    subTotal +
    tip -
    discount -
    dailyDiscount
  ) + deliveryPrice;

  return (
    <div className={classes.background}>
      <ScrollToTopOnMount />
      <Container maxWidth="xs" className={classes.container}>
        <div className={classes.padder}>
          <Typography variant="h4">Finaliza tu pedido</Typography>
        </div>
        <div className={classes.addressContainer}>
          <AddressHandler />
          {deliveryError &&
            <Typography
              className={classes.addressError}
              variant="body2"
              color="error">
              Debes escoger un método de entrega
            </Typography>
          }
        </div>
        {/* <div className={classes.marginTop}>
          <SelectAdditionals additionals={additionals} setAdditionals={setAdditionals} />
        </div> */}
        <div className={classes.marginTop}>
          <SelectTip cart={cart} tip={tip} setTip={setTip} />
        </div>
        <div className={classes.marginTop}>
          <PromoCode matches={matches} />
        </div>
        <div className={classes.marginTop}>
          <Details
            cart={cart}
            discount={discount}
            dailyDiscount={dailyDiscount}
            deliveryOptions={deliveryOptions}
            tip={tip}
            promotionalCodes={promotionalCodes}
            additionals={additionals}
            total={total}
            subTotal={subTotal}
            dailyPromo={dailyPromo} />
        </div>
        <div className={classes.marginTop} ref={userDataRef}>
          {!user &&
            <UserData
              matches={matches}
              firstName={userData.firstName}
              lastName={userData.lastName}
              email={userData.email}
              telephone={userData.telephone}
              password={userData.password}
              onChange={handleUserDataChange}
            />
          }
        </div>
        <div className={classes.marginTop}>
          <PaymentSelect
            restaurant={restaurant}
            matches={matches}
            paymentSelected={paymentSelected}
            setPaymentSelected={setPaymentSelected} />
        </div>
        <div className={classes.marginTop}>
          {total - deliveryPrice < minimum_order &&
            <Typography color="error" align="center" variant="subtitle1">El monto mínimo para delivery en tu zona es ${formatMoney(minimum_order)}</Typography>
          }
          {orderError && (
            <Typography color="error" align="center" variant="subtitle1">Ocurrió un error al enviar tu orden</Typography>
          )}
          <Button
            onClick={total - deliveryPrice < minimum_order ? () => setOpenMinimumAmountAlert(true) : handleConfirm}
            color="primary"
            variant="contained"
            fullWidth
            disabled={loading}
            className={classes.bottomButton}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <ShoppingCart style={{ marginRight: '0.5em' }} />
              <Typography variant="body1"><b>Confirmar pedido ({totalItems})</b></Typography>
              <Typography variant="body1"><b>${formatMoney(total)}</b></Typography>
              {loading ? <CircularProgress size={24} /> : null}
            </div>
          </Button>
        </div>
      </Container>
      <Alert
        open={openMinimumAmountAlert}
        accept={() => setOpenMinimumAmountAlert(false)}
        onClose={() => setOpenMinimumAmountAlert(false)}
        title="Debes superar el monto mínimo"
        text={`El monto mínimo para tu zona de despacho es de $${formatMoney(minimum_order)}, por favor agrega más productos.`}
        acceptText="Entendido"
        cancelText=""
      />
      <Alert
        open={openAlert}
        accept={goToPayment}
        onClose={handleConfirmAlertClose}
        important
        title="¿Deseas confirmar el pedido?"
        text={`${deliveryOptions?.type === 'delivery' ? `Enviaremos tu pedido a ${deliveryOptions.address?.short}` : 'Retirarás el pedido en nuestro local'} y vas a pagar con ${translatePaymentMethod(paymentSelected, 'ES')}.`}
        acceptText="Confirmar pedido"
        cancelText="Cancelar"
      />
    </div>
  )
}

export default OrderConfigure

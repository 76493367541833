import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Hidden,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { ConfigureDialog } from '../dialogs';
import { Item } from './';
import { formatMoney } from '../../helpers/money';
import { getTotalItems, getTotalPrice } from '../../helpers/cart';
import { getRestaurantOpen } from '../../helpers/date';
import { MainContext } from '../../context/MainContext';

const useStyles = makeStyles(theme => ({
  container: {
    height: 'calc(100vh - 50px)', // substract header height
    paddingTop: '1rem',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2rem',
      backgroundColor: theme.custom.header.backgroundColor,
      color: 'white',
    }
  },
  infoContainer: {
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      height: '15%',
    }
  },
  lineHeight1: {
    lineHeight: 1,
    marginTop: 3,
  },
  address: {
    color: theme.palette.grey[500],
  },
  upperCart: {
    height: '88%',
    padding: '0 1rem 2rem',
    alignItems: 'flex-start',
  },
  lowerCart: {
    height: '4rem',
    width: '100%',
    bottom: 0,
    position: 'sticky',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
    },
  },
  bottomButton: {
    width: '100%',
    height: '100%',
    borderRadius: 0,
    display: 'flex',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    justifyContent: 'space-between',
  },
  itemsContainer: {
    height: '78%',
    overflowY: 'auto',
    alignItems: 'flex-start',
    '&&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    [theme.breakpoints.down('sm')]: {
      height: '85%',
    }
  },
}))

const Cart = () => {
  const classes = useStyles()
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [openDialog, setOpenDialog] = useState(false)
  const [deliveryPrice, setDeliveryPrice] = useState(0)
  const [itemDialogData, setItemDialogData] = useState({
    open: false,
    item: null,
  })
  const {
    restaurant,
    cart,
    removeItemFromCart,
    deliveryOptions,
    dailyPromo,
    promotionalCodes,
  } = useContext(MainContext)
  const history = useHistory()
  const discount = promotionalCodes.reduce((acc, code) => acc + code.moneyDiscount, 0);
  const dailyDiscount = dailyPromo.reduce((acc, code) => acc + code.moneyDiscount, 0);
  const restaurantOpen = restaurant.isOpen && getRestaurantOpen(restaurant)
  useEffect(() => {
    setTotalPrice(getTotalPrice(cart))
    setTotalItems(getTotalItems(cart))
    // Update the cost each time deliveryOptions changes.
    if (deliveryOptions) {
      if (deliveryOptions.deliveryQuote) setDeliveryPrice(deliveryOptions.deliveryQuote.fee);
      else setDeliveryPrice(0);
    }
  }, [cart, deliveryOptions])

  const handleOpenItemDialog = (item) =>
    setItemDialogData({
      open: true,
      selectedItem: item,
    })

  const handleGotoOrderConfigure = () => {
    if (restaurantOpen) history.push("/order-configure");
    else setOpenDialog(true);
  }

  return (
    <div className={classes.container}>
      <div className={classes.upperCart}>
        <div className={classes.infoContainer}>
          <Typography variant="h4" className={classes.title}>Tu orden</Typography>
          <Hidden mdUp>
            <Typography variant="h6" className={classes.lineHeight1}>{restaurant.name}</Typography>
            <Typography variant="subtitle1" className={classes.lineHeight1}>{restaurant.short_address}</Typography>
          </Hidden>
        </div>
        <div className={classes.itemsContainer}>
          {cart.length <= 0
            ?
            <Typography align="center">Aun no has agregado productos al carro</Typography>
            :
            cart.map((item, i) =>
              <Item
                key={item.id}
                item={item}
                edit={() => handleOpenItemDialog(item)}
                remove={() => removeItemFromCart(item)} />
            )
          }
          {dailyPromo.length > 0 && dailyPromo.map((code) =>
            <Item key={code.code} item={{ name: code.name, totalPrice: code.moneyDiscount }} />
          )}
          {promotionalCodes.length > 0 && promotionalCodes.map((code) =>
            <Item key={code.code} item={{ name: code.name, totalPrice: code.moneyDiscount }} />
          )}
          {deliveryOptions &&
            <Item item={{ name: 'Delivery', totalPrice: deliveryPrice }} />
          }
        </div>
      </div>
      <div className={classes.lowerCart}>
        {cart.length > 0 ?
          <Button
            color="primary"
            variant="contained"
            onClick={handleGotoOrderConfigure}
            className={classes.bottomButton}>
            <div style={{ display: 'flex' }}>
              <ShoppingCart style={{ marginRight: '0.5em' }} />
              <Typography variant="body1"><b>Hacer pedido ({totalItems})</b></Typography>
            </div>
            <Typography variant="body1"><b>${formatMoney(totalPrice - dailyDiscount - discount + deliveryPrice)}</b></Typography>
          </Button>
          :
          <Hidden mdUp>
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push("/")}
              className={classes.bottomButton}>
              <Typography style={{ width: '100%' }} align="center"><b>Volver al menú</b></Typography>
            </Button>
          </Hidden>
        }
      </div>
      {
        itemDialogData.selectedItem !== null && (
          <ConfigureDialog
            open={itemDialogData.open}
            item={itemDialogData.selectedItem}
            onClose={() => setItemDialogData({ open: false, selectedItem: null })} />
        )
      }
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Restaurante cerrado</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            En estos momentos no estamos recibiendo pedidos por la página
            {restaurant.contactInformation && restaurant.contactInformation.phoneNumber && (
              <span>, puedes comunicarte a nuestro <b>Whatsapp al {restaurant.contactInformation.phoneNumber}.</b> o directamente
                <a
                  style={{ color: '#D6062A', margin: '0 5px' }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://wa.me/${restaurant.contactInformation.phoneNumber.replaceAll(' ', '').replace('+', '')}`}
                >
                  aquí.
                </a>
              </span>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div >
  )
}

export default Cart

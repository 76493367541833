import React, { useState, useContext } from "react"
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import TagManager from 'react-gtm-module';
import {
  Typography,
  Button,
  CircularProgress,
  Grid
} from '@material-ui/core'
import { FormField, GoogleLoginButton, FacebookLoginButton } from "./";
import { MainContext } from '../../context/MainContext'
import { register } from '../../apiCalls/strapi'
import { ScrollToTopOnMount, SomaLogo } from '../utils'

const fields = ["firstName", "lastName", "telephone", "email", "password"]

const useStyles = makeStyles(theme => ({
  container: {
    padding: '3em 2em',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.custom.header.backgroundColor,
      color: 'white',
    },
  },
  title: {
    marginBottom: '1rem',
  },
  fieldContainer: {
    marginBottom: '0.5rem',
  },
  buttonContainer: {
    marginTop: '2rem',
    width: '100%',
  },
  button: {
    height: 45,
    fontSize: '1.1rem',
  },
  loginLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '0.5rem',
    marginBottom: '2rem',
  },
  loginLink: {
    marginLeft: '1rem',
    fontSize: 14,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'inherit',
    }
  },
}))

const Register = ({ onDialog }) => {
  const history = useHistory()
  const classes = useStyles();
  const { setUser, setDialogOpen, restaurant } = useContext(MainContext)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState({
    firstName: {
      value: '',
      error: '',
    },
    lastName: {
      value: '',
      error: '',
    },
    telephone: {
      value: '',
      error: '',
    },
    email: {
      value: '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
  })

  const handleChange = (name, value, error) => {
    setData(data => ({
      ...data,
      [name]: {
        ...data[name], value, error
      },
    }))
  }

  const handleRegister = () => {
    let hasError;
    const { telephone, email, password, firstName, lastName } = data
    const error = 'No puede estar vacío'
    if (telephone.error !== '' || email.error !== '' || password.error !== '')
      return
    let newData = { ...data };
    if (firstName.value === '') {
      newData.firstName.error = error;
      hasError = true;
    }
    if (lastName.value === '') {
      newData.lastName.error = error;
      hasError = true;
    }
    if (telephone.value === '') {
      newData.telephone.error = error
      hasError = true
    }
    if (email.value === '') {
      newData.email.error = error
      hasError = true
    }
    if (password.value === '') {
      newData.password.error = error
      hasError = true
    }
    if (hasError) {
      setData(newData)
      return
    }
    setLoading(true);
    setError('');
    register(email.value, password.value, telephone.value, firstName.value, lastName.value, restaurant.id)
      .then((response) => {
        const { user, jwt } = response.data
        setUser({ ...user, jwt })
        setLoading(false)
        TagManager.dataLayer({
          dataLayer: {
            event: 'Create Account',
            email: email.value,
            telephone: `+569${telephone.value}`,
            firstName: firstName.value,
            lastName: lastName.value,
          }
        })
        if (onDialog) setDialogOpen(false, 'register')
        else history.goBack()
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.data.code === 'ENOTFOUND') {
          setError(`El dominio ${email.value.split("@")[1]} no existe`);
        } else if (error.response.data.message.length > 0 && error.response.data.message[0].messages[0].id === 'Auth.form.error.email.taken') {
          setError('Este mail ya está registrado. Recuerda que tu usuario Soma35 sirve en varios comercios')
        } else {
          setError('Ocurrió un error en el registro');
        }
      });
  }

  const navigateToLogin = () => {
    if (onDialog) {
      setDialogOpen(false, 'register')
      setDialogOpen(true, 'login')
    }
    else history.replace('login')
  }

  return (
    <div className={classes.container}>
      <ScrollToTopOnMount />
      <Typography variant="h4" align="center" className={classes.title}>
        Regístrate
      </Typography>
      {fields.map((field) =>
        <div key={field} className={classes.fieldContainer}>
          <FormField
            type={field}
            name={field}
            error={data[field].error}
            value={data[field].value}
            onChange={handleChange} />
        </div>
      )}
      <div className={classes.buttonContainer}>
        <Typography align="center" color="error" variant="caption">{error}</Typography>
        <Button
          onClick={handleRegister}
          className={classes.button}
          fullWidth
          disabled={loading}
          endIcon={loading ? <CircularProgress size={20} /> : null}
          variant="contained"
          color="primary"
        >
          Registrarme
        </Button>
        <div className={classes.loginLinkContainer}>
          <Typography style={{ fontSize: 14 }}>¿Ya tienes cuenta?</Typography>
          <Button onClick={navigateToLogin} className={classes.loginLink}>
            Inicia sesión
          </Button>
        </div>
        <Grid container spacing={1} style={{ marginBottom: "1rem" }}>
          <Grid item xs={12}>
            <GoogleLoginButton />
          </Grid>
          <Grid item xs={12}>
            <FacebookLoginButton />
          </Grid>
        </Grid>
        <SomaLogo />
      </div>
    </div>
  )
}

export default Register

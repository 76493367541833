import React, { useContext } from "react";
import host from '../../config/host';
import { Button } from "@material-ui/core";
import GoogleIcon from "../../assets/icons/google_logo.svg";
import { MainContext } from "../../context/MainContext";

const GoogleLoginButton = () => {
  const { restaurant } = useContext(MainContext);
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Button
        href={`${host}/connect/google?callback=${restaurant.host}/login_providers/google`}
        variant="contained"
        fullWidth
      >
        <img
          style={{
            marginBottom: 0,
            height: "2rem",
            position: "absolute",
            left: 5,
          }}
          src={GoogleIcon}
          alt="google login button"
        ></img>
        Iniciar sesión con Google
      </Button>
    </div>
  );
};

export default GoogleLoginButton;

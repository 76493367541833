import React, { forwardRef, useContext } from 'react'
import {
  Grid,
  Tabs,
  Tab,
} from '@material-ui/core'
import { MainContext } from '../../context/MainContext'

const CategoriesHeader = forwardRef((props, ref) => {
  const { categories, category } = props
  // const [selectedCategory, setCategory] = useState(0)
  // const [selectedSubcategory, setSubcategory] = useState(0)
  const mainContext = useContext(MainContext)

  const handleTabChange = (event, newTab) => {
    props.setCategory(newTab)
    mainContext.setCategory(newTab)
  };

  return(
    <Grid container
      ref={ref}>
      <Grid item xs={12}>
        <Tabs
          value={category}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons='desktop'>
          {categories.map((category) =>
            <Tab key={category.name} label={category.name} />
          )}
        </Tabs>
      </Grid>
    </Grid>
  )
})

export default CategoriesHeader

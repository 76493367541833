import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  scroller: {
    '&&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
  container: {
    padding: 0, 
    width: '350px',
  }
})

import React from 'react'
import {
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import {
  Mail,
  VpnKey,
  Phone,
  Apartment,
  Note,
  Person,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { validEmail } from '../../helpers/validators'

const useStyles = makeStyles(theme => ({
  fieldLabelContainer: {
    display: 'flex',
    marginBottom: '0.5rem',
  },
  fieldLabel: {
    marginLeft: '1em',
  },
  field: {
    [theme.breakpoints.down("sm")]: {
      backgroundColor: 'white',
    }
  },
  input: {
    padding: '10px 14px',
  },
}))

const getAssets = {
  firstName: {
    icon: <Person color="primary"/>,
    label: 'Nombre',
    adornment: null,
  },
  lastName: {
    icon: <Person color="primary"/>,
    label: 'Apellidos',
    adornment: null,
  },
  telephone: {
    icon: <Phone color="primary"/>,
    label: 'Teléfono',
    adornment: <InputAdornment position="start">+56 9</InputAdornment>,
  },
  phoneNumber: {
    icon: <Phone color="primary"/>,
    label: 'Teléfono',
    adornment: <InputAdornment position="start">+56 9</InputAdornment>,
  },
  email: {
    icon: <Mail color="primary"/>,
    label: 'Email',
    adornment: null,
  },
  password: {
    icon: <VpnKey color="primary"/>,
    label: 'Contraseña',
    adornment: null,
  },
  addressDetails: {
    icon: <Apartment color="primary"/>,
    label: 'Piso / Oficina / Departamento',
    adornment: null,
  },
  note: {
    icon: <Note color="primary"/>,
    label: 'Agregar nota',
    adornment: null,
  }
}

const FormField = (props) => {
  const classes = useStyles()
  const { name, value, error, onChange, type } = props
  const handleChange = (event) => {
    let error = ''
    const { name, value } = event.target
    if((type === 'telephone' || type === 'phoneNumber') && value.length !== 8)
      error = 'Debe tener 8 dígitos'
    if(type === 'email' && !validEmail(value))
      error = 'Email inválido'
    if(type === 'password' && value.length < 8)
      error = 'Debe tener al menos 8 caracteres'
    onChange(name, value, error)
  }

  return(
    <>
      <div className={classes.fieldLabelContainer}>
        {getAssets[name].icon}
        <Typography className={classes.fieldLabel}>{getAssets[name].label}</Typography>
      </div>
      <TextField
        name={name}
        value={value}
        onChange={handleChange}
        type={type}
        error={error !== ''}
        helperText={error}
        InputProps={{
          className: classes.field,
          startAdornment: getAssets[name].adornment,
        }}
        inputProps={{
          className: classes.input
        }}
        variant="outlined"
        fullWidth/>
    </>
  )
}

export default FormField

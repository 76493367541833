import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const EmailConfirmed = ({ open, onClose }) => {
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Email confirmado con éxito</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Puedes cerrar este mensaje y continuar navegando por la página
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            Entendido
          </Button>
        </DialogActions>

    </Dialog>
  );
}
 export default EmailConfirmed;

import React, { useContext, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom";
import TagManager from 'react-gtm-module';
import PropTypes from "prop-types"
import {
  Grid,
  Hidden,
  Badge,
  Button,
  IconButton,
  Typography,
  Avatar,
  MenuItem,
  Menu as MenuComponent
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { UserDrawer } from './'
import { AddressHandler } from '../delivery'
import { ShoppingCart, ArrowBackIos, WhatsApp, Menu, AccountCircle } from '@material-ui/icons'
import { MainContext } from '../../context/MainContext'
import { getTotalItems } from '../../helpers/cart'

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.custom.header.backgroundColor,
    color: theme.custom.header.color,
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  infoContainer: {
    margin: `0 auto`,
    maxWidth: 1280,
    height: 55,
    padding: '0 2rem 0 3rem',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      paddingLeft: '1rem',
    }
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoLink: {
    color: 'white',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  addressContainer: {
    padding: '0 0.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 5,
  },
  logo: {
    zIndex: 20,
    marginBottom: 0,
    marginTop: 3,
    maxHeight: 40,
  },
  cartContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  cartLink: {
    color: 'white',
    textDecoration: 'none',
  },
  elementContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  whatsappLink: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none',
    marginRight: '2rem'
  },
  userAvatar: {
    height: '1.5rem',
    width: '1.5rem',
    marginRight: '0.5rem',
  },
  actionButton: {
    color: 'inherit',
  },
}))

const Header = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { cart, user, logout, setDialogOpen, restaurant } = useContext(MainContext)
  const totalItems = getTotalItems(cart)
  const [logoWidth] = useState(40)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [logoUrl] = useState(restaurant.logo ? restaurant.logo.url : null)

  // useScrollPosition(({ prevPos, currPos }) => {
  //   if (logoWidth <= 40 && currPos.y < -100) return
  //   const { y } = currPos
  //   setLogoWidth(Math.min(75, Math.max(y + 115, 40)))
  // })

  const handleCartClick = () => {
    if (location.pathname === '/')
      history.push('/cart')
    else
      history.replace('/cart')
  }

  const handleLogout = () => {
    logout()
    history.replace('/')
  }

  const handleContactUs = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Contact Us Click',
      }
    })
  }
  return (
    <header className={classes.header}>
      <Hidden mdUp>
        <Grid container className={classes.infoContainer}>
          <Grid item xs={2}>
            <Grid container style={{ height: '100%' }} alignItems="center">
              {location.pathname !== '/'
                ? (
                  <Grid item xs={12}>
                    <IconButton onClick={() => history.goBack()}>
                      <ArrowBackIos style={{ color: 'white' }} />
                    </IconButton>
                  </Grid>
                )
                : (
                  <Grid item xs={12} className={classes.logoContainer}>
                    <Link to="/" className={classes.logoLink}>
                      <img
                        className={classes.logo}
                        style={location.pathname === '/' ? { width: logoWidth, left: Math.max(60 - logoWidth, 5) } : { width: 40, left: 50 }}
                        src={logoUrl}
                        alt="Logo" />
                    </Link>
                  </Grid>

                )}
            </Grid>
          </Grid>
          <Grid item xs={7} className={classes.addressContainer}>
            <AddressHandler />
          </Grid>
          <Grid item xs={3}>
            <Grid container justify="flex-start" style={{ height: '100%' }}>
              <Grid item xs={5} className={classes.cartContainer}>
                <div onClick={handleCartClick} className={classes.cartLink}>
                  <Badge badgeContent={totalItems} color="primary">
                    <ShoppingCart />
                  </Badge>
                </div>
              </Grid>
              <Grid item xs={5} className={classes.cartContainer}>
                <div onClick={() => setDrawerOpen(true)} className={classes.cartLink}>
                  <Menu style={{ fontSize: '2rem', marginTop: '5px' }} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid container className={classes.infoContainer} justify="space-between">
          <Grid item xs={4} className={classes.elementContainer}>
            {restaurant.contactInformation && restaurant.contactInformation.phoneNumber && (
              <>
                <a
                  onClick={handleContactUs}
                  className={classes.whatsappLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://wa.me/${restaurant.contactInformation.phoneNumber.replace(/ /g, '').replace('+', '')}`}
                >
                  <WhatsApp style={{ color: '#128C7E', marginRight: '5px' }} />
                  <Typography variant="body2">Contáctanos</Typography>
                </a>
                <a
                  className={classes.whatsappLink}
                  href="https://eatifier-main.s3.us-east-2.amazonaws.com/FAQ_pagina_de_pedidos_4a6a6e97f6.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography variant="body2">Preguntas frecuentes</Typography>
                </a>
              </>
            )}
          </Grid>
          <Grid item xs={4} className={classes.elementContainer}>
            <Link to="/" className={classes.logoLink}>
              <img
                className={classes.logo}
                src={logoUrl}
                alt="Logo" />
            </Link>
          </Grid>
          {user ?
            <Grid item xs={4} className={classes.elementContainer}>
              <Avatar className={classes.userAvatar}>
                <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                  <AccountCircle />
                </IconButton>
              </Avatar>
              <Typography variant="body2" style={{ color: 'white' }}>
                {user.first_name ? `${user.first_name} ${user.last_name}` : user.email}
              </Typography>
              <MenuComponent
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => history.push('/profile')}>Mi perfil</MenuItem>
                <MenuItem onClick={() => history.push('/orders')}>Mis pedidos</MenuItem>
                <MenuItem onClick={() => history.push('/addresses')}>Mis direcciones</MenuItem>
                <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
              </MenuComponent>
            </Grid>
            :
            <Grid item xs={3} className={classes.elementContainer}>
              <Button
                onClick={() => setDialogOpen(true, 'register')}
                className={classes.actionButton}>
                Registrarme
              </Button>
              <Button
                onClick={() => setDialogOpen(true, 'login')}
                className={classes.actionButton}>
                Iniciar sesión
              </Button>
            </Grid>
          }
        </Grid>
      </Hidden>
      <UserDrawer
        open={drawerOpen}
        anchor="right"
        onClose={() => setDrawerOpen(false)} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

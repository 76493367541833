import React, { useState } from 'react'
import {
  Typography,
  Grid,
  Switch,
  IconButton,
} from '@material-ui/core'
import { Remove, Add } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { formatMoney } from '../../helpers/money'

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0 1rem',
  },
  label: {
    width: '100%',
    padding: '0.5em 1em',
    backgroundColor: '#EEE',
    color: 'black',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]:{
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
    }
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    [theme.breakpoints.down('sm')]:{
      color: 'white',
    }
  }
}))

const SelectAdditionals = ({ additionals, setAdditionals }) => {
  const classes = useStyles()

  const handleQuantity = (newQuantity, id) => {
    if(newQuantity < 0) return
    let newAdditionals = [...additionals]
    let selectedAdditional = newAdditionals.find(additional => additional.id === id)
    const { freeAmount, pricePerExtra } = selectedAdditional
    selectedAdditional.quantity = newQuantity
    selectedAdditional.totalPrice = Math.max((newQuantity - freeAmount)*pricePerExtra, 0)
    setAdditionals(newAdditionals)
  }

  const getContent = (additional) => {
    const { type, id, quantity, totalPrice } = additional
    if(type === 'quantity'){
      return(
        <Grid key={additional.id} container className={classes.container}>
          <Grid item xs={3} className={classes.alignCenter}>
            <Typography>{additional.name}</Typography>
          </Grid>
          <Grid item xs={7} className={classes.alignCenter}>
            <IconButton
              className={classes.button}
              onClick={() => handleQuantity(quantity - 1, id)}>
              <Remove/>
            </IconButton>
            <Typography>{quantity}</Typography>
            <IconButton
              className={classes.button}
              onClick={() => handleQuantity(quantity + 1, id)}>
              <Add/>
            </IconButton>
          </Grid>
          <Grid item xs={2} className={classes.alignCenter}>
            <Typography>
              ${formatMoney(totalPrice)}
            </Typography>
          </Grid>
        </Grid>
      )
    }
  }

  return(
    <div>
      <div className={classes.label}>
        <Typography><b>¿Algo más?</b></Typography>
      </div>
      {additionals.map(additional => getContent(additional))}
    </div>
  )
}

export default SelectAdditionals

import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Paper,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { List, ListItem, ListItemSecondaryAction, ListItemText, ListItemAvatar, Checkbox, Avatar } from '@material-ui/core';

import { getFullWrittenDate } from '../../helpers/date';
import { MainContext } from '../../context/MainContext'
import { useQuery } from '@apollo/client';
import { GET_ITEMS } from '../../gql/orders';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  img: {
    height: "100px",
  }
}));

export function Reorder({ orderData }) {
  const history = useHistory();
  const { addMutlipleItemToCart } = useContext(MainContext);
  const [items, setItems] = useState([]);

  const { data, error, loading } = useQuery(GET_ITEMS, {
    variables: {
      id: orderData.id,
      restaurantId: process.env.REACT_APP_RESTAURANT_ID,
    }
  })

  useEffect(() => {
    if (!loading && !error && orderData) {
      let updatedItems = [];
      updatedItems = orderData.items.map((item, i) => {
        const prodList = data.getProducts;

        const categoryIndex = prodList.findIndex(x => x.id === item.category.id);

        if (categoryIndex < 0 || prodList[categoryIndex] === null || prodList[categoryIndex].items === null) {
          setUnchecked([...unchecked, `error_product_${i}`]);
          return { name: item.name, notAvailable: true }; // Compatibility for old orders without category
        }
        const newProd = prodList[categoryIndex].items.find(searchedItem => searchedItem.id === item.id);
        if (!newProd) {
          setUnchecked([...unchecked, `error_product_${i}`]);
          return { name: item.name, notAvailable: true }; // Compatibility for old orders without category
        }
        return {
          comment: item.comment,
          quantity: item.quantity,
          userSelections: item.userSelections,
          totalPrice: newProd.price * item.quantity,
          unitPrice: newProd.price,
          ...newProd
        };
      });

      setItems(updatedItems);
    }
  }, [loading, error, data, orderData])

  const manageItemsToAdd = () => {
    let itemsToAdd = [];
    items.forEach((item, i) => {
      const id = `${item.id}-${i}`;
      if (!unchecked[id]) {
        itemsToAdd.push(item);
      }
    })

    addMutlipleItemToCart(itemsToAdd);
    history.push(`/`);
  }

  const classes = useStyles();
  const [unchecked, setUnchecked] = React.useState(items);

  const handleToggle = (value) => () => {
    const currentIndex = unchecked.indexOf(value);
    const newUnchecked = [...unchecked];

    if (currentIndex === -1) {
      newUnchecked.push(value);
    } else {
      newUnchecked.splice(currentIndex, 1);
    }

    setUnchecked(newUnchecked);
  };

  if (error) {
    console.log(error);
    return <p>Lo sentimos, no pudimos traer los productos de tu orden</p>
  }

  if (Array.isArray(items) && !loading) {
    if (!items || items.length == 0) {
      return <Typography variant='h6'>Lo sentimos, esta orden no se encuentra disponible actualmente.</Typography>;
    }

    return (
      <div style={{ paddingTop: "10px" }}>
        <hr />
        <Typography variant="h6">Seleccionar productos para agregar al carrito</Typography>
        <Typography>Puedes editar las opciones de cada producto en el carrito de compras.</Typography>
        <List dense className={classes.root}>{
            items.map((item, i) => {
              if (item.notAvailable || !item) return (
                <ListItem button>
                  <ListItemText primary={`${item.name} ya no se encuentra disponible`} />
                </ListItem>
              );

              let selections = "";

              const values = Object.values(item.userSelections);
              values.forEach(e => {
                selections += (selections !== "" ? ", " : "") + e.name;
              });

              const id = `${item.id}-${i}`;
              const labelId = `checkbox-list-secondary-label-${id}`;
              return (
                <ListItem key={id} button onClick={handleToggle(id)}>
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      alt={item.name}
                      src={item.images_min[0].url}
                      style={{
                        alignItems: "normal",
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText id={labelId} primary={item.name} />
                  <ListItemText primary={selections} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={handleToggle(id)}
                      checked={unchecked.indexOf(id) === -1}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
          }
        </List>
        <div style={{
          marginLeft: "10px",
        }}>
          <Button
            variant="contained"
            color="primary"
            disabled={(unchecked.length === items.length)}
            onClick={manageItemsToAdd}
          >
            Agregar productos al carro
          </Button>
        </div>
      </div>
    )
  } else {
    return (
      <div style={{ paddingTop: "10px", width: '100%' }}>
        <LinearProgress />
      </div>
    )
  }
}

Reorder.propTypes = {
  orderData: PropTypes.any,
};
import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Typography,
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { MainContext } from '../../context/MainContext'
import { sendEmailConfirmation } from '../../apiCalls/strapi';

const useStyles = makeStyles(theme => ({
  label: {
    width: '100%',
    padding: '0.5em 1em',
    backgroundColor: '#EEE',
    color: 'black',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
    }
  },
  fieldContainer: {
    display: 'flex',
    padding: '0 1rem',
  },
  promoInput: {
    backgroundColor: 'white',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0.5rem 1rem',
  },
  notLoggedContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 1rem',
  },
  success: {
    color: theme.palette.success.main,
  }
}))

const REDEEM_CODE = gql`
  mutation RedeemCode($code: String!, $restaurant: ID!){
    redeemCode(code: $code, restaurant: $restaurant){
      id
      rule
      type
      day
      name
      isStackable
      categories {
        id
      }
      items {
        id
      }
      itemsJSON
      categoriesJSON
    }
  }
`

const PromoCode = ({ matches }) => {
  const classes = useStyles();
  const history = useHistory();
  const [promoCode, setPromoCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [success, setSuccess] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [emailSentSuccess, setEmailSentSuccess] = useState(false);
  const [emailSentError, setEmailSentError] = useState(false);
  const [emailSentLoading, setEmailSentLoading] = useState(false);
  const [redeemCode, { loading }] = useMutation(REDEEM_CODE);
  const { promotionalCodes, addPromotionalCode, user, setDialogOpen } = useContext(MainContext);

  const handlePromoCode = (e) => {
    setPromoCode(e.target.value.toUpperCase())
  }

  const validatePromoCode = () => {
    setConfirmDialog(false);
    setSuccess(false);
    setCodeError('');
    if (promoCode === '') {
      setCodeError('Debes ingresar un código');
      return
    }
    redeemCode({
      variables: {
        code: promoCode,
        restaurant: process.env.REACT_APP_RESTAURANT_ID,
      }
    })
      .then((res) => {
        const { redeemCode } = res.data
        // If items length is 0 and itemsJSON length is more than 0, 
        // the restaurant has an erp integration
        if (redeemCode.items && redeemCode.items.length === 0 && redeemCode.itemsJSON && redeemCode.itemsJSON.length > 0) {
          redeemCode.items = redeemCode.itemsJSON;
        }
        // Same as above with categories
        if (redeemCode.categories && redeemCode.categories.length === 0 && redeemCode.categoriesJSON && redeemCode.categoriesJSON.length > 0) {
          redeemCode.categories = redeemCode.categoriesJSON;
        }
        if (!redeemCode) {
          setCodeError('Código inválido')
          return
        }
        const newCode = redeemCode
        const checkCode = promotionalCodes.find((code) => code.id === newCode.id)
        if (checkCode) {
          setCodeError('Ya canjeaste ese código')
          return
        }
        setCodeError('')
        setSuccess(true)
        addPromotionalCode(redeemCode)
      })
      .catch((err) => {
        if (err && err.graphQLErrors) {
          if (err.graphQLErrors[0].message === 'Forbidden') setCodeError('Debes confirmar tu cuenta. Revisa tu email.')
          else setCodeError(JSON.parse(err.graphQLErrors[0].message).message)
        }
      })
  }

  const handleDialogOpen = (type) => {
    if (matches) history.push(type)
    else setDialogOpen(true, type)
  }

  const handleClose = () => setConfirmDialog(false)

  const handleSendConfirmationEmail = () => {
    setEmailSentLoading(true);
    setEmailSentSuccess(false);
    setEmailSentError(false);
    sendEmailConfirmation(user.email)
    .then(() => {
      setEmailSentSuccess(true);
      setEmailSentLoading(false);
    })
    .catch(() => {
      setEmailSentError(true);
      setEmailSentLoading(false);
      })
  }

  return (
    <div>
      <div className={classes.label}>
        <Typography><b>¿Tienes algún código promocional?</b></Typography>
      </div>
      {user ?
        <>
          <div className={classes.fieldContainer}>
            <TextField
              fullWidth
              variant="outlined"
              value={promoCode}
              InputProps={{
                className: classes.promoInput,
              }}
              onChange={handlePromoCode} />
            <Button
              style={{ width: '40%' }}
              color="primary"
              disabled={loading}
              endIcon={loading ? <CircularProgress size={20} /> : null}
              onClick={() => setConfirmDialog(true)}>
              Validar
            </Button>
          </div>
          {success && (
            <Typography align="center" style={{ color: '#22bb33' }}>
              Código añadido con éxito
            </Typography>
          )}
          {Boolean(codeError) && (
            <div className={classes.errorContainer}>
              <Typography color="error" variant="caption">{codeError}</Typography>
              {codeError === 'Debes confirmar tu cuenta para realizar esta acción' && (
                <>
                  <Typography color="success" variant="body2" className={classes.success}>
                    {emailSentSuccess ? 'Email enviado' : ''}
                  </Typography>
                  <Button
                    disabled={emailSentLoading}
                    variant="contained"
                    color="primary"
                    onClick={handleSendConfirmationEmail}
                    endIcon={emailSentLoading ? <CircularProgress size={16} /> : null}
                  >
                    Reenviar mail de confirmación
                    </Button>
                  <Typography color="error" variant="body2">
                    {emailSentError ? 'No pudimos enviar el email de confirmación' : ''}
                  </Typography>
                </>
              )}
            </div>
          )}
        </>
        :
        <div className={classes.notLoggedContainer}>
          <Typography>Debes iniciar sesión para usar códigos promocionales</Typography>
          <div style={{ display: 'flex', marginTop: '1rem', justifyContent: 'space-evenly' }}>
            <Button
              color="primary"
              onClick={() => handleDialogOpen('login')}>
              Iniciar sesión
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDialogOpen('register')}>
              Registrarme
            </Button>
          </div>
        </div>
      }
      <Dialog
        open={confirmDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Quieres usar este código?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Si ya tienes un código activo que no es acumulable, este código lo reemplazará
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={validatePromoCode} color="primary" autoFocus>
            Usar código
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PromoCode

import React from 'react'
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { formatMoney } from '../../helpers/money'
import Image from './Image';

const useStyles = makeStyles(theme => ({
  container: {
    // height: 485,
    // width: 305,
    borderRadius: '5px',
    borderWidth: '0',
    backgroundColor: theme.palette.background.default,
    paddingBottom: '1rem',
  },
  name: {
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    height: 28,
    fontWeight: 'bold',
    margin: '0.5rem 0',
  },
  description: {
    color: '#A3A3A3',
    lineHeight: 1,
    overflow: 'hidden',
    display: '-webkit-box',
    marginBottom: '1em',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    height: '30px',
    width: '300px',
  },
  descriptionVertical: {
    color: '#A3A3A3',
    lineHeight: 1,
    overflow: "hidden",
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    height: '62px',
    marginBottom: '1rem',
  },
  verticalTopContainer: {
    width: '100%',
    // height: 305,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.logoBackground,
  },
  image: {
    margin: 0,

    backgroundColor: '#0A0A0A',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  verticalMiddleContainer: {
    padding: '0 1em',
    // height: '32%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  verticalBottomContainer: {
    padding: '0 1em',
    width: '100%',
    display: 'flex',
  },
}))

const HorizontalCard = ({ item, classes }) =>
  <Grid container alignItems="center" className={classes.container}>
    <Grid item xs={9} style={{padding: '0.5em 1em 0.5em 0.75em', height: '100%'}}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.name}>{item.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.description}>
            {item.description_long ?
            item.description_long : item.description_short}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" className={classes.price}> ${formatMoney(item.price)} </Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={3} style={{height: '100%', display: 'flex', alignItems: 'center'}}>
      <Image item={item} className={classes.image}/>
    </Grid>
  </Grid>

const VerticalCard = ({ item, classes, onClick }) =>
  <Grid container alignItems="center" direction="column" className={classes.container}>
    <Grid item className={classes.verticalTopContainer}>
    <Image item={item} className={classes.image}/>
    </Grid>
    <Grid item className={classes.verticalMiddleContainer}>
      <Typography variant="subtitle1" className={classes.name}>{item.name}</Typography>
      <Typography variant="body2" className={classes.descriptionVertical}>
      {item.description_long ?
        <span>{item.description_long }</span>
        :
        <span dangerouslySetInnerHTML={{__html: item.description_special}}/>
      }
      </Typography>
    </Grid>
    <Grid item className={classes.verticalBottomContainer}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={7}>
          <Typography variant="h6" className={classes.price}> ${formatMoney(item.price)} </Typography>
        </Grid>
        <Grid item xs={5}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={onClick}>
              Agregar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </Grid>

const Card = ({ item, type, onClick }) => {
  const classes = useStyles();
  if(type === 'vertical')
    return(
      <VerticalCard onClick={onClick} item={item} classes={classes}/>
    )
  if(type === 'horizontal')
    return(
      <HorizontalCard item={item} classes={classes}/>
    )
}

export default Card

import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import cyan from '@material-ui/core/colors/cyan'
import {
  Grid,
  Typography,
  Hidden,
} from '@material-ui/core'
import { formatMoney } from '../../helpers/money'

const useStyles = makeStyles(theme => ({
  itemContainer: {
    marginBottom: '1em',
  },
  itemInnerContainer: {
    marginTop: '4px',
  },
  option: {
    color: grey[500],
  },
  itemButton: {
    color: cyan[700],
    cursor: 'pointer',
    textDecoration: 'none',
  }
}))

const Item = ({ item, edit, remove}) => {
  const classes = useStyles()

  const handleEdit = () => {
    if(edit) edit()
  }
  const handleRemove = () => {
    if(remove) remove()
  }

  return(
    <div key={item.id} className={classes.itemContainer}>
      <Grid container justify="space-between">
        {item.quantity !== undefined &&
          <Grid item xs={1}>
            <Typography><b> {item.quantity} x </b></Typography>
          </Grid>
        }
        <Grid item xs={7}>
          <Typography><b> {item.name} </b></Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography align="right"> ${formatMoney(item.totalPrice)} </Typography>
        </Grid>
      </Grid>
      {item.userSelections &&
        <div className={classes.itemInnerContainer}>
          {Object.keys(item.userSelections).map(key => {
            if(Array.isArray(item.userSelections[key]))
              return item.userSelections[key].filter(option => option).map(option =>
                <Grid container key={option.name}>
                  <Grid item xs={1}/>
                  <Grid item xs={7}>
                    <Typography variant="body2" className={classes.option}>
                      {option.name}
                    </Typography>
                  </Grid>
                </Grid>
              )
            else
              return(
                <Grid container key={key}>
                  <Grid item xs={1}/>
                  <Grid item xs={11}>
                    <Typography variant="body2" className={classes.option}>
                      {item.userSelections[key].name}
                    </Typography>
                  </Grid>
                </Grid>
              )
          })}
        </div>
      }
      {edit && remove &&
        <Grid container className={classes.itemInnerContainer}>
          <Grid item xs={1}/>
          <Grid item xs={3}>
            <Hidden mdUp>
              <Link
                to={{
                  pathname:'/item-configure',
                  state: {item}
                }}
                className={classes.itemButton}>
                <Typography
                  variant="body2"
                  className={classes.itemButton}>
                  <b>Editar</b>
                </Typography>
              </Link>
            </Hidden>
            <Hidden smDown>
              <Typography
                variant="body2"
                className={classes.itemButton}
                onClick={handleEdit}>
                <b>Editar</b>
              </Typography>
            </Hidden>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              className={classes.itemButton}
              onClick={handleRemove}>
              <b>Eliminar</b>
            </Typography>
          </Grid>
        </Grid>
      }
    </div>
  )
}

export default Item

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { sendEmailConfirmation } from '../../apiCalls/strapi';

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
}))

const ConfirmEmail = ({ open, onClose, userEmail }) => {
  const classes = useStyles();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(false);
  const handleSendEmail = () => {
    setLoading(true);
    sendEmailConfirmation(userEmail)
    .then(() => {
      setLoading(false);
      setEmailSent(true);
    })
    .catch(() => {
      setLoading(false);
      setError(true);
    })
  };

  return (
    <Dialog
      open={open && location.pathname !== '/email-confirmation'}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Recuerda confirmar tu email</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Cuando te registraste te enviamos un correo, recuerda que puede estar en spam. No es necesario para realizar pedidos, pero sí para utilizar códigos promocionales
        </DialogContentText>
        <div>
          <Typography variant="h6" gutterBottom>¿Definitivamente no te llegó el correo?</Typography>
          <Typography className={classes.success}>{emailSent ? 'Correo enviado con éxito, recuerda revisar la carpeta spam' : ''}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendEmail}
            disabled={loading}
            endIcon={loading ? <CircularProgress size={16} /> : null}
            >
            Enviar nuevamente
          </Button>
          <Typography color="error">{error ? 'No pudimos enviar el correo de confirmación' : ''}</Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Entendido
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmEmail;


import React from 'react'
import {
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { formatMoney } from '../../helpers/money'

const CustomCheckbox = withStyles({
  root: {
    color: `rgb(67, 89, 110)`,
    '&$checked': {
      color: `rgb(67, 89, 110)`,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  label: {
    width: '100%',
    padding: '0.5em 1em',
    color: theme.palette.common.white,
    marginBottom: '1em',
    backgroundColor: theme.palette.common.black,
  },
  checkboxGroup: {
    padding: '0 1em',
  },
  checkbox: {
    borderBottomWidth: '1px',
    borderBottomColor: theme.palette.grey[500],
    borderBottomStyle: 'solid',
    margin: 0,
    [theme.breakpoints.down('sm')]:{
      borderBottomColor: theme.palette.grey[700],
    },
    width: '100%',
  },
  checkboxLabelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
  }
}))


const ChooseMany = ({config, onChange, userSelections}) => {
  const classes = useStyles()
  return(
    <div className={classes.container}>
      <div className={classes.label}>
        <Typography><b>{config.title}</b></Typography>
        {config.limit > 0 && !config.mandatory &&
          <Typography variant="body2">Elige hasta {config.limit}</Typography>
        }
        {config.limit > 0 && config.mandatory &&
          <Typography variant="body2">Elige mínimo 1 y máximo {config.limit}</Typography>
        }
        {config.limit === 0 && config.mandatory && // limit === 0 means no limit
          <Typography variant="body2">Elige mínimo 1</Typography>
        }
        {config.limit === 0 && !config.mandatory &&
          <Typography variant="body2">Elige los que quieras</Typography>
        }
      </div>
      <div className={classes.checkboxGroup}>
        {config.modification_options.map((option, i) =>
          <FormControlLabel
            key={option.name}
            className={classes.checkbox}
            control={
              <CustomCheckbox
                checked={userSelections[i]}
                onChange={(event) => onChange(event, i)}
                name={option.name}
              />
            }
            classes={{
              label: classes.checkboxLabelContainer,
            }}
            label={
              <Grid container>
                <Grid item xs={8}>
                  <Typography>{option.name}</Typography>
                </Grid>
                {option.price > 0 &&
                  <Grid item xs={4}>
                    <Typography align="right">+ ${formatMoney(option.price)}</Typography>
                  </Grid>
                }
              </Grid>}
          />
        )}
      </div>
    </div>
  )
}

export default ChooseMany

import { gql } from '@apollo/client';

// TODO: add scheduled time with dropoff date
export const CREATE_UBER_DELIVERY_QUOTE = gql`
  mutation CreateUberDeliveryQuote(
    $pickupAddress: String!,
    $dropoffAddress: String!,
    $dropoffReadyDt: String,
    $pickupReadyDt: String,
    ) {
  	CreateUberDeliveryQuote(
      pickup_address: $pickupAddress,
      dropoff_address: $dropoffAddress,
      dropoff_ready_dt: $dropoffReadyDt,
      pickup_ready_dt: $pickupReadyDt,
    ) {
      ...on GraphqlError {
        status
        code
        message
      }
      ...on UberDeliveryQuote {
        id
        expires
        fee
        currency
        dropoff_eta
        duration
      }
    }
  }
`;

export const CREATE_UBER_DELIVERY = gql`
  mutation CreateUberDelivery(
    $dropoffAddress: String!,
    $dropoffName: String!,
  	$dropoffPhoneNumber: String!,
    $manifest: String!,
    $manifestItems: [ManifestItemInput]!,
    $pickupAddress: String!,
    $pickupName: String!,
    $pickupPhoneNumber: String!,
    $quoteId: String!,
    $pickupNotes: String,
  ) {
  	CreateUberDelivery(
      dropoff_address: $dropoffAddress,
      dropoff_name: $dropoffName,
      dropoff_phone_number: $dropoffPhoneNumber,
      manifest: $manifest,
      manifest_items: $manifestItems,
      pickup_address: $pickupAddress,
      pickup_name: $pickupName,
      pickup_phone_number: $pickupPhoneNumber,
      quote_id: $quoteId,
      pickup_notes: $pickupNotes
    ) {
      ...on GraphqlError {
        status
        code
        message
      }
      ...on UberDelivery {
        id
        quote_id
        pickup {
          name
          phone_number
          address
          detailed_address {
            street_address_1
            street_address_2
            city
            state
            zip_code
            country
          }
          notes
          location {
            lat
            lng
          }
        }
        dropoff {
          name
          phone_number
          address
          detailed_address {
            street_address_1
            street_address_2
            city
            state
            zip_code
            country
          }
          notes
          location {
            lat
            lng
          }
        }
        manifest_items {
          name
          quantity
          size
          must_be_upright
        }
        created
        dropoff_eta
        fee
        currency
      }
    }
  }
`;
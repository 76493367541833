import React from 'react'
import {
  Dialog,
  Slide,
  IconButton
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Delivery as DeliveryComponent } from '../delivery'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Delivery = ({ open, onClose }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))
  return(
    <Dialog
      fullScreen={matches}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}>
      <IconButton
        style={{
          position: 'absolute',
          top: 5,
          right: 10,
        }}
        onClick={onClose}>
        <Close/>
      </IconButton>
      <DeliveryComponent />
    </Dialog>
  )
}

export default Delivery

import React from 'react';
import { Button, Container, Grid, LinearProgress, Typography } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { GET_IN_STORE_MENUS } from '../gql/restaurant';
import { useQuery } from '@apollo/client';

const InStoreMenus = () => {
  const { data, loading } = useQuery(GET_IN_STORE_MENUS, {
    variables: {
      restaurantId: process.env.REACT_APP_RESTAURANT_ID,
    },
  })

  const handleInStoreMenuDownload = (inStoreMenu) => {
    var link = document.createElement("a");
    link.download = inStoreMenu.name;
    link.href = inStoreMenu.file.url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  if (loading) return <LinearProgress />

  const { restaurant: { in_store_menus } } = data;

  if (in_store_menus.length === 0) return (
    <Typography>
      Este restaurante no tiene ninguna carta configurada para los pedidos de consumo en el local
    </Typography>
  )

  return (
    <Container maxWidth="lg" style={{ padding: '2rem' }}>
      <Grid container spacing={2} justify="center">
        {in_store_menus.map((inStoreMenu) => (
          <Grid item xs={12} sm={6} key={inStoreMenu.id}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => handleInStoreMenuDownload(inStoreMenu)}
              startIcon={<CloudDownload />}
            >
              {inStoreMenu.name}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default InStoreMenus
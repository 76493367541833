import React from 'react';
import {
  Route,
  Redirect,
} from "react-router-dom";

const PrivateRoute = ({ component: Component, ...props }) =>

  <Route 
    {...props}
    render={ (props) =>
      localStorage.getItem('user') ? <Component {...props} /> : <Redirect to='/' />
    }
  />

 export default PrivateRoute;
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, CircularProgress, Grid } from "@material-ui/core";
import { FormField, GoogleLoginButton, FacebookLoginButton } from "./";
import { MainContext } from "../../context/MainContext";
import { login } from "../../apiCalls/strapi";
import { ScrollToTopOnMount, SomaLogo } from "../utils";
import { formErrorToSpanish } from "../../helpers/validators";

const fields = ["email", "password"];

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1em 2em 0.5em 2em",
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.custom.header.backgroundColor,
      color: "white",
    },
  },
  title: {
    marginBottom: "0.5rem",
  },
  fieldContainer: {
    marginBottom: "0.5rem",
  },
  buttonContainer: {
    marginTop: "1rem",
    width: "100%",
    paddingLeft: "inherit",
    paddingRight: "inherit",
  },
  button: {
    height: 45,
    fontSize: "1.1rem",
  },
  registerLinkContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "0.5rem",
  },
  registerLink: {
    marginLeft: "1rem",
    fontSize: 14,
    padding: 0,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "inherit",
    },
  },
  forgetLink: {
    width: "100%",
    paddingBottom: 0,
    fontSize: 14,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "inherit",
    },
  },
}));

const Login = ({ onDialog }) => {
  const history = useHistory();
  const classes = useStyles();
  const { setUser, setDialogOpen } = useContext(MainContext);
  const [data, setData] = useState({
    email: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (name, value, error) => {
    setData((data) => ({
      ...data,
      [name]: {
        ...data[name],
        value,
        error,
      },
    }));
  };

  const handleLogin = () => {
    // Other errors are handled in FormField and written in .error
    if (
      data.email.value === "" ||
      data.email.error !== "" ||
      data.password.value === "" ||
      data.password.error !== ""
    ) {
      return;
    }

    setLoading(true);
    setError("");
    login(data.email.value, data.password.value)
      .then((response) => {
        // Handle success.
        const { user, jwt } = response.data;
        setUser({ ...user, jwt });
        if (onDialog) setDialogOpen(false, "login");
        else history.replace("/");
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        setLoading(false);
        setError(
          formErrorToSpanish[error.response.data.message[0].messages[0].id]
        );
      });
  };

  const navigateToRegister = () => {
    if (onDialog) {
      setDialogOpen(false, "login");
      setDialogOpen(true, "register");
    } else history.replace("register");
  };

  const navigateToForgotPassword = () => {
    if (onDialog) {
      setDialogOpen(false, "login");
      setDialogOpen(true, "forgotPassword");
    } else history.push("forgot-password");
  };

  return (
    <div className={classes.container}>
      <ScrollToTopOnMount />
      <Typography variant="h4" align="center" className={classes.title}>
        Ingresa
      </Typography>
      {fields.map((field) => (
        <div key={field} className={classes.fieldContainer}>
          <FormField
            type={field}
            name={field}
            error={data[field].error}
            value={data[field].value}
            onChange={handleChange}
          />
        </div>
      ))}
      <div className={classes.buttonContainer}>
        <Typography color="error" variant="body2" align="center" gutterBottom>
          {error}
        </Typography>
        <Button
          onClick={handleLogin}
          className={classes.button}
          fullWidth
          disabled={loading}
          endIcon={loading ? <CircularProgress size={20} /> : null}
          variant="contained"
          color="primary"
        >
          Iniciar Sesión
        </Button>
        <Button
          color="primary"
          onClick={navigateToForgotPassword}
          className={classes.forgetLink}
        >
          Olvidé mi contraseña
        </Button>
        <div className={classes.registerLinkContainer}>
          <Typography style={{ fontSize: 14 }}>¿No tienes cuenta?</Typography>
          <Button
            color="primary"
            onClick={navigateToRegister}
            className={classes.registerLink}
          >
            Regístrate
          </Button>
        </div>
      </div>
      <Grid container spacing={1} style={{ marginBottom: "1rem" }}>
        <Grid item xs={12}>
          <GoogleLoginButton />
        </Grid>
        <Grid item xs={12}>
          <FacebookLoginButton />
        </Grid>
      </Grid>
      <SomaLogo />
    </div>
  );
};

export default Login;

import React, { useEffect } from "react"
import { Redirect, useLocation } from 'react-router-dom'
import {
  Configure
} from '../components/configure';

const ItemConfigure = () => {
  let location = useLocation();
  if(!location.state
    || (location. state && !location.state.item)) {
      return <Redirect to="/" />
    }

  return(
    <Configure item={location.state.item}/>
  )
}

export default ItemConfigure

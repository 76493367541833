/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { hoursBy15 } from '../../constants/dateTime';

const useStyles = makeStyles({
  iconOutlined: {
    top: 4,
  },
});

const TimeSelect = ({ currentSelection, onChange, className, style, minHour, maxHour }) => {
  const classes = useStyles();
  return (
    <Select
      onChange={(event) => onChange(event.target.value)}
      value={currentSelection}
      variant="outlined"
      classes={{
        outlined: className,
        iconOutlined: classes.iconOutlined,
      }}
      style={{ minWidth: 90, ...style }}
    >
      {hoursBy15.filter((hour) => hour >= minHour && hour <= maxHour).map((hour) => (
        <MenuItem key={hour} value={hour}>{hour}</MenuItem>
      ))}
    </Select>
  );
}

TimeSelect.propTypes = {
  currentSelection: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.object,
  style: PropTypes.object,
};

TimeSelect.defaultProps = {
  currentSelection: '09:00',
  minHour: '00:00',
  maxHour: '23:59',
};

export default TimeSelect;

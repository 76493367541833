import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Drawer,
  Button,
  Typography,
} from '@material-ui/core'
import { MainContext } from '../../context/MainContext'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Faqs } from '../common';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    padding: '2rem 2rem',
    backgroundColor: theme.palette.background.default,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  logoutButton: {
    color: theme.palette.error.main,
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'absolute',
    bottom: 40,
  },
  bottomButton: {
    textAlign: 'right',
  },
  faqs: {
    color: 'inherit',
    textDecoration: 'none',
  },
}))

const UserDrawer = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const { user, restaurant, logout } = useContext(MainContext)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))

  const navigateToRegister = () => {

    // sm or xs devices navigate to route
    if (matches) history.push("register")
    // bigger than sm devices close the drawer and open login modal
    else return
    props.onClose()
  }

  const navigateToLogin = () => {
    // sm or xs devices navigate to route
    if (matches) history.push("login")
    // bigger than sm devices close the drawer and open login modal
    else return
    props.onClose()
  }

  const handleLogout = () => {
    logout()
    if (matches) history.replace('/')
    else return
    props.onClose()
  }

  return (
    <Drawer
      PaperProps={{
        style: { maxWidth: '85%' },
      }}
      {...props} >
      {user ? (
        <div className={classes.contentContainer}>
          <Typography variant="h4" style={{ lineHeight: 1 }}>¡Hola!</Typography>
          <Typography variant="subtitle1">{user.first_name ? `${user.first_name} ${user.last_name}` : user.email}</Typography>
          <div className={classes.buttonContainer}>
            <Button
              onClick={() => history.push('/profile')}
              fullWidth
              color="primary"
            >
              Mi perfil
            </Button>
            <Button
              onClick={() => history.push('/orders')}
              fullWidth
              color="primary"
            >
              Mis pedidos
            </Button>
            <Button
              onClick={() => history.push('/addresses')}
              fullWidth
              color="primary"
            >
              Mis direcciones
            </Button>
            <Button
              onClick={handleLogout}
              fullWidth
              className={classes.logoutButton}
            >
              Cerrar sesión
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.contentContainer}>
            <Typography variant="h4" style={{ lineHeight: 1 }}>{restaurant && restaurant.name}</Typography>
            <Typography variant="subtitle1">{restaurant && restaurant.short_address}</Typography>
            <Typography variant="h6" style={{ marginTop: '2rem' }}>Crea una cuenta</Typography>
            <Typography style={{ marginBottom: '2rem' }}>
              Al registrarte podrás guardar tu dirección y utilizar cupones de descuento
            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                onClick={navigateToRegister}
                fullWidth
                color="primary"
                variant="contained">
                Registrarme
              </Button>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                onClick={navigateToLogin}
                color="primary">
                Iniciar sesión
              </Button>
            </div>
          </div>
          <div className={classes.bottomContainer}>
            <Faqs />
            {/* <Button classes={{
              label: classes.bottomButton
            }}>
              Términos y condiciones
            </Button>
            <Button classes={{
              label: classes.bottomButton
            }}>
              Política de privacidad
            </Button> */}
          </div>
        </>
      )}
    </Drawer>
  )
}

export default UserDrawer

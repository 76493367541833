import React, { useContext } from 'react'
import {
  Button,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Store } from '@material-ui/icons'
import { MainContext } from '../../context/MainContext'
import Scheduling from './Scheduling';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    marginTop: '2rem',
  },
  pickupContainer: {
    margin: '1rem 0',
  },
  storeContainer: {
    display: 'flex',
    alignItems: 'center',
  }
}))

const TakeAwayDetails = () => {
  const classes = useStyles()

  const {
    restaurant,
    deliveryOptions,
    setDeliveryOptions,
    setDialogOpen,
  } = useContext(MainContext)

  const handleConfirm = () => {
    let newDeliveryOptions;
    if (deliveryOptions) {
      newDeliveryOptions = {
        ...deliveryOptions,
        type: 'takeAway',
      };
    }
    else newDeliveryOptions = { type: 'takeAway' };
    setDeliveryOptions(newDeliveryOptions);
    setDialogOpen(false, 'address')
  }

  return (
    <>
      <div className={classes.pickupContainer}>
        <div className={classes.storeContainer}>
          <Store color="primary" style={{ marginRight: '1.5rem' }} />
          <div>
            <Typography>{restaurant.name}</Typography>
            <Typography variant="body2">{restaurant.short_address}</Typography>
          </div>
        </div>
      </div>
      <Scheduling type="delivery" />
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          className={classes.button}
          onClick={handleConfirm}>
          Confirmar
        </Button>
      </div>
    </>
  )
}

export default TakeAwayDetails

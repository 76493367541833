import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import {
  Container,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from '@material-ui/core';
import { Card as OrderCard } from '../components/order';
import { GET_ORDERS } from '../gql/orders';
import { MainContext } from '../context/MainContext';
import { ErrorPage } from '../components/utils';
import useViewsStyles from './styles';

const Orders = () => {
  const viewsClasses = useViewsStyles();
  const { user: { id: userId } } = useContext(MainContext);
  const { data, error, loading } = useQuery(GET_ORDERS, {
    variables: {
      restaurantId: process.env.REACT_APP_RESTAURANT_ID,
      userId,
    }
  })

  if (loading) return <LinearProgress />
  if (error) return <ErrorPage full />

  return (
    <div className={viewsClasses.background}>
      <Container maxWidth="md" style={{ minHeight: '80vh' }}>
        <Grid container spacing={2} justify="center">
          {data.orders.length > 0 
          ? data.orders.map((order) => (
            <Grid item xs={12} key={order.id}>
              <OrderCard order={order} />
            </Grid>
          ))
        : (
          <Paper style={{ padding: '1rem' }}>
            <Typography variant="h6">Todavía no realizas ningún pedido</Typography>
          </Paper>
        )}
        </Grid>
      </Container>
    </div>
  )
}

export default Orders;
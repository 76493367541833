import React from 'react'
import {
  Typography,
  Grid,
  IconButton,
} from '@material-ui/core'
import { Remove, Add } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  quantityContainer: {
    width: '100%',
    padding: '0.5em 1em',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    marginBottom: '1em',
  },
  button: {
    border: '1px solid',
    padding: 0,
    margin: '0 1em',
  },
  remove: {
    color: theme.palette.error.main,
  },
  add: {
    color: theme.palette.success.main,
  },
}))

const QuantitySelect = ({ quantity, setQuantity }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Grid container alignItems="center" className={classes.quantityContainer}>
        <Grid item xs={4}>
          <Typography><b>Cantidad</b></Typography>
        </Grid>
        <Grid item xs={8} className={classes.quantityButtonsContainer}>
          <Grid container justify="center" alignItems="center" >
            <IconButton
              className={clsx(classes.button, classes.remove)}
              disabled={quantity <= 1}
              onClick={() => setQuantity(quantity => quantity - 1)}
              aria-label="minus quantity">
              <Remove />
            </IconButton>
            <Typography>{quantity}</Typography>
            <IconButton
              className={clsx(classes.button, classes.add)}
              onClick={() => setQuantity(quantity => quantity + 1)}
              aria-label="plus quantity">
              <Add />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}


export default QuantitySelect

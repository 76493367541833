export default async (uri, options, login, logout, user) => {
  const response = await fetch(uri, options);
  const text = await response.text();
  const jsonResponse = await JSON.parse(text);
  if (jsonResponse.errors && jsonResponse.errors[0].message === 'Invalid token.') {
    logout();
    const newOptions = {
      ...options,
      headers: { ...options.headers, authorization: '' },
    };
    return fetch(uri, newOptions);
  }
  const result = {};
  result.ok = true;
  result.text = () => Promise.resolve(text);
  return result;
};
import React, { useContext } from 'react';
import { MainContext } from '../../context/MainContext';

const Image = ({ item, className }) => {
  const { restaurant } = useContext(MainContext);
  let imageSrc = null;
  if (item.images_min.length > 0) imageSrc = item.images_min[0].url;
  else if (restaurant.logo) imageSrc = restaurant.logo.url;
  return (
    <img
      style={item.images_min.length > 0 
        ? {}
        : { padding: '1rem' }}
      src={imageSrc}
      alt={item.name}
      className={className}
    />
  );
}

export default Image;

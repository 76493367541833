import React, { useContext, useState } from 'react';
import useViewsStyles from './styles';
import { MainContext } from '../context/MainContext';
import { Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Snackbar, Typography } from '@material-ui/core';
import { AddressCard } from '../components/address';
import EditOrCreateAddress from '../components/address/EditOrCreate';
import { CREATE_ADDRESS } from '../gql/address';
import { useMutation } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';

export default function Addresses() {
  const viewsClasses = useViewsStyles();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newAddress, setNewAddress] = useState(null);
  const { user, addUserAddress } = useContext(MainContext);
  const [createAddress, { loading, error }] = useMutation(CREATE_ADDRESS);


  const handleAddressChange = (address) => {
    setNewAddress(address)
  }

  const handleCreateAddress = () => {
    createAddress({
      variables: {
        ...newAddress,
        user: user.id,
      },
    })
    .then(({ data }) => {
      addUserAddress(data.createAddress.address);
      setCreateDialogOpen(false);
    })
  }

  return (
    <div className={viewsClasses.background}>
      <Container maxWidth="md" style={{ minHeight: '80vh', paddingTop: '2rem', paddingBottom: '2rem' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem'}}>
          <Button variant="contained" onClick={() => setCreateDialogOpen(true)} color="primary">
            Crear nueva dirección
          </Button>
        </div>
        <Grid container spacing={2} justify="center">
          {user.addresses.length > 0
            ? user.addresses
              .filter((address) => !address.deletedAt)
              .sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10))
              .map((address) => (
              <Grid item xs={12} key={address.id}>
                <AddressCard address={address} />
              </Grid>
            ))
            : (
              <Paper style={{ padding: '1rem' }}>
                <Typography variant="h6">No tienes ninguna dirección registrada</Typography>
              </Paper>
            )}
        </Grid>
      </Container>
      <Dialog
        fullWidth
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      >
        <DialogTitle>Crear nueva dirección</DialogTitle>
        <DialogContent style={{ padding: '1rem' }}>
          <EditOrCreateAddress address={newAddress} onChange={handleAddressChange} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setCreateDialogOpen(false)}>Cancelar</Button>
          <Button
            color="primary"
            onClick={handleCreateAddress}
            disabled={loading || !newAddress}
            endIcon={loading ? <CircularProgress size={14} /> : null}
          >
            Crear dirección
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={Boolean(error)} autoHideDuration={4000}>
        <Alert severity="error" elevation={6} variant="filled">
          No fue posible crear la dirección
        </Alert>
      </Snackbar>
    </div>
  )
}
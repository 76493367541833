import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  Typography,
  CircularProgress,
  LinearProgress,
} from '@material-ui/core';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from '@apollo/client'
import { MainContext } from '../../context/MainContext'
import { FormField } from '../userActions'
import { formatMoney } from '../../helpers/money'
import SelectAddress from './SelectAddress';
import CreateAddress from './CreateAddress';
import Scheduling from './Scheduling';
import { CREATE_ADDRESS, UPDATE_ADDRESS } from '../../gql/address';
import { CREATE_UBER_DELIVERY_QUOTE } from '../../gql/delivery';
import { deliveryCalculator } from '../../helpers/delivery';
import GoogleMaps from '../utils/GoogleMaps';

const useStyles = makeStyles(theme => ({
  marginTop: {
    marginTop: '0.5rem',
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  nonSelected: {
    backgroundColor: 'white',
  },
  zoneContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  timeContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.5rem',
  },
  buttonContainer: {
    marginTop: '3rem',
  },
  pickupContainer: {
    marginTop: '1rem',
    padding: '0 1rem',
  },
  storeContainer: {
    display: 'flex',
    alignItems: 'center',
  }
}))

const DeliveryDetails = () => {
  const classes = useStyles()
  let [createAddress, { loading: createLoading, error: createError }] = useMutation(CREATE_ADDRESS)
  let [updateAddress, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_ADDRESS)
  let [createDeliveryQuote, {
    loading: deliveryQuoteLoading,
    data: deliveryQuoteData,
  }] = useMutation(CREATE_UBER_DELIVERY_QUOTE)

  const {
    deliveryOptions,
    setDeliveryOptions,
    setDialogOpen,
    // deliveryZones,
    restaurant,
    user,
    addUserAddress,
    scheduleOptions,
  } = useContext(MainContext);


  const [note, setNote] = useState(
    deliveryOptions && deliveryOptions.address ? deliveryOptions.address.note : ''
  );
  const [deliveryQuoteError, setDeliveryQuoteError] = useState('');

  useEffect(() => {
    const { type, time } = scheduleOptions;
    if (!deliveryOptions.address) return;
    let dropoffReadyDt = null;
    let pickupReadyDt = null;
    const PICKUP_DELAY = restaurant.deliveryPickupDelay || 25;
    if (type === 'scheduled' && time) {
      const [hours, minutes] = time.split(':');
      dropoffReadyDt = moment()
        .startOf('day')
        .add(hours, 'hours')
        .add(minutes, 'minutes')
        .format();
    } else {
      pickupReadyDt = moment().add(PICKUP_DELAY, 'minutes').format();
    }
    handleCreateDeliveryQuote(
      deliveryOptions.address.long,
      restaurant.short_address,
      dropoffReadyDt,
      pickupReadyDt,
    )
  }, [scheduleOptions]);

  useEffect(() => {
    if (!deliveryQuoteData) return;

    const { CreateUberDeliveryQuote: newDeliveryQuoteData } = deliveryQuoteData;
    if (newDeliveryQuoteData.__typename === 'UberDeliveryQuote') {
      const deliveryQuote = { ...newDeliveryQuoteData, fee: deliveryCalculator(newDeliveryQuoteData.fee, restaurant) }
      setDeliveryOptions({ ...deliveryOptions, deliveryQuote });
      setDeliveryQuoteError('');
    } else {
      setDeliveryQuoteError(newDeliveryQuoteData.message);
    }
  }, [deliveryQuoteData])

  const handleCreateDeliveryQuote = (dropoffAddress, pickupAddress, dropoffReadyDt, pickupReadyDt) => {
    createDeliveryQuote({
      variables: {
        dropoffAddress,
        pickupAddress,
        dropoffReadyDt,
        pickupReadyDt,
      }
    })
  }

  const handleNoteChange = (_, value, e) => {
    setNote(value);
  }

  const updateDeliveryOptions = (address) => {
    const newDeliveryOptions = {
      ...deliveryOptions,
      address,
      type: 'delivery',
    };
    setDeliveryOptions(newDeliveryOptions);
    setNote('');
  }

  const onCreateComplete = ({ data }) => {
    const address = data.createAddress.address;
    addUserAddress(address);
    updateDeliveryOptions(address);
    setDialogOpen(false, 'address')
  }

  const onUpdateComplete = ({ data }) => {
    const address = data.updateAddress.address;
    updateDeliveryOptions(address);
    setDialogOpen(false, 'address')
  }

  const handleAddress = (address) => {
    if (address) {
      setNote(address.note);
      setDeliveryOptions({ ...deliveryOptions, address, })
      handleCreateDeliveryQuote(address.long, restaurant.short_address);
    }
  }

  console.log('deliveryOptions', deliveryOptions)

  const confirmDeliveryOptions = () => {
    const variables = {
      note,
      id: deliveryOptions ? deliveryOptions.address.id : null,
      short: deliveryOptions.address.short,
      long: deliveryOptions.address.long,
      latitude: deliveryOptions.address.latitude,
      longitude: deliveryOptions.address.longitude,
      default: true,
      user: user ? user.id : null,
    }
    // If there is already an address chosen
    if (variables.id) {
      updateAddress({ variables })
        .then((data) => onUpdateComplete(data))
    }
    else {
      createAddress({ variables })
        .then((data) => onCreateComplete(data))
    }
  }

  const QuoteComponent = () => {
    if (deliveryQuoteLoading) {
      return <LinearProgress />
    } else if (deliveryOptions && deliveryOptions.deliveryQuote) {
      const { latitude: lat, longitude: lng } = deliveryOptions.address;
      return (
        <>
          <div className={classes.marginTop}>
            <Typography gutterBottom>{deliveryOptions.address.long}</Typography>
            <GoogleMaps
              lat={lat}
              lng={lng}
              containerElement={<div style={{ height: `300px`, marginBottom: '1rem' }} />}
              mapElement={<div style={{ height: `100%` }} />}
              options={{ disableDefaultUI: true }}
            />
            <div className={classes.zoneContainer}>
              <Typography>Costo envío</Typography>
              <Typography>${formatMoney(deliveryOptions.deliveryQuote.fee)}</Typography>
            </div>
            <div className={classes.zoneContainer}>
              <Typography>Hora estimada de llegada</Typography>
              <Typography>{moment(deliveryOptions.deliveryQuote.dropoff_eta).format('HH:mm')}</Typography>
            </div>
          </div>
          <div style={{ marginTop: '1.5rem' }}>
            <Typography variant="h6">¿Quieres dejar instrucciones extra?</Typography>
          </div>
          <div className={classes.marginTop}>
            <FormField
              name="note"
              error=""
              value={note}
              onChange={handleNoteChange} />
          </div>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              disabled={createLoading || updateLoading || deliveryQuoteError}
              startIcon={createLoading || updateLoading ? <CircularProgress size={20} /> : null}
              className={classes.button}
              onClick={confirmDeliveryOptions}>
              Confirmar
            </Button>
          </div>
        </>
      )
    } else {
      return null;
    }
  }

  return (
    <>
      <Scheduling deliveryType="delivery" />
      {scheduleOptions && (
        <>
          <div className={classes.marginTop}>
            <CreateAddress
              handleAddress={handleAddress}
              address={deliveryOptions ? deliveryOptions.address : ''}
            />
          </div>
          {user && user.addresses && user.addresses.length > 0 &&
            <div style={{ marginTop: '0.5rem', marginBottom: '2rem' }}>
              <SelectAddress
                handleAddress={handleAddress}
                user={user} />
            </div>
          }
        </>
      )}
      {QuoteComponent()}
      {createError && <Typography color="error">No pudimos guardar tu dirección</Typography>}
      {updateError && <Typography color="error">No pudimos actualizar tu dirección</Typography>}
      <Typography color="error" className={classes.marginTop}>{deliveryQuoteError}</Typography>
    </>
  )
}

export default DeliveryDetails

import React, { useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { MainContext } from '../context/MainContext';
import {
  Container,
  Typography,
  Button,
} from '@material-ui/core';
import { SentimentVeryDissatisfied } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  background: {
    backgroundImage: `url(${theme.backgroundImageUrl})`,
    backgroundSize: 500,
    height: '100vh',
    padding: '2rem 0',
  },
  container: {
    padding: '2rem',
    backgroundColor: 'white',
    borderRadius: 5,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2rem 0',
  },
  button: {
    marginTop: '2rem',
    padding: '0 2rem',
    height: '3rem',
  }
}))

const PaymentFailure = () => {
  const classes = useStyles();
  const history = useHistory();
  const receivedToken = history.location.search ?
    history.location.search.split('=')[1] :
    null;
  const { order } = useContext(MainContext);

  if(!receivedToken && !order){
    return (
      <Redirect to="/" />
    )
  }

  return(
    <div className={classes.background}>
      <Container maxWidth="xs" className={classes.container}>
        <Typography align="center" variant="h4">Operación rechazada</Typography>
        <div className={classes.iconContainer}>
          <SentimentVeryDissatisfied style={{fontSize: 100}}/>
        </div>
        <Typography align="center">
          Lo sentimos, transbank rechazó la transacción. Te recomendamos intentar de nuevo con otra tarjeta
        </Typography>
        <Button
          variant="contained"
          className={classes.button}
          fullWidth
          color="primary"
          onClick={() => history.push('/order-configure')}
        >
          Intentar de nuevo
        </Button>
      </Container>
    </div>
  )
}

export default PaymentFailure;
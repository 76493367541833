import React, { useState } from 'react';
import CreateAddress from '../delivery/CreateAddress';
import { FormField } from '../userActions';

export default function EditOrCreateAddress({ address, onChange }) {
  const [note, setNote] = useState(address?.note);

  const handleNoteChange = (_, value, e) => {
    setNote(value);
    const newAddress = { ...address, note: value };
    onChange(newAddress);
  }

  const handleAddressChange = (partialAddress) => {
    const newAddress = { ...address, ...partialAddress };
    onChange(newAddress);
  }

  return (
    <>
      <div style={{ marginBottom: '2rem' }}>
        <CreateAddress address={address} handleAddress={handleAddressChange} />
      </div>
      <FormField
        name="note"
        error=""
        value={note}
        onChange={handleNoteChange} />
    </>
  )
}
import React from 'react';
import {
  CircularProgress,
} from '@material-ui/core';

const FullPageLoader = () => (
  <div style={{
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}>
    <CircularProgress size={30} />
  </div>
);

export default FullPageLoader;
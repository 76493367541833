import TagManager from 'react-gtm-module';

export const initialize = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
    auth: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH,
    preview: process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW,
  };

  if (!tagManagerArgs.gtmId || !tagManagerArgs.auth || !tagManagerArgs.preview) return;
  TagManager.initialize(tagManagerArgs);
};

export const sendTransactionData = (transactionData) => {
  TagManager.dataLayer({ dataLayer: transactionData });
}
import { useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Paper, Snackbar, Typography } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import moment from 'moment-timezone';
import React, { useContext, useState } from 'react';
import { MainContext } from '../../context/MainContext';
import { DELETE_ADDRESS, UPDATE_ADDRESS } from '../../gql/address';
import { Alert as AlertDialog } from '../dialogs';
import Alert from '@material-ui/lab/Alert';
import EditOrCreateAddress from './EditOrCreate';


export default function AddressCard({ address }) {
  const { replaceUserAddress } = useContext(MainContext);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [componentAddress, setComponentAddress] = useState({ ...address });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [deleteAddress, { loading, error }] = useMutation(DELETE_ADDRESS);
  const [updateAddress, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_ADDRESS);

  const handleOnDelete = () => {
    setDeleteDialogOpen(true);
  }

  const handleDeleteAddress = () => {
    deleteAddress({
      variables: {
        id: componentAddress.id,
        deletedAt: moment(),
      }
    })
      .then(({ data }) => {
        replaceUserAddress(data.updateAddress.address);
      })
    setDeleteDialogOpen(false);
  }

  const handleEdit = () => {
    setEditDialogOpen(true);
  }

  const handleAddressChange = (address) => {
    setComponentAddress(address);
  }

  const handleSaveChanges = () => {
    setEditDialogOpen(false);
    updateAddress({
      variables: {
        ...componentAddress,
      },
    })
    .then(({ data }) => {
      replaceUserAddress(data.updateAddress.address);
      setComponentAddress(data.updateAddress.address);
      setUpdateSuccess(true);
    })
  }

  return (
    <Paper style={{ padding: '1rem', display: 'flex', alignItems: 'center' }}>
      {(loading || updateLoading) ? (
        <LinearProgress />
      ) : (
        <Grid container>
          <Grid item xs={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography>{componentAddress.long}</Typography>
            {componentAddress.note && (<Typography variant="caption">{componentAddress.note}</Typography>)}
          </Grid>
          <Grid item xs={4}>
            <Grid container justify="flex-end">
              <IconButton onClick={handleEdit}>
                <Edit />
              </IconButton>
              <IconButton onClick={handleOnDelete}>
                <Delete color="error" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid >
      )}
      <Dialog
        fullWidth
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
      >
        <DialogTitle>Editar dirección</DialogTitle>
        <DialogContent style={{ padding: '1rem' }}>
          <EditOrCreateAddress address={componentAddress} onChange={handleAddressChange} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setEditDialogOpen(false)}>Cancelar</Button>
          <Button color="primary" onClick={handleSaveChanges}>Guardar cambios</Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={deleteDialogOpen}
        accept={handleDeleteAddress}
        onClose={() => setDeleteDialogOpen(false)}
        title="¿Quieres eliminar esta dirección?"
        text="Esta acción es irreversible y tendrás que agregar la dirección de nuevo"
        acceptText="Eliminar dirección"
        cancelText="Cancelar"
      />
      <Snackbar open={Boolean(error)} autoHideDuration={4000}>
        <Alert severity="error" elevation={6} variant="filled">
          No fue posible eliminar la dirección
        </Alert>
      </Snackbar>
      <Snackbar open={Boolean(updateError)} autoHideDuration={4000}>
        <Alert severity="error" elevation={6} variant="filled">
          No fue posible actualizar la dirección
        </Alert>
      </Snackbar>
      <Snackbar open={updateSuccess} autoHideDuration={4000} onClose={() => setUpdateSuccess(false)}>
        <Alert severity="success" elevation={6} variant="filled">
          Dirección actualizada con éxito
        </Alert>
      </Snackbar>
    </Paper >
  )
}
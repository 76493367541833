import React from 'react';
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const GoogleMaps = withGoogleMap(({ lat, lng }) =>
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{ lat, lng }}
    options={{ disableDefaultUI: true }}
  >
    <Marker position={{ lat, lng }} />
  </GoogleMap>
)

export default GoogleMaps;
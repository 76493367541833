import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ShoppingCart } from '@material-ui/icons'
import {
  ChooseOne,
  ChooseMany,
  Comment,
  QuantitySelect,
} from './'
import { formatMoney } from '../../helpers/money'
import { MainContext } from '../../context/MainContext'
import { Image } from '../items';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '2em 0 4em',
    backgroundColor: theme.palette.common.white,
  },
  itemName: {
    color: theme.palette.text.secondary,
  },
  itemInfo: {
    padding: '0 1em',
  },
  itemDescription: {
    marginBottom: 0,
    lineHeight: 1.1,
  },
  itemImage: {
    margin: '2em 0',
    width: '100%',
  },
  configContainer: {
    marginBottom: '2em',
  },
  addToCart: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    height: '4em',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'space-around',
    textTransform: 'none',
  },
  disabledAddToCart: {
    backgroundColor: `${theme.palette.grey[400]} !important`
  },
  configMarker: {
    width: '100%',
    position: 'relative',
    top: -10,
    [theme.breakpoints.down('sm')]: {
      top: -60,
    },
  },
}))

// TODO: homologate choose one with choose many
const Configure = ({ item, onDialog, handleClose }) => {
  const classes = useStyles();
  const { editItemFromCart, addItemToCart } = useContext(MainContext);
  const history = useHistory();
  const [userSelections, setUserSelections] = useState(null);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState(false);
  const configMarker = useRef();

  useEffect(() => {
    const getData = async () => {
      // Item is already in cart and comes from an edit button
      if (item.hasOwnProperty('userSelections')) {
        getSelectionsFromItem()
        setEditing(true)
        setLoading(false)
      }
      else {
        await generateSelections()
        setLoading(false)
      }
    }
    getData()
  }, [item])

  const getSelectionsFromItem = () => {
    let _userSelections = {};
    Object.keys(item.userSelections).forEach(key => {
      if (Array.isArray(item.userSelections[key])) {
        _userSelections[key] = item.userSelections[key].map((selection, i) =>
          selection ? true : false
        );
      } else {
        _userSelections[key] = item.userSelections[key];
      }
    });

    setUserSelections(_userSelections)
    setPrice(item.price)
    setComment(item.comment)
    setQuantity(item.quantity)
  }

  const generateSelections = async () => {
    let _userSelections = {};
    item.item_modifications.forEach(config => {
      if (config.type === 'choose_one')
        _userSelections[config.id] = { name: '', price: 0 };
      else if (config.type === 'choose_many')
        _userSelections[config.id] = config.modification_options.map(option => false)
    });
    setUserSelections(_userSelections)
    setPrice(item.price)
  }

  const allSelectionsMade = () => {
    let allSelectionsMade = true
    item.item_modifications.filter(config => config.mandatory).forEach(config => {
      if (config.type === 'choose_one' && userSelections[config.id].name === '') {
        allSelectionsMade = false
      }
      else if (config.type === 'choose_many') {
        const selectedAmount = userSelections[config.id].reduce((p, c) =>
          c ? p + 1 : p
          , 0)
        if (selectedAmount === 0) allSelectionsMade = false
      }
    })
    return allSelectionsMade
  }


  const handleOneChange = (event, id) => {
    const optionName = event.target.value
    const currentConfig = item.item_modifications.find(config => config.id === id);
    const currentOption = currentConfig.modification_options.find(option => option.name === optionName);
    const oldOption = userSelections[id];
    setUserSelections(userSelections =>
      ({
        ...userSelections,
        [id]: currentOption,
      })
    );
    if (oldOption) {
      setPrice(price => price + currentOption.price - oldOption.price)
    }
    else {
      setPrice(price => price + currentOption.price)
    }
  }

  const handleManyChange = (event, id, i) => {
    const checked = event.target.checked;
    const currentConfig = item.item_modifications.find(config => config.id === id)
    const currentOption = currentConfig.modification_options[i]
    const selectedAmount = userSelections[currentConfig.id].reduce((p, c) =>
      c ? p + 1 : p
      , 0);

    let newUserSelections = { ...userSelections };
    const checkedArray = userSelections[id]
    // if trying to select a new option, the limit exists (>0) and the limit has being reached
    if (checked && currentConfig.limit > 0 && selectedAmount === currentConfig.limit) return;
    
    checkedArray[i] = checked;
    newUserSelections = {
      ...userSelections,
      [id]: checkedArray,
    };
    setUserSelections(newUserSelections);

    // Needed because sometimes price is "" instead of 0
    const cleanedPrice = currentOption.price || 0;
    if (checked)
      setPrice(price => price + cleanedPrice);
    else
      setPrice(price => price - cleanedPrice);
  }

  const handleAddToCart = () => {
    if (!allSelectionsMade()) {
      configMarker.current.scrollIntoView({ behavior: 'smooth' });
      setError(true)
      return;
    }
    const selectionsByConfig = {}
    // Determine the selections made based in the boolean values on userSelections
    Object.keys(userSelections).forEach(key => {
      if (Array.isArray(userSelections[key])) {
        selectionsByConfig[key] = userSelections[key].map((selection, i) => {
          if (userSelections[key][i]) {
            return item.item_modifications.find(config => config.id === key).modification_options[i]
          }
          return null
        })
      }
      else {
        selectionsByConfig[key] = userSelections[key]
      }
    });

    const data = {
      ...item,
      totalPrice: price * quantity,
      unitPrice: price,
      userSelections: selectionsByConfig,
      comment,
      quantity,
    }
    if (onDialog) {
      if (editing) {
        editItemFromCart(data);
      }
      else {
        addItemToCart(data);
      }
      handleClose();
    }
    else {
      if (editing) {
        //Comes from cart in mobile
        editItemFromCart(data);
        history.goBack();
      }
      else {
        addItemToCart(data);
        history.replace("/");
      }
    }
  }

  if (loading)
    return null
  return (
    <React.Fragment>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.itemInfo}>
          <Typography gutterBottom variant="h6" className={classes.itemName}>
            <b>{item.name}</b>
          </Typography>
          <Typography component="span" variant="body2" className={classes.itemDescription}>
            {item.description_long ?
              <div>{item.description_long}</div>
              :
              <div dangerouslySetInnerHTML={{ __html: item.description_special }} />
            }
          </Typography>
        </Grid>
        {item.images_min.length > 0 ?
          <Grid item xs={12}>
            <Grid container justify="center">
              <Image item={item} className={classes.itemImage} />
            </Grid>
          </Grid>
          :
          <Grid item xs={12} style={{ height: '1em' }} />
        }
        <Typography color="error" align="center" className={classes.configMarker} ref={configMarker}>
          {error && 'Faltan elecciones obligatorias'}
        </Typography>
        {item.item_modifications.sort((a, b) => a.order - b.order).map((config) => (
          <Grid item xs={12} key={config.id} className={classes.configContainer}>
            {config.type === 'choose_one' &&
              <ChooseOne
                config={config}
                onChange={(event) => handleOneChange(event, config.id)}
                value={userSelections[config.id].name}
              />
            }
            {config.type === 'choose_many' &&
              <ChooseMany
                config={config}
                onChange={(event, i) => handleManyChange(event, config.id, i)}
                userSelections={userSelections[config.id]}
              />
            }
          </Grid>
        ))}
        <Grid item xs={12}>
          <Comment comment={comment} setComment={setComment} />
        </Grid>
        <Grid item xs={12}>
          <QuantitySelect quantity={quantity} setQuantity={setQuantity} />
        </Grid>
      </Grid>
      <Button
        color="primary"
        variant="contained"
        classes={{
          root: classes.addToCart,
          disabled: classes.disabledAddToCart,
        }}
        onClick={handleAddToCart}>
        <div style={{ display: 'flex' }}>
          <ShoppingCart style={{ marginRight: '0.5em' }} />
          <Typography variant="body1">
            {editing ?
              <b>Modificar {quantity > 1 ? 'producto' : 'productos'} </b> :
              <b>Agregar al pedido ({quantity})</b>
            }
          </Typography>
        </div>
        <Typography variant="body1"><b>${formatMoney(price * quantity)}</b></Typography>
      </Button>
    </React.Fragment>
  )
}

export default Configure

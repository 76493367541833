import React, { useContext, useState } from "react";
import host from '../../config/host';
import { Button } from "@material-ui/core";
import FacebookIcon from "../../assets/icons/facebook_logo.svg";
import { MainContext } from "../../context/MainContext";

const FacebookLoginButton = () => {
  const { restaurant } = useContext(MainContext);

  return (
    <Button
    href={`${host}/connect/facebook?callback=${restaurant.host}/login_providers/facebook`}
      variant="contained"
      fullWidth
    >
      <img
        style={{
          marginBottom: 0,
          height: "1.2rem",
          margin: "0.4rem",
          position: "absolute",
          left: 5,
        }}
        alt="facebook login"
        src={FacebookIcon}
      ></img>
      Iniciar sesión con Facebook
    </Button>
  );
};

export default FacebookLoginButton;

import { gql } from "@apollo/client";

export const CREATE_ORDER = gql`
  mutation CreateOrder(
    $user: ID
    $restaurant: ID
    $items: JSON
    $address: ID
    $zone: ID
    $promotionalCodes: [ID]
    $tip: Float
    $additionals: JSON
    $deliveryPrice: Float
    $itemsTotal: Float
    $discount: Float
    $dailyDiscount: Float
    $additionalsPrice: Float
    $totalPrice: Float
    $deliveryType: ENUM_ORDER_DELIVERY_TYPE
    $deliveryEstimate: Int
    $status: ENUM_ORDER_STATUS
    $paymentMethod: ENUM_ORDER_PAYMENT_METHOD
    $scheduledTime: DateTime
  ) {
    createOrder(
      input: {
        data: {
          user: $user
          restaurant: $restaurant
          items: $items
          address: $address
          zone: $zone
          promotional_codes: $promotionalCodes
          tip: $tip
          additionals: $additionals
          delivery_price: $deliveryPrice
          items_total: $itemsTotal
          discount: $discount
          daily_discount: $dailyDiscount
          additionals_price: $additionalsPrice
          total_price: $totalPrice
          delivery_type: $deliveryType
          delivery_estimate: $deliveryEstimate
          status: $status
          payment_method: $paymentMethod
          scheduledTime: $scheduledTime
        }
      }
    ) {
      order {
        id
        user {
          id
        }
        restaurant {
          id
        }
        items
        address {
          id
          short
        }
        zone {
          id
        }
        promotional_codes {
          id
        }
        tip
        additionals
        delivery_price
        items_total
        discount
        additionals_price
        total_price
        delivery_type
        daily_discount
        status
        payment_method
        scheduledTime
      }
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation UpdateOrder(
    $id: ID!
    $user: ID
    $restaurant: ID
    $items: JSON
    $address: ID
    $zone: ID
    $promotionalCodes: [ID]
    $tip: Float
    $additionals: JSON
    $deliveryPrice: Float
    $itemsTotal: Float
    $discount: Float
    $dailyDiscount: Float
    $additionalsPrice: Float
    $totalPrice: Float
    $deliveryType: ENUM_ORDER_DELIVERY_TYPE
    $deliveryEstimate: Int
    $status: ENUM_ORDER_STATUS
    $paymentMethod: ENUM_ORDER_PAYMENT_METHOD
    $scheduledTime: DateTime
  ) {
    updateOrder(
      input: {
        where: { id: $id }
        data: {
          user: $user
          restaurant: $restaurant
          items: $items
          address: $address
          zone: $zone
          promotional_codes: $promotionalCodes
          tip: $tip
          additionals: $additionals
          delivery_price: $deliveryPrice
          items_total: $itemsTotal
          discount: $discount
          daily_discount: $dailyDiscount
          additionals_price: $additionalsPrice
          total_price: $totalPrice
          delivery_type: $deliveryType
          delivery_estimate: $deliveryEstimate
          status: $status
          payment_method: $paymentMethod
          scheduledTime: $scheduledTime
        }
      }
    ) {
      order {
        id
        user {
          id
        }
        restaurant {
          id
        }
        items
        address {
          id
          short
        }
        zone {
          id
        }
        promotional_codes {
          id
        }
        tip
        additionals
        delivery_price
        items_total
        discount
        additionals_price
        total_price
        delivery_type
        daily_discount
        status
        payment_method
        scheduledTime
      }
    }
  }
`;

export const GET_ORDERS = gql`
  query Orders($restaurantId: ID!, $userId: ID!) {
    orders(
      limit: 10
      where: { restaurant: $restaurantId, user: $userId }
      sort: "created_at:desc"
    ) {
      id
      address {
        short
      }
      created_at
    }
  }
`;

export const GET_ORDER = gql`
  query Order($id: ID!) {
    order(id: $id) {
      id
      user {
        id
      }
      restaurant {
        id
      }
      items
      address {
        id
        short
      }
      zone {
        id
      }
      promotional_codes {
        id
      }
      uber_delivery {
        status
        courier
        uuid
        dropoff
      }
      tip
      additionals
      delivery_price
      items_total
      discount
      additionals_price
      total_price
      delivery_type
      daily_discount
      status
      payment_method
      scheduledTime
    }
  }
`;

// Get the updated list of items (to prevent price changes)
export const GET_ITEMS = gql`
  query Items($restaurantId: ID!) {
    getProducts (restaurantId: $restaurantId)
  }
`;

export const GET_ORDER_STATUS = gql`
  query OrderStatus($id: ID!) {
    order(id: $id) {
      status
      uber_delivery {
        status
      }
    }
  }
`;

import React, { useEffect, useContext, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { FullPageLoader, ErrorPage } from '../components/utils';
import { urlParser } from '../helpers/generic';
import { confirmEmail } from '../apiCalls/strapi';
import { MainContext } from '../context/MainContext';

const EmailConfirmation = () => {
  const { search } = useLocation();
  const { confirmUser, setDialogOpen, restaurant } = useContext(MainContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const searchParams = urlParser(search);
  const { confirmation } = searchParams;
  useEffect(() => {
    confirmEmail(confirmation)
      .then(() => {
        confirmUser();
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      })
  }, [])

  if (error) return (
    <ErrorPage
      full
      message={
        `No pudimos confirmar tu email, por favor contáctate con nosotros por whatsapp al ${restaurant.contactInformation.phoneNumber.replace(/ /g, '').replace('+', '')}`
      }
      />
  )
  if (loading) return <FullPageLoader />
  setDialogOpen(true, 'emailConfirmed');
  return <Redirect to="/" />;
}

export default EmailConfirmation;

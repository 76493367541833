import React, { useContext, useState } from 'react';
import { Button, CircularProgress, Container, makeStyles, Paper, Typography } from "@material-ui/core"
import FormField from "./FormField"
import { MainContext } from '../../context/MainContext';
import { updateUser } from '../../apiCalls/strapi';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: '1rem',
  },
  fieldContainer: {
    marginBottom: '0.5rem',
  },
  button: {
    height: 45,
    fontSize: '1.1rem',
  },
  buttonContainer: {
    marginTop: '2rem',
  },
  successColor: {
    color: theme.palette.success.main,
  },
}))

export default function Profile({ user }) {
  const fields = ["firstName", "lastName", "phoneNumber"];
  const classes = useStyles();
  const { setUser, restaurant } = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    firstName: {
      value: user.first_name,
      error: '',
    },
    lastName: {
      value: user.last_name,
      error: '',
    },
    phoneNumber: {
      value: user.phone_number,
      error: '',
    },
  })

  const handleChange = (name, value, error) => {
    setFormData(data => ({
      ...data,
      [name]: {
        ...data[name], value, error
      },
    }))
  }

  const formWithErrors = () => {
    let hasError;
    const { phoneNumber, firstName, lastName } = formData
    const error = 'No puede estar vacío'
    if (Boolean(phoneNumber.error) || Boolean(firstName.error) || Boolean(lastName.error)) {
      return true
    }
    let newData = { ...formData };
    if (firstName.value === '') {
      newData.firstName.error = error;
      hasError = true;
    }
    if (lastName.value === '') {
      newData.lastName.error = error;
      hasError = true;
    }
    if (phoneNumber.value === '') {
      newData.telephone.error = error
      hasError = true
    }
    if (hasError) {
      setFormData(newData)
      return true
    }
    return false;
  }

  const handleUpdateData = async () => {
    const { phoneNumber, firstName, lastName } = formData
    if (formWithErrors()) return;
    setLoading(true);
    setSuccess(false);
    try {
      const response = await updateUser({
        user: user,
        firstName: firstName.value,
        lastName: lastName.value,
        phoneNumber: phoneNumber.value,
        restaurantRegisteredAt: user.restaurantRegisteredAt || restaurant.id,
      });
      setUser({ jwt: user.jwt, ...response.data })
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setError('No pudimos actualizar tus datos');
      setLoading(false);
    }
  }

  return (
    <Paper
      elevation={10}
      style={{
        margin: "2rem",
        padding: "2rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Container maxWidth="sm">
        <Typography variant="h4" align="center" className={classes.title}>
          Completa tus datos
        </Typography>
        {fields.map((field) =>
          <div key={field} className={classes.fieldContainer}>
            <FormField
              type={field}
              name={field}
              error={formData[field].error}
              value={formData[field].value}
              onChange={handleChange} />
          </div>
        )}
        <div className={classes.buttonContainer}>
          {success && (
            <Typography className={classes.successColor} align="center" component="div">
              Datos actualizados correctamente
            </Typography>
          )}
          {error && (
            <Typography color="error" align="center" component="div">
              {error}
            </Typography>
          )}
          <Button
            onClick={handleUpdateData}
            className={classes.button}
            fullWidth
            disabled={loading}
            endIcon={loading ? <CircularProgress size={20} /> : null}
            variant="contained"
            color="primary"
          >
            Actualizar datos
          </Button>
        </div>
      </Container>
    </Paper>
  )
}
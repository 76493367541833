import React from 'react'
import {
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { formatMoney } from '../../helpers/money'
import Image from './Image';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    borderRadius: 10,
    minHeight: 125,
    margin: '1rem 0',
  },
  infoContainer: {
    marginLeft: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  name: {
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    height: 20,
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  description: {
    lineHeight: 1.2,
    fontSize: 14,
    overflow: "hidden",
    display: '-webkit-box',
    marginBottom: '0.5em',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
  },
  image: {
    margin: 0,
    minWidth: 125,
    minHeight: 125,
    maxHeight: 125,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
}));

const CardMobile = ({ item }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {item.images_min.length > 0
        ? (
          <Image item={item} className={classes.image} />
        )
        : (
          <div className={classes.image} />
        )
      }
      <div className={classes.infoContainer}>
        <div>
          <Typography className={classes.name}>{item.name}</Typography>
          <Typography className={classes.description}>
            {item.description_long ?
              <span>{item.description_long}</span>
              :
              <span dangerouslySetInnerHTML={{ __html: item.description_special }} />
            }
          </Typography>
        </div>
        <Typography> ${formatMoney(item.price)} </Typography>
      </div>
    </div>
  )
}

export default CardMobile

import React from 'react'
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { formatMoney } from '../../helpers/money'

const CustomRadio = withStyles({
  root: {
    color: `rgb(67, 89, 110)`,
    '&$checked': {
      color: `rgb(67, 89, 110)`,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  label: {
    width: '100%',
    padding: '0.5em 1em',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    marginBottom: '1em',
  },
  radioGroup: {
    padding: '0 1em',
  },
  radio: {
    borderBottomWidth: '1px',
    borderBottomColor: theme.palette.grey[500],
    borderBottomStyle: 'solid',
    margin: 0,
  },
  radioLabelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  optionName: {
    flex: 0.7,
  },
  optionPrice: {
    flex: 0.3,
  },
}))


const ChooseOne = ({config, onChange, value}) => {
  const classes = useStyles()
  return(
    <div className={classes.container}>
      <div className={classes.label}>
        <Typography><b>{config.title}</b></Typography>
        {config.mandatory && <Typography variant="body2">Obligatorio</Typography>}
      </div>
      <RadioGroup
        aria-label={config.slug}
        name={config.slug}
        value={value}
        onChange={onChange}
        className={classes.radioGroup}>
        {config.modification_options.map(option =>
          <FormControlLabel
            key={option.name}
            className={classes.radio}
            value={option.name}
            control={<CustomRadio />}
            classes={{
              label: classes.radioLabelContainer,
            }}
            label={
              <>
                <Typography className={classes.optionName}>{option.name}</Typography>
                {option.price > 0 &&
                  <Typography className={classes.optionPrice}>+ ${formatMoney(option.price)}</Typography>
                }
              </>} />
        )}
      </RadioGroup>
    </div>
  )
}

export default ChooseOne

import React, { Component, createContext } from 'react';
import { getPromosDiscounts } from '../helpers/cart';
import { nextAvailableQuarterHour } from '../helpers/date';

const INITIAL_STATE = {
  user: null,
  menus: null,
  selectedMenu: 0,
  selectedCategory: 0,
  restaurant: null,
  deliveryZones: [{
    id: 1,
    name: 'Zona 1',
    price: 2500,
    center: [],
    radius: 2000, //meters
  }],
  deliveryOptions: null,
  scheduleOptiones: {
    type: 'inmediate',
    time: nextAvailableQuarterHour().format('HH:mm')
  },
  cart: [],
  dialogsOpen: {
    login: false,
    register: false,
    address: false,
    forgotPassword: false,
    confirmEmailDialog: false,
    emailConfirmed: false,
  },
  dailyPromo: [],
  promotionalCodes: [],
  order: null,
  currentTransactionToken: null,
  apolloClient: null,
}

export const MainContext = createContext(INITIAL_STATE);

export class MainContextProvider extends Component {
  state = {
    user: JSON.parse(localStorage.getItem('user')) || null,
    menus: null,
    selectedMenu: 0,
    selectedCategory: 0,
    restaurant: JSON.parse(localStorage.getItem('restaurant')) || null,
    deliveryZones: JSON.parse(localStorage.getItem('deliveryZones')) || null,
    deliveryOptions: JSON.parse(localStorage.getItem('deliveryOptions')) || null,
    scheduleOptions: {
      type: 'inmediate',
      time: nextAvailableQuarterHour().format('HH:mm')
    },
    cart: JSON.parse(localStorage.getItem('cart')) || [],
    dialogsOpen: {
      login: false,
      register: false,
      address: false,
      forgotPassword: false,
      confirmEmailDialog: false,
      emailConfirmed: false,
    },
    dailyPromo: [],
    promotionalCodes: JSON.parse(localStorage.getItem('promotionalCodes')) || [],
    order: JSON.parse(localStorage.getItem('order')) || null,
    currentTransactionToken: JSON.parse(localStorage.getItem('currentTransactionToken')) || null,
    apolloClient: null,
  }

  render() {
    // localStorage.clear();
    // localStorage.removeItem('order')
    return (
      <MainContext.Provider value={{
        user: this.state.user,
        restaurant: this.state.restaurant,
        menus: this.state.menus,
        cart: this.state.cart,
        selectedMenu: this.state.selectedMenu,
        selectedCategory: this.state.selectedCategory,
        deliveryZones: this.state.deliveryZones,
        deliveryOptions: this.state.deliveryOptions,
        scheduleOptions: this.state.scheduleOptions,
        dialogsOpen: this.state.dialogsOpen,
        dailyPromo: this.state.dailyPromo,
        promotionalCodes: this.state.promotionalCodes,
        order: this.state.order,
        currentTransactionToken: this.state.currentTransactionToken,
        apolloClient: this.state.apolloClient,
        setRestaurant: (restaurant) => {
          this.setState((state) => ({...state, restaurant}));
          localStorage.setItem('restaurant', JSON.stringify(restaurant));
        },
        addItemToCart: (item) => {
          const temporaryId = parseInt(Math.random() * 1000000)
          item = { ...item, temporaryId }
          let newCart = [...this.state.cart, item];
          const newPromotionalCodes = getPromosDiscounts(newCart, this.state.promotionalCodes)
          this.setState(state => ({...state, cart: newCart, promotionalCodes: newPromotionalCodes}))
          localStorage.setItem('cart', JSON.stringify(newCart))
          localStorage.setItem('promotionalCodes', JSON.stringify(newPromotionalCodes))
        },
        addMutlipleItemToCart: (items) => {
          let newCart = this.state.cart;
          for (let i = 0; i < items.length; i++) {
            let item = items[i];

            const temporaryId = parseInt(Math.random() * 1000000)
            item = { ...item, temporaryId }

            newCart = [...newCart, item];
          }

          const newPromotionalCodes = getPromosDiscounts(newCart, this.state.promotionalCodes)
          this.setState(state => ({...state, cart: newCart, promotionalCodes: newPromotionalCodes}))
          localStorage.setItem('cart', JSON.stringify(newCart))
          localStorage.setItem('promotionalCodes', JSON.stringify(newPromotionalCodes))
        },
        editItemFromCart: (item) => {
          let newCart = this.state.cart
          let itemIndex = newCart.findIndex(_item => _item.temporaryId === item.temporaryId)
          newCart[itemIndex] = item
          const newPromotionalCodes = getPromosDiscounts(newCart, this.state.promotionalCodes)
          this.setState(state => ({...state, cart: newCart, promotionalCodes: newPromotionalCodes}))
          localStorage.setItem('cart', JSON.stringify(newCart))
          localStorage.setItem('promotionalCodes', JSON.stringify(newPromotionalCodes))
        },
        removeItemFromCart: (item) => {
          let newCart = this.state.cart.filter(_item => _item.temporaryId !== item.temporaryId)
          const newPromotionalCodes = getPromosDiscounts(newCart, this.state.promotionalCodes)
          this.setState(state => ({...state, cart: newCart, promotionalCodes: newPromotionalCodes}))
          localStorage.setItem('cart', JSON.stringify(newCart))
          localStorage.setItem('promotionalCodes', JSON.stringify(newPromotionalCodes))
        },
        cleanCart: () => {
          this.setState(state => ({...state, cart: []}))
          localStorage.setItem('cart', JSON.stringify([]))
        },
        setMenus: (menus) => this.setState(state => ({...state, menus})),
        setMenu: (menu) => this.setState(state => ({...state, selectedMenu: menu})),
        setCategory: (category) => this.setState(state => ({...state, selectedCategory: category})),
        setUser: (user) => {
          this.setState(state => ({...state, user}))
          localStorage.setItem('user', JSON.stringify(user))
        },
        logout: () => {
          this.setState(state => ({
            ...state,
            user: null,
            deliveryOptions: null,
            order: null,
            currentTransactionToken: null,
          }))
          localStorage.setItem('user', JSON.stringify(null));
          localStorage.setItem('deliveryOptions', JSON.stringify(null));
          localStorage.setItem('order', JSON.stringify(null));
          localStorage.setItem('currentTransactionToken', JSON.stringify(null));
        },
        addUserAddress: (address) => {
          //TODO: Handle if there is no user and save the address for when user registers or logs in checkout
          if(!this.state.user) return
          let newAddresses = this.state.user.addresses || []
          newAddresses.push(address)
          const user = {...this.state.user, addresses: newAddresses}
          this.setState(state => ({...state, user }))
          localStorage.setItem('user', JSON.stringify(user))
        },
        replaceUserAddress: (updatedAddress) => {
          const newAddresses = [...this.state.user.addresses]
          const addressIndex = newAddresses.findIndex((address) => address.id.toString() === updatedAddress.id);
          newAddresses[addressIndex] = updatedAddress;
          const user = {...this.state.user, addresses: newAddresses};
          this.setState(state => ({...state, user }))
          localStorage.setItem('user', JSON.stringify(user))
        },
        setDeliveryOptions: (deliveryOptions) => {
          this.setState(state => ({...state, deliveryOptions}));
          localStorage.setItem('deliveryOptions', JSON.stringify(deliveryOptions));
        },
        setScheduleOptions: (scheduleOptions) => this.setState(state => ({...state, scheduleOptions})),
        setDialogOpen: (open, dialog) =>
          this.setState(state => ({
            ...state,
            dialogsOpen: { ...state.dialogsOpen, [dialog]: open },
          })),
        setDeliveryZones: (zones) => {
          this.setState(state => ({...state, deliveryZones: zones}))
          localStorage.setItem('deliveryZones', JSON.stringify(zones))
        },
        setDailyPromo: (promo) => this.setState(state => ({...state, dailyPromo: getPromosDiscounts(this.state.cart, [promo])})),
        addPromotionalCode: (promo) => {
          let newPromotionalCodes = [...this.state.promotionalCodes]

          // If the promotion is stackable add it to the list
          if(promo.isStackable) {
            newPromotionalCodes.push(promo);
          } else {
            const notStackableIndex = newPromotionalCodes.findIndex((promo) => !promo.isStackable)
            // If there is already a non stackable promotion, replace it with the new one
            if (notStackableIndex !== -1) newPromotionalCodes[notStackableIndex] = promo;
            else newPromotionalCodes.unshift(promo);
          }
          newPromotionalCodes = getPromosDiscounts(this.state.cart, newPromotionalCodes)
          localStorage.setItem('promotionalCodes', JSON.stringify(newPromotionalCodes));
          this.setState(state => ({...state, promotionalCodes: newPromotionalCodes}));
        },
        cleanPromotionalCodes: () => {
          localStorage.setItem('promotionalCodes', JSON.stringify([]));
          this.setState(state => ({...state, promotionalCodes: []}));
        },
        setOrder: (order) => {
          this.setState(state => ({...state, order}))
          localStorage.setItem('order', JSON.stringify(order))
        },
        setCurrentTransactionToken: (token) => {
          this.setState(state => ({...state, currentTransactionToken: token}))
          localStorage.setItem('currentTransactionToken', JSON.stringify(token))
        },
        confirmUser: () => {
          const newUser = { ...this.state.user, confirmed: true };
          this.setState((state) => ({ ...state, user: newUser }))
          localStorage.setItem('user', JSON.stringify(newUser))
        },
        setApolloClient: (apolloClient) => {
          this.setState((state) => ({ ...state, apolloClient }))
        },
      }}>
        {this.props.children}
      </MainContext.Provider>
    )
  }

}

export const withMainContext = ChildComponent => props => (
  <MainContext.Consumer>
    {
      mainContext => <ChildComponent {...props} mainContext={mainContext}  />
    }
  </MainContext.Consumer>
);

import React, { useEffect, useContext, useState, useRef } from 'react'
import {
  Card,
  Radio,
  RadioGroup,
  GridList,
  GridListTile,
  FormControlLabel,
  Typography,
  Divider,
  Button,
  LinearProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import WebpayPayment from '../../assets/icons/webpay_payment.svg';
import RegisterCard from '../../assets/icons/register_card.svg';
import { restaurantHasFeature } from '../../helpers/generic';

import { MainContext } from '../../context/MainContext';

import { oneClickStartInscription } from '../../apiCalls/strapi';

import { useQuery } from "@apollo/client";
import { GET_USER_CARDS } from '../../gql/user';
import { klapAvailablePayments } from '../../helpers/payments';

const useStyles = makeStyles(theme => ({
  label: {
    width: '100%',
    padding: '0.5em 1em',
    backgroundColor: '#EEE',
    color: 'black',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
    }
  },
  container: {
    padding: '0 1em',
  },
  marginTop: {
    marginTop: '1rem',
  },
  paymentCard: {
    height: '100%',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
  },
  tile: {
    overflow: 'unset',
  },
  formControlLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  instructions: {
    marginTop: '0.5rem',
  },
  contactLink: {
    textDecoration: 'none',
  },
}))

let paymentOptions = [
  {
    name: 'Webpay',
    image:
      <img
        style={{ width: 45, margin: '5px 1rem 0 0.5rem', }}
        src={WebpayPayment}
        alt="logo webpay"
      />,
    text: 'Webpay'
  },
  {
    name: 'CreditOrDebitCard',
    image:
      <img
        style={{ width: 45, margin: '5px 1rem 0 0.5rem', }}
        src={WebpayPayment}
        alt="credit card payment icon"
      />,
    text: 'Tarjeta de crédito o débito',
  },
  {
    name: 'OneClick',
    image:
      <img
        style={{ width: 45, margin: '5px 1rem 0 0.5rem', }}
        src={RegisterCard}
        alt="logo registrar tarjeta"
      />,
    text: 'Tarjeta registrada'
  },
  {
    name: 'Edenred',
    image:
      <img
        style={{ width: 120, marginBottom: 0, marginTop: 5, }}
        src="/images/edenred-logo.png"
        alt="logo edenred"
      />,
  },
  // {
  //   name: 'Amipass',
  //   image:
  //     <img
  //       style={{ width: 120, marginBottom: 0, marginTop: 5, }}
  //       src="/images/amipass-logo.png"
  //       alt="logo amipass"
  //     />,
  // },
  {
    name: 'Sodexo',
    image:
      <img
        style={{ width: 120, marginBottom: 0, marginTop: 5, }}
        src="/images/sodexo-logo.png"
        alt="logo sodexo"
      />,
  },
  // {
  //   name: 'CreditCard',
  //   image:
  //     <img
  //       style={{ width: 45, margin: '5px 1rem 0 0.5rem', }}
  //       src={CardPayment}
  //       alt="credit card payment icon"
  //     />,
  //   text: 'Tarjeta de crédito',
  // },
  // {
  //   name: 'DebitCard',
  //   image:
  //     <img
  //       style={{ width: 45, margin: '5px 1rem 0 0.5rem', }}
  //       src={CardPayment}
  //       alt="credit card payment icon"
  //     />,
  //   text: 'Tarjeta de débito',
  // },
  // {
  //   name: 'Transfer',
  //   image:
  //     <img
  //       style={{ width: 120, marginBottom: 0, marginTop: 5, }}
  //       src="/images/transfer-logo.png"
  //       alt="transfer payment logo"
  //     />,
  // },
  // {
  //   name: 'Cash',
  //   image:
  //     <img
  //       style={{ width: 45, margin: '5px 1rem 0 0.5rem', }}
  //       src={CashPayment}
  //       alt="cash payment logo"
  //     />,
  //   text: 'Efectivo'
  // },
]
const PaymentSelect = ({ paymentSelected, setPaymentSelected, restaurant }) => {
  const { user } = useContext(MainContext)

  const { loading: loadingMyCards, data: myCards, error: myCardsError } = useQuery(GET_USER_CARDS, {
    variables: {
      restaurantId: restaurant.id,
    },
  });

  const formRef = useRef();
  const inputRef = useRef();

  const classes = useStyles()
  const [selected, setSelected] = useState(paymentSelected || '');
  const [availablePayments, setAvailablePayments] = useState([]);

  useEffect(() => {
    let newAvailablePayments = [];
    if (restaurantHasFeature(restaurant, 'webpay')) {
      newAvailablePayments.push(paymentOptions.find((payment) => payment.name === 'Webpay'));
    }
    if (restaurantHasFeature(restaurant, 'oneClick')) {
      newAvailablePayments.push(paymentOptions.find((payment) => payment.name === 'OneClick'));
    }
    if (restaurantHasFeature(restaurant, 'klap')) {
      newAvailablePayments = [
        ...newAvailablePayments,
        ...paymentOptions.filter((payment) => klapAvailablePayments.includes(payment.name))
      ];
    }
    setAvailablePayments(newAvailablePayments);
    setPaymentSelected(paymentSelected || newAvailablePayments[0].name);
    setSelected(paymentSelected || newAvailablePayments[0].name)
  }, [restaurant])

  const handleChange = ({ target: { value } }) => {
    setSelected(value);
    setPaymentSelected(value);
  }

  const modifyCard = () => {
    oneClickStartInscription(user.jwt, restaurant)
      .then(async (response) => {
        const { data } = response;
        const { token, url } = data;

        formRef.current.action = `${url}`;
        inputRef.current.value = token;
        formRef.current.submit();
      })
      .catch((err) => console.log(err))
  }

  const myCardsContent = () => {
    if (!user) {
      return (
        <Typography className={classes.instructions}>Se debe iniciar sesión para ver las tarjetas guardadas.</Typography>
      )
    } else {
      if (loadingMyCards) {
        return (
          <LinearProgress />
        )
      }

      if (myCardsError) {
        return (
          <Typography className={classes.instructions}>Hubo un error al obtener tus tarjetas guardadas, si realizas el pago se sobreescribiran.</Typography>
        );
      }

      if (myCards.getMyCards && myCards.getMyCards.length > 0) {
        return myCards.getMyCards.map((cardData) => {
          const cardNumber = cardData.card_number.substring(cardData.card_number.length - 4, cardData.card_number.length)
          return (
            <>
              <Typography className={classes.instructions}>{`Pagaras con tu tarjeta ${cardData.card_type} terminada en ${cardNumber}`}</Typography>
              <Button color="primary" variant="text" onClick={modifyCard}>Cambiar tarjeta agregada</Button>
            </>
          );
        })
      }
    }
  }

  return (
    <>
      <div className={classes.label}>
        <Typography><b>Escoge tu método de pago</b></Typography>
      </div>
      <div className={classes.container}>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={paymentSelected}
          onChange={handleChange}>
          <GridList cellHeight={60} cols={4} spacing={10}>
            {availablePayments.map(payment =>
              <GridListTile style={{ width: "100%" }} key={payment.name} cols={3} classes={{ tile: classes.tile }}>
                <Card className={classes.paymentCard}>
                  <FormControlLabel
                    value={payment.name}
                    control={<Radio />}
                    label={
                      <div className={classes.formControlLabel}>
                        {payment.image}
                        <Typography><b>{payment.text}</b></Typography>
                      </div>
                    } />
                </Card>
              </GridListTile>
            )}
          </GridList>
        </RadioGroup>
        <form method="post" ref={formRef}>
          <input type="hidden" name="TBK_TOKEN" ref={inputRef} />
        </form>
        {(selected === 'OneClick') && (
          <>
            <Divider />
            {
              myCardsContent()
            }
          </>
        )}
      </div>
    </>
  )
}

export default PaymentSelect

import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Container,
  Typography,
  Divider,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Details } from '../components/orderConfigure';
import { ErrorPage, ScrollToTopOnMount } from '../components/utils';
import { getFullWrittenDate } from '../helpers/date';
import { translatePaymentMethod } from '../helpers/i18n';
import { restaurantHasFeature } from '../helpers/generic';
import useViewsStyles from './styles';
import { GET_ORDER } from '../gql/orders';
import { Status } from '../components/order';
import { MainContext } from '../context/MainContext';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '2rem',
    borderRadius: 5,
    backgroundColor: theme.palette.background.default,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2rem 0',
  },
  divider: {
    backgroundColor: theme.palette.grey[500],
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.grey[700],
    },
    margin: '2rem 0'
  },
  button: {
    marginTop: '2rem',
    padding: '0 2rem',
    height: '3rem',
  },
  statusContainer: {
    marginBottom: '4rem',
  }
}))

const Order = () => {
  const classes = useStyles();
  const viewsClasses = useViewsStyles();
  const { id } = useParams();
  const { restaurant } = useContext(MainContext);
  const { data, loading, error } = useQuery(GET_ORDER, {
    variables: {
      id,
    },
  });

  if (loading) return <LinearProgress />;
  if (error) return (
    <ErrorPage
      full
      message={
        `Para saber el estado de tu pedido contáctate con nosotros por whatsapp al ${restaurant.contactInformation.phoneNumber.replace(/ /g, '').replace('+', '')}`
      }
    />
  )

  const { order } = data;
  const deliveryText = order.delivery_type === 'delivery' ? `${order.address.short}` : 'Retiro en el local';
  const status = order.uber_delivery?.status || order.status;
  const uuid = order.uber_delivery?.uuid

  return (
    <div className={viewsClasses.background}>
      <ScrollToTopOnMount />
      <Container maxWidth="md" className={classes.container}>
        <Typography variant="h4">Tu pedido</Typography>
        <Typography variant="h6">
          {getFullWrittenDate(order.created_at)}
        </Typography>
        {order.scheduledTime
          ? (
            <Typography variant="h6">
              {deliveryText} a las {getFullWrittenDate(order.scheduledTime)}
            </Typography>
          )
          : (
            <Typography variant="h6">{deliveryText} lo antes posible</Typography>
          )}
        <Typography>Pagado con {translatePaymentMethod(order.payment_method, 'ES')}</Typography>
        {order.uber_delivery?.courier && (
          <>
          <Divider className={classes.divider} />
            <Typography style={{ marginBottom: '1rem' }} color="error"><strong>
              Tienes que darle este código al repartidor para recibir tu pedido {order.uber_delivery?.dropoff?.verification_requirements?.pincode?.value}
            </strong></Typography>
            <Typography><strong>Repartidor</strong></Typography>
            <Typography variant="body2">{order.uber_delivery.courier.name}</Typography>
            <Typography variant="body2">{order.uber_delivery.courier.phone_number}</Typography>
            {uuid && (<Typography variant="body2">Código soporte uber {uuid.substring(uuid.length - 5)}</Typography>)}
          </>
        )}
        <Divider className={classes.divider} />
        {restaurantHasFeature(restaurant, 'realTimeOrderStatus') &&
          <div className={classes.statusContainer}>
            <Status
              deliveryType={order.delivery_type}
              status={status}
              orderId={order.id}
            />
          </div>
        }
        <Details
          cart={order.items}
          deliveryOptions={{ zone: { delivery_cost: order.delivery_price } }}
          discount={order.discount}
          tip={order.tip}
          total={order.total_price}
          subTotal={order.items_total}
        />
      </Container>
    </div>
  )
}

export default Order;
export const addGoogleTagManager = (id) => {
  appendScriptWithCode(googleTagManagerCode(id));
  appendNoScriptTagManager(id);
};

export const addFacebookDomainVerificationTag = (id) => {
  const meta = document.createElement('meta');
  meta.name = "facebook-domain-verification";
  meta.content = id;
  const head = document.getElementsByTagName('head')[0];
  head.insertBefore(meta, head.childNodes[0]);
}

const googleTagManagerCode = (id) => `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${id}');
`;

const appendScriptWithSrc = (src) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;

  const head = document.getElementsByTagName('head')[0];
  head.insertBefore(script, head.childNodes[0]);
}

const appendScriptWithCode = (code) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  const head = document.getElementsByTagName('head')[0];
  try {
    script.appendChild(document.createTextNode(code));
    head.insertBefore(script, head.childNodes[0]);
  } catch (e) {
    script.text = code;
    head.insertBefore(script, head.childNodes[0]);
  }
}

const appendNoScriptTagManager = (id) => {
  const noScript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`;
  iframe.height = '0';
  iframe.width = '0';
  iframe.style= 'display:none;visibility:hidden';
  noScript.appendChild(iframe);
  const body = document.getElementsByTagName('body')[0];
  body.insertBefore(noScript, body.childNodes[0]);
}
const paymentMethodToSpanish = {
  Webpay: 'Webpay',
  Edenred: 'Edenred',
  Amipass: 'Amipass',
  Sodexo: 'Sodexo',
  Transfer: 'transferencia',
  Cash: 'efectivo',
  DebitCard: 'tarjeta de débito física',
  CreditCard: 'tarjeta de crédito física',
  CreditOrDebitCard: 'tarjeta de crédito o débito',
  OneClick: 'registro de tarjeta'
};

export const translatePaymentMethod = (paymentMethod, language) => {
  if (language === 'ES') return paymentMethodToSpanish[paymentMethod];
  else return paymentMethod;
};

import React, { useContext, useState } from 'react'
import {
  Typography,
  Paper,
  ButtonGroup,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Store, Motorcycle } from '@material-ui/icons'
import DeliveryDetails from './DeliveryDetails';
import TakeAwayDetails from './TakeAwayDetails';
import { MainContext } from '../../context/MainContext';
import { Faqs } from '../common';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '3rem 1rem',
  },
  marginTop: {
    marginTop: '1rem',
  },
  buttonsContainer: {
    margin: '1rem 0',
  },
  detailsContainer: {
    padding: '1rem',
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  nonSelected: {
    backgroundColor: 'white',
  },
}))

const Delivery = () => {
  const classes = useStyles();
  const { deliveryOptions, setDeliveryOptions } = useContext(MainContext);

  return (
    <div className={classes.container}>
      <Typography variant="h4" gutterBottom>¿Cómo pedirás hoy?</Typography>
      <ButtonGroup className={classes.buttonsContainer} fullWidth disableElevation variant="outlined">
        <Button
          onClick={() => setDeliveryOptions({ ...deliveryOptions, type: 'delivery' })}
          startIcon={<Motorcycle />}
          className={
            deliveryOptions?.type === 'delivery' ? classes.selected : classes.nonSelected
          }>
          Delivery
        </Button>
        <Button
          onClick={() => setDeliveryOptions({ type: 'takeAway' })}
          startIcon={<Store />}
          className={
            deliveryOptions?.type === 'takeAway' ? classes.selected : classes.nonSelected
          }>
          Retiro en local
        </Button>
      </ButtonGroup>
      {deliveryOptions?.type === 'delivery' && (
        <Paper className={classes.detailsContainer}>
          <DeliveryDetails />
        </Paper>
      )}
      {deliveryOptions?.type === 'takeAway' && (
        <Paper className={classes.detailsContainer}>
          <TakeAwayDetails />
        </Paper>
      )}
      <Faqs />
    </div>
  )
}

export default Delivery

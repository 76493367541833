import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  faqs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
  },
}));

const Faqs = () => {
  const classes = useStyles();
  return (
    <div className={classes.faqs}>
      <Typography variant="subtitle1">¿Necesitas ayuda para pedir?</Typography>
      <Typography variant="body2">
        <a
          href="https://eatifier-main.s3.us-east-2.amazonaws.com/FAQ_pagina_de_pedidos_4a6a6e97f6.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Revisa nuestras preguntas frecuentes
        </a>
      </Typography>
    </div>
  );
};

export default Faqs;
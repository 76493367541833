import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonGroup, Typography } from '@material-ui/core';
import { MainContext } from '../../context/MainContext';
import { Schedule } from '@material-ui/icons';
import TimeSelect from '../utils/TimeSelect';
import { getScheduleMaxHour, getScheduleMinHour } from '../../helpers/date';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    margin: '0.5rem 0',
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  nonSelected: {
    backgroundColor: 'white',
  },
  timeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  scheduleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 0.5rem',

  },
  timeSelect: {
    paddingTop: 7,
    paddingBottom: 7,
    fontSize: 16,
  },
}));

const Scheduling = ({ deliveryType }) => {
  const classes = useStyles();
  const { scheduleOptions, setScheduleOptions, restaurant } = useContext(MainContext);

  if (!restaurant.allowsScheduling) return null;

  const setType = (type) => {
    setScheduleOptions({ ...scheduleOptions, type });
  }

  const handleTimeChange = (time) => {
    setScheduleOptions({ ...scheduleOptions, time });
  };

  const minHour = getScheduleMinHour(restaurant);
  const maxHour = getScheduleMaxHour(restaurant);

  return (
    <div>
      <ButtonGroup className={classes.buttonsContainer} fullWidth disableElevation variant="outlined">
        <Button
          onClick={() => setType('inmediate')}
          className={
            scheduleOptions.type === 'inmediate' ? classes.selected : classes.nonSelected
          }>
          Lo antes posible
          </Button>
        <Button
          onClick={() => setType('scheduled')}
          className={
            scheduleOptions.type === 'scheduled' ? classes.selected : classes.nonSelected
          }>
          Programado
          </Button>
      </ButtonGroup>
      {scheduleOptions.type === 'scheduled' && (
        <>
          {minHour && maxHour && maxHour.isAfter(minHour.add(15, 'minutes'))
            ? (

              <div className={classes.scheduleContainer}>
                <div className={classes.timeContainer}>
                  <Schedule color="primary" style={{ marginRight: '0.5rem' }} />
                  <Typography>Hora del pedido</Typography>
                </div>
                <TimeSelect
                  currentSelection={scheduleOptions.time}
                  onChange={handleTimeChange}
                  minHour={minHour.format('HH:mm')}
                  maxHour={maxHour.format('HH:mm')}
                  className={classes.timeSelect}
                />
              </div>
            )
            : (
              <Typography>No estamos programando pedidos en estos momentos</Typography>
            )}
        </>
      )}
    </div>
  )
};

Scheduling.propTypes = {
  deliveryType: PropTypes.oneOf(['delivery', 'takeAway']),
};

Scheduling.defaultProps = {
  deliveryType: 'takeAway',
};

export default Scheduling;

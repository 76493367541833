import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const Alert = ({open, accept, onClose, important, title, text, acceptText, cancelText}) => {

  const handleAccept = () => {
    accept()
    onClose()
  }
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {text &&
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
      }
      {important ?
        <DialogActions>
          <Button onClick={handleAccept} color="primary" autoFocus>
            {acceptText}
          </Button>
          <Button onClick={onClose} color="primary">
            {cancelText}
          </Button>
        </DialogActions>
        :
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {cancelText}
          </Button>
          <Button onClick={handleAccept} color="primary" autoFocus>
            {acceptText}
          </Button>
        </DialogActions>
      }

    </Dialog>
  );
}
 export default Alert

import React, { useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { MainContext } from '../context/MainContext';
import { createOneClickTransaction, createTransbankTransaction, klapStartPayment } from '../apiCalls/strapi';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { ScrollToTopOnMount } from '../components/utils';
import { klapAvailablePayments, paymentTypeToKlapOptions } from '../helpers/payments';

const Payment = () => {
  const history = useHistory();
  const { order, user, setCurrentTransactionToken } = useContext(MainContext);

  const formRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    if (!order) {
      return;
    }
    if (klapAvailablePayments.includes(order.payment_method)) {
      klapStartPayment(user.jwt, order, paymentTypeToKlapOptions[order.payment_method])
        .then(async ({ data }) => {
          await setCurrentTransactionToken(data.order_id);
          window.location.replace(data.redirect_url);
        })
        .catch((err) => console.log(err))
    }
    else if (order.payment_method === 'Webpay') {
      createTransbankTransaction(user.jwt, order)
        .then(async (response) => {
          const { data } = response;
          const { token, url } = data;
          await setCurrentTransactionToken(token);
          formRef.current.action = `${url}`;
          inputRef.current.value = token;
          formRef.current.submit();
        })
        .catch((err) => console.log(err))
    } else if (order.payment_method === 'OneClick') {
      createOneClickTransaction(user.jwt, order)
        .then((response) => {
          const { data } = response;

          if (data.method === "payment") {
            history.push('payment/success');
          } else if (data.method === "inscription") {
            const { token, url } = data;

            formRef.current.action = `${url}`;
            inputRef.current.value = token;
            formRef.current.submit();
          } else if (data.method === "error") {
            console.log(data)
            history.push('payment/failure');
          }
        })
        .catch((err) => console.log(err))
    }
  }, [history, order, user.jwt])

  if (!order) {
    return <Redirect to="/" />
  }

  return (
    <Box height="50vh" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <ScrollToTopOnMount />
      <CircularProgress style={{ marginBottom: '1rem' }} />
      <Typography>Te estamos redirigiendo al proveedor de pagos</Typography>
      <form method="post" ref={formRef}>
        <input type="hidden" name="TBK_TOKEN" ref={inputRef} />
      </form>
    </Box>
  )
}

export default Payment
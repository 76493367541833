import React from 'react'
import {
  Typography,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    marginBottom: '1em',
  },
  label: {
    width: '100%',
    padding: '0.5em 1em',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    marginBottom: '1em',
  },
  textFieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0.75em 2em',
  },
  textField: {
    [theme.breakpoints.down('sm')]:{
      backgroundColor: theme.palette.grey[200],
    }
  }
}))

const Comment = ({ comment, setComment }) => {
  const classes = useStyles()
  return(
    <div className={classes.container}>
      <div className={classes.label}>
      <Typography><b>Comentarios adicionales</b></Typography>
      </div>
      <div className={classes.textFieldContainer}>
        <TextField
          InputProps={{className: classes.textField}}
          placeholder="Agrega alguna instrucción especial"
          variant="outlined"
          value={comment}
          fullWidth
          multiline
          rows={3}
          rowsMax={3}
          onChange={(event) => setComment(event.target.value)}
          />
      </div>
    </div>
  )
}


export default Comment

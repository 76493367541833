import { gql } from '@apollo/client';

export const GET_RESTAURANT = gql`
query GetRestaurant($restaurantId: ID!) {
    optimizedRestaurant (restaurantId: $restaurantId)
  }
`;

export const GET_IN_STORE_MENUS = gql`
  query GetInStoreMenus($restaurantId: ID!) {
    restaurant(id: $restaurantId) {
      in_store_menus {
        name
        file {
          url
        }
      }
    }
  }
`;